import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi"
import { MdOutlineSettingsSuggest } from "react-icons/md"
import { toast } from "react-toastify"
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util"
import {
  ActionType,
  DASHBOARD_USERTYPE,
  PermissionAction,
  PermissionResponseType,
} from "../../../types/role"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { useLazyQuery, useQuery } from "@apollo/client"
import {
  GETCOMPANYROLES,
  GETPERMISSIONSFORSPECIFICROLE,
} from "../../../GraphQl/queries"
import { UserType } from "../../../types/user"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"

const actionNames = [
  "Show Menu Tab",
  "Can Add",
  "Can Edit",
  "Can Delete",
  "Can View",
]

const DUMMYOPTION = [
  "Show Menu Tab",
  "Can Add",
  "Can Edit",
  "Can Delete",
  "Can View logs",
]

type RolesType = {
  created_at: string
  description: string
  id: string
  name: string
}

type RolePermissionType = {
  permission_setup: {
    id: string
    name: string
  }
  role_id: string
  selected_actions: PermissionAction[]
}

type SavePermissionPayload = {
  name: string
  description: string
  company_id: string
  permissions: {
    permission_id: string
    selected_actions: {
      id: string
      name: string
      action_type: string
    }[]
  }[]
  user_type: string
  role_id: string
}

const RolesAndPermissions = () => {
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const PAGE_SIZE = 7
  const [loading, setLoading] = useState(true)
  const [creating, setCreating] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [state, setState] = useState<{
    name: string
    description: string
    id: string
  }>({
    id: "",
    name: "",
    description: "",
  })

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)
  interface Action {
    id: string | number
    name: string
    action_type: string
  }

  const [perrmissionRows, setPermissionRows] = useState<RolePermissionType[]>(
    []
  )
  const [existingPermissions, setExistingPermissions] = useState<any[]>([])
  const [selectedItem, setSelectedItem] = useState<RolesType>()
  const [permissions, setPermissions] = useState<PermissionResponseType[]>()
  const [selectedActions, setSelectedActions] = useState<string[]>([])
  const [selectedRoleGroups, setSelectedRoleGroups] = useState<string[]>([])
  const [arr, setArr] = useState<PermissionResponseType[]>()
  const [newPermissions, setNewPermissions] = useState<{
    [key: string]: {
      name: string
      id: string
      actions: { id: string; name: string; action_type: string }[]
    }
  }>({})

  const {
    data: roles,
    loading: loadingRoles,
    refetch: retchRoles,
  } = useQuery<{
    company_role: RolesType[]
  }>(GETCOMPANYROLES, {
    variables: {
      id: companyId,
    },
  })

  const [
    GetPermissionForRole,
    { data: role_permissions, loading: loadingPermission, refetch },
  ] = useLazyQuery<{
    company_role_permissions: RolePermissionType[]
  }>(GETPERMISSIONSFORSPECIFICROLE, {
    variables: {
      role_id: selectedItem?.id,
    },
  })

  const handleCheckboxChange = (group: any, data: any, actionType?: string) => {
    if (actionType) {
      // It's an action, update selectedActions
      setSelectedActions((prevActions) => {
        const actionIndex = prevActions.indexOf(actionType)

        if (actionIndex !== -1) {
          // If the action is already selected, remove it
          const newActions = [...prevActions]
          newActions.splice(actionIndex, 1)
          return newActions
        } else {
          // If the action is not selected, add it
          return [...prevActions, actionType]
        }
      })
    } else {
      // It's a role group, update selectedRoleGroups
      const temp = JSON.parse(JSON.stringify(existingPermissions))
      const targetGroup = temp.filter((el: any) => el.id === group.id)
      const remainingGroups = temp.filter((el: any) => el.id !== group.id)
      if (targetGroup?.length === 0 && data !== undefined) {
        temp.push({ name: group?.name, id: group.id, permission: data })

        setExistingPermissions(temp)
      } else if (targetGroup?.length === 0 && data === undefined) {
        temp.push({
          name: group?.name,
          id: group.id,
          permission: {
            role_id: selectedItem?.id,
            permission_setup: { id: group?.id, name: group.name },
            selected_actions: group.actions,
          },
        })
        setExistingPermissions(temp)
      } else {
        setExistingPermissions(remainingGroups)
      }
    }
  }

  const fetchPermissions = async () => {
    const res = await fetch(
      `${BASEURL}/get-all-permissions?company_id=${companyId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const req = await res.json()

    try {
      if (res.ok) {
        setPermissions(req?.data)
      } else {
        toast.error(req?.message ?? req?.detail)
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const filterData = (filter: RolesType) => {
    const isTextMatch =
      filter?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.description?.toLowerCase()?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  //----------------------------
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  useEffect(() => {
    if (searchText) {
      setCurrentPage(1)
    }
  }, [searchText])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalItems = roles?.company_role?.filter(filterData)
  const paginatedData = totalItems?.slice(startIndex, endIndex)

  // console.log(paginatedData)

  //--------------------------

  // const paginatedData = roles?.company_role
  //   ?.filter(filterData)
  //   .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  // const nextPage = () => {
  //   if (
  //     (activePage - 1) * PAGE_SIZE <
  //     (roles?.company_role?.filter(filterData).length || 0)
  //   ) {
  //     setActivePage(activePage + 1);
  //   }
  // };

  // const prevPage = () => {
  //   if (activePage > 1) {
  //     setActivePage(activePage - 1);
  //   }
  // };

  const updatePermission = async () => {
    const formattedPermissions = Object.values(existingPermissions).map(
      (permission) => ({
        permission_id: permission.id,
        selected_actions: permission?.permission?.selected_actions.map(
          (action: Action) => ({
            ...action,
            id:
              typeof action.id === "number" ? action.id.toString() : action.id, // Convert id to string if it's a number
          })
        ),
      })
    )

    try {
      const payload = {
        name: state?.name,
        description: state?.description,
        role_id: state?.id,
        company_id: companyId,
        permissions: formattedPermissions,
        user_type: DASHBOARD_USERTYPE.company,
      }
      setSubmitting(true)

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }

      const req = await fetch(`${BASEURL}/update-roles-permissions`, options)
      const res = await req.json()

      if (req.ok) {
        setSelectedItem(undefined)
        fetchPermissions()

        retchRoles()
        toast.success(res?.msg ?? "Record updated successfully")
        //()
        setSelectedRoleGroups([])
        setSelectedActions([])
        setState({
          name: "",
          description: "",
          id: "",
        })
      } else {
        toast.error(res?.detail ?? "An error occurred")
      }

      setSubmitting(false)
    } catch (error: any) {
      setSubmitting(false)
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    fetchPermissions()
  }, [])

  const calculateInitialCheckedActions = (
    existingPermission: RolePermissionType | undefined,
    actions: PermissionAction[]
  ) => {
    return actions
      .filter(
        (item) =>
          existingPermission?.selected_actions?.some(
            (selectedAction) => selectedAction.action_type === item.action_type
          ) || false
      )
      .map((item) => item.action_type)
  }

  const handleCheckboxNewChange = (
    permissionName: string,
    id: string,
    action: { id: string; name: string; action_type: string }
  ) => {
    setNewPermissions((prev) => {
      const existingPermission = prev[permissionName]

      if (existingPermission) {
        // Check for duplicate action before updating
        const isDuplicate = existingPermission.actions.some(
          (existingAction) => existingAction.id === action.id
        )

        if (isDuplicate) {
          // Do nothing if the action is already in the list
          return prev
        }

        return {
          ...prev,
          [permissionName]: {
            ...existingPermission,
            actions: [...existingPermission.actions, { ...action, id }],
          },
        }
      } else {
        // Permission doesn't exist yet, create a new one
        return {
          ...prev,
          [permissionName]: {
            id,
            name: permissionName,
            actions: [{ ...action, id }],
          },
        }
      }
    })
  }

  const CreatePermission = async () => {
    try {
      setCreating(true)

      const formattedPermissions = Object.values(newPermissions).map(
        (permission) => ({
          permission_id: permission.id,
          selected_actions: permission.actions,
        })
      )

      const payload = {
        name: state?.name,
        description: state?.description,
        company_id: companyId,
        permissions: formattedPermissions,
        user_type: DASHBOARD_USERTYPE.company,
      }

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }

      const req = await fetch(`${BASEURL}/create-roles-permissions`, options)
      const res = await req.json()

      if (req.ok) {
        refetch()
        retchRoles()
        fetchPermissions()
        toast.success(res?.msg ?? "Record updated successfully")
        setSelectedRoleGroups([])
        setSelectedActions([])
        setNewPermissions({})
        setArr([])
        setState({
          name: "",
          description: "",
          id: "",
        })
      } else {
        toast.error(res?.detail ?? "An error occurred")
      }

      setCreating(false)
    } catch (error: any) {
      setCreating(false)
      toast.error(error?.message)
    }
  }

  const DeletePermission = async (role_id: string) => {
    try {
      const payload = {
        role_id,
        usertype: DASHBOARD_USERTYPE.company,
      }

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }

      const req = await fetch(`${BASEURL}/delete-roles-permissions`, options)
      const res = await req.json()

      if (req.ok) {
        fetchPermissions()
        toast.success(res?.msg ?? "Record deleted successfully")
        retchRoles()
        setArr([])
      } else {
        toast.error(res?.detail ?? "An error occurred")
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  const createExistingPermissions = (targetList: any[]) => {
    const temp: any[] = []
    permissions?.forEach((element) => {
      const existingPermission =
        targetList &&
        targetList.find(
          (existingPermission) =>
            existingPermission.permission_setup.id === element.id
        )
      if (existingPermission !== undefined)
        temp.push({ id: element.id, permission: existingPermission })
    })

    setExistingPermissions(temp)
  }

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  return (
    <>
      <CustomHeader title="Roles & Permissions" />

      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
        <div className="rounded-lg overflow-hidden md:col-span-1 lg:col-span-2 w-full">
          <div className="bg-black py-2 px-2 text-white">
            <Typography
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="text-lg"
            >
              Create new role
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full flex flex-col lg:flex-row md:flex-col">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Role name
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="md"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      value={state?.name}
                      onChange={(e) =>
                        setState({ ...state, name: e?.target.value })
                      }
                    />
                  </div>
                  <div className="w-full flex flex-col lg:flex-row md:flex-col">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Description
                    </Typography>
                    <Textarea
                      size="md"
                      value={state?.description}
                      onChange={(e) =>
                        setState({ ...state, description: e?.target.value })
                      }
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button
              size="sm"
              onClick={() =>
                selectedItem ? updatePermission() : CreatePermission()
              }
              style={{
                background:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="flex flex-row items-center  font-normal"
            >
              <BiPlus size={20} />
              {submitting || creating ? (
                <Spinner className="w-4 h-4" />
              ) : (
                "Save Record"
              )}
            </Button>

            <div className="mt-6">
              <div className="flex flex-row items-center gap-2">
                <MdOutlineSettingsSuggest size={20} />
                <Typography className="text-lg">Permissions</Typography>
              </div>
              <hr className="my-4" />

              {selectedItem ? (
                permissions && (
                  // For editing permissions
                  <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    {permissions?.map((group, index) => {
                      const existingPermission =
                        perrmissionRows &&
                        perrmissionRows.find(
                          (existingPermission) =>
                            existingPermission.permission_setup.id === group.id
                        )

                      const initialCheckedActions =
                        calculateInitialCheckedActions(
                          existingPermission,
                          group.actions
                        )

                      return (
                        <div key={index}>
                          <div className="flex items-center flex-row gap-1">
                            <Checkbox
                              size={10}
                              checked={
                                existingPermissions.filter(
                                  (el: any) => el.id === group.id
                                )[0]?.permission?.role_id === selectedItem?.id
                                // existingPermission?.role_id === selectedItem?.id
                              }
                              onChange={() => {
                                handleCheckboxChange(group, existingPermission)
                              }}
                              value={undefined}
                            />
                            <Typography className="text-sm font-semibold">
                              {group?.name}
                            </Typography>
                          </div>
                          <div className="flex flex-col">
                            {group?.actions?.map((item, itemIndex) => {
                              const isCheckedAction =
                                initialCheckedActions.includes(item.action_type)

                              return (
                                <div
                                  key={itemIndex}
                                  className="flex items-center flex-row gap-1"
                                >
                                  <Checkbox
                                    size={10}
                                    defaultChecked={isCheckedAction}
                                    checked={
                                      existingPermissions
                                        ?.filter(
                                          (el: any) => el.id === group.id
                                        )[0]
                                        ?.permission?.selected_actions?.filter(
                                          (ell: any) => ell.name === item.name
                                        ).length > 0
                                    }
                                    onChange={(e) => {
                                      const temp: any[] = JSON.parse(
                                        JSON.stringify(existingPermissions)
                                      )
                                      const targetGroup = temp
                                        ?.filter(
                                          (el: any) => el.id === group.id
                                        )[0]
                                        ?.permission?.selected_actions?.filter(
                                          (ell: any) => ell.name === item.name
                                        )
                                      const remainingGroups = temp
                                        ?.filter(
                                          (el: any) => el.id === group.id
                                        )[0]
                                        ?.permission?.selected_actions?.filter(
                                          (ell: any) => ell.name !== item.name
                                        )

                                      let targetIndex = temp.findIndex(
                                        (el: any) => el.id === group.id
                                      )
                                      if (targetGroup === undefined) {
                                        toast.info(
                                          "Select permission item first"
                                        )
                                      } else if (targetGroup?.length > 0) {
                                        temp[
                                          targetIndex
                                        ].permission.selected_actions =
                                          remainingGroups
                                        setExistingPermissions(temp)
                                        // setExistingPermissions(temp)
                                      } else {
                                        remainingGroups.push({
                                          id: group.id,
                                          name: item?.name,
                                          action_type: item.action_type,
                                        })

                                        temp[
                                          targetIndex
                                        ].permission.selected_actions =
                                          remainingGroups
                                        setExistingPermissions(temp)
                                      }
                                    }}
                                    value={undefined}
                                  />
                                  {/* <input
                                    type="checkbox"
                                    onChange={() =>
                                      handleCheckboxNewChange(
                                        group?.name,
                                        group.id,
                                        item as any
                                      )
                                    }
                                    checked={isCheckedAction}
                                  /> */}
                                  <Typography className="text-xs font-normal">
                                    {item.name}{" "}
                                    {/* {isCheckedAction ? "(Checked)" : ""} */}
                                  </Typography>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              ) : (
                // For creating new permisssions
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                  {permissions &&
                    permissions?.map((item, index) => (
                      <div key={index}>
                        <div className="flex items-center flex-row gap-1">
                          <Checkbox
                            size={10}
                            defaultChecked
                            value={undefined}
                          />
                          <Typography className="text-sm font-semibold">
                            {item?.name}
                          </Typography>
                        </div>
                        <div className="flex flex-col">
                          {item?.actions?.map((action, index) => (
                            <div
                              key={action?.id}
                              className="flex items-center flex-row gap-1"
                            >
                              <Checkbox
                                size={10}
                                onChange={() =>
                                  handleCheckboxNewChange(
                                    item?.name,
                                    item.id,
                                    action as any
                                  )
                                }
                                defaultChecked={false}
                                value={undefined}
                              />
                              <Typography className="text-xs font-normal">
                                {action?.name}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              )}
              <div className=" mb-3 flex w-full  justify-end">
                <Button
                  size="sm"
                  onClick={() =>
                    selectedItem ? updatePermission() : CreatePermission()
                  }
                  style={{
                    background:
                      colors?.length! > 1
                        ? `${colors![0]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`,
                  }}
                  className="flex flex-row items-center  font-normal"
                >
                  <BiPlus size={20} />
                  {submitting || creating ? (
                    <Spinner className="w-4 h-4" />
                  ) : (
                    "Save Record"
                  )}
                </Button>
              </div>

              {loading && (
                <div className="mt-10 flex items-center justify-center">
                  <Spinner className="w-1o h-10" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="rounded-lg overflow-hidden shadow-xl w-full">
          <div className="bg-black py-2 px-2 text-white">
            <Typography
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="text-lg"
            >
              All roles
            </Typography>
          </div>
          <div className="py-3 px-2 bg-white">
            <div className="h-full w-full">
              <div className="rounded-none">
                <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
                  <div className="flex w-full shrink-0 gap-2">
                    <div className="w-full">
                      <Input
                        crossOrigin={""}
                        className="w-full"
                        value={searchText}
                        onChange={handleSearch}
                        label="Search"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full scrolls overflow-y-scroll px-0">
                <div className="w-full min-w-max table-auto text-left">
                  {["name"].map((head, index) => (
                    <div
                      key={index}
                      className="w-full border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="capitalize font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </div>
                  ))}

                  <div>
                    {paginatedData?.map((item, index) => {
                      const isLast = index === paginatedData?.length - 1
                      const classes = "px-4 py-2.5"

                      const handleClick = () => {
                        setSelectedItem(item)

                        setState({
                          ...state,
                          name: item?.name,
                          description: item?.description,
                          id: item?.id,
                        })
                        GetPermissionForRole({
                          variables: { role_id: item?.id },
                        }).then((res: any) => {
                          setPermissionRows(res.data.company_role_permissions)
                          createExistingPermissions(
                            res.data.company_role_permissions
                          )
                        })
                      }

                      return (
                        <div
                          key={index}
                          className="hover:cursor-pointer hover:bg-gray-100 border-b border-blue-gray-50 w-full flex flex-row items-center justify-between"
                        >
                          <div onClick={handleClick} className={classes}>
                            <Typography className="text-xs">
                              {item?.name}
                            </Typography>
                          </div>
                          <div onClick={handleClick} className={classes}></div>

                          <div className={classes}>
                            <div className="flex gap-3">
                              <Tooltip content="Edit">
                                <IconButton
                                  onClick={handleClick}
                                  color="green"
                                  size="sm"
                                  variant="filled"
                                >
                                  <BiEdit />
                                </IconButton>
                              </Tooltip>
                              <Tooltip content="Delete">
                                <IconButton
                                  onClick={() => DeletePermission(item?.id)}
                                  color="red"
                                  size="sm"
                                  variant="filled"
                                >
                                  <BiTrash />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              {loadingRoles && (
                <div className="mt-10 flex items-center justify-center">
                  <Spinner className="w-1o h-10" />
                </div>
              )}

              {!paginatedData && !loadingRoles && (
                <div className="mt-10 flex items-center justify-center">
                  <Typography color="black" variant="h6">
                    No data found
                  </Typography>
                </div>
              )}

              {paginatedData &&
                paginatedData?.length !== 0 &&
                !loadingRoles && (
                  <GeneralPagination
                    color="black"
                    totalItems={totalItems?.length!}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                )}
              {/* {paginatedData && (
                <div className="my-10 flex items-center justify-center">
                  <div className="flex items-center gap-8">
                    <IconButton
                      size="sm"
                      variant="outlined"
                      onClick={prevPage}
                      disabled={activePage === 1}
                    >
                      <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                    </IconButton>
                    <Typography color="gray" className="font-normal">
                      Page{" "}
                      <strong className="text-gray-900">{activePage}</strong> of{" "}
                      <strong className="text-gray-900">
                        {Math.ceil(
                          (roles?.company_role?.filter(filterData).length ||
                            0) / PAGE_SIZE
                        )}
                      </strong>
                    </Typography>
                    <IconButton
                      size="sm"
                      variant="outlined"
                      onClick={nextPage}
                      disabled={
                        activePage ===
                        Math.ceil(
                          (roles?.company_role?.filter(filterData).length ||
                            0) / PAGE_SIZE
                        )
                      }
                    >
                      <AiOutlineArrowRight
                        strokeWidth={2}
                        className="h-4 w-4"
                      />
                    </IconButton>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RolesAndPermissions
