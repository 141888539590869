import React, { useState, useEffect } from "react"
import {
  Button,
  CardBody,
  CardHeader,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import { BiSearch } from "react-icons/bi"
import { FiDownloadCloud } from "react-icons/fi"
import useSWR from "swr"
import dayjs from "dayjs"
import Papa from "papaparse"
import { toast } from "react-toastify"
import { useQuery } from "@apollo/client"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import moment from "moment"
import { CustomHeader } from "../../components/headers/CustomHeader"
import { Table_Headers } from "../dashboard/reports/data"
import { DEFAULTPRIMARYCOLOR } from "../../util"
import { UserType } from "../../types/user"
import { BASEURL } from "../../util"
import { CustomModal } from "../../components"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import GeneralPagination from "../../components/paginations/generalpagination"
import { useDebounceSearch } from "../../hooks/useDebounce"
import { SEARCHREPORTS, SEARCHREPORTSAMOUNT } from "../../GraphQl/queries"
import useSearchReports from "../../hooks/useSearch"
import DriverArrears from "./manageDrivers/DriverArrears"

type ResponseType = {
  account_status: string
  address: string
  balance: number
  bank_details: {
    branch: string
    bank_name: string
    account_number: string
  }
  company_staff: {
    id: string
    name: string
    phone_number_one: string
  }
  sub_agent: {
    id: string
    first_name: string
    last_name: string
    phone_number: string
  }
  total_collections: {
    aggregate: {
      sum: {
        amount: number
      }
    }
  }
  total_withdrawals: {
    aggregate: {
      sum: {
        amount: number
      }
    }
  }
  total_invest2insure_payments: {
    aggregate: {
      sum: {
        amount: number
      }
    }
  }
  driver_payments_schedules_aggregate: {
    aggregate: {
      sum: {
        arrears: number
      }
    }
  }

  total_invest2insure_payments_balance: {
    aggregate: {
      sum: {
        premium_balance: number
      }
    }
  }
  created_at: string
  consent: boolean
  dob: string
  driver_code: string
  driver_beneficiaries: [
    {
      first_name: string
      surname: string
      relationship: string
      share_percentage: number
    }
  ]
  email: string
  full_name: string
  gender: string
  id: string
  id_card_type: string
  id_number: string
  momo_wallet_details: {
    number: string
    network: string
  }
  phone_number_1: string
  phone_number_2: string
}

const Invest2InsureReport = () => {
  const [searchText, setSearchText] = useState("")

  const [data, setData] = useState<ResponseType[]>([])
  const [loading, setLoading] = useState(true)
  const [activePage, setActivePage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState<ResponseType>()
  const [open, setModalOpen] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [totalPageSize, setTotalPageSize] = useState(0)

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const search = useDebounceSearch<string>(searchText)
  // console.log(search)
  const {
    loading: loadingData,
    error: errData,
    data: reportData,
  } = useSearchReports(search)
  // console.log(Data1)

  const check = parseFloat(search)
  const convert = isNaN(check) ? 0 : check

  // const {
  //   loading: loadingData,
  //   error,
  //   data: reportData,
  // } = useQuery(SEARCHREPORTS, {
  //   variables: {
  //     searchText: `%${search}%`,
  //     jsonFilter: "",
  //   },
  // })
  // const {
  //   loading: load,
  //   error: err,
  //   data: Data,
  // } = useQuery(SEARCHREPORTSAMOUNT, {
  //   variables: {
  //     searchAmount: convert,
  //   },
  // })

  // console.log(reportData)
  // console.log(Data)
  // console.log(parseInt(searchText))

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }
  // console.log(startDate, endDate)
  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }
  useEffect(() => {
    const fetchData = async () => {
      setData([])
      setLoading(true)
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/get-all-report?page=${activePage}&limit=${pageSize}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const req = await res.json()
        // console.log(req?.data)
        if (res?.ok) {
          if (req?.error) {
            toast.error(req?.data)
          } else {
            // console.log(req)
            setData(req?.data)
            setTotalPageSize(req?.total_records)
          }
        }
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        toast.error(error?.message ?? "Something went wrong. Try again!")
      }
    }
    fetchData()
  }, [activePage, pageSize, token])
  const filterData = (filter: ResponseType) => {
    const isTextMatch =
      filter?.full_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.gender?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.id_card_type
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.address?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.id_number?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.phone_number_1
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.momo_wallet_details?.network
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())

    return isTextMatch
  }

  // useEffect(() => {
  //   fetchData()
  // }, [])

  const filtering = (filter: ResponseType) => {
    const filt1 = {
      ...filter,
      created_at: moment(filter?.created_at).format("LL"),
    }
    const isTextMatch =
      // filt1?.total_collections?.aggregate?.sum?.amount
      //   ?.toString()
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // filt1?.total_withdrawals?.aggregate?.sum?.amount
      //   ?.toString()
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // filt1?.total_invest2insure_payments?.aggregate?.sum?.amount
      //   ?.toString()
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // filt1?.total_invest2insure_payments?.aggregate?.sum?.amount
      //   ?.toString()
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // filt1?.balance
      //   ?.toString()
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // filt1?.account_status
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // filt1?.address?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      // filt1?.bank_details?.account_number
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // filt1?.bank_details?.bank_name
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // filt1?.bank_details?.branch
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // filt1?.company_staff?.name
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // filt1?.company_staff?.phone_number_one
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // filt1?.driver_code?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      // filt1?.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      // filt1?.full_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      // filt1?.gender?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      // filt1?.id_card_type?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      // filt1?.id_number?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filt1?.created_at?.toLowerCase()?.includes(searchText?.toLowerCase())
    // filt1?.momo_wallet_details?.network
    //   ?.toLowerCase()
    //   ?.includes(searchText?.toLowerCase()) ||
    // filt1?.momo_wallet_details?.number
    //   ?.toLowerCase()
    //   ?.includes(searchText?.toLowerCase()) ||
    // filt1?.phone_number_1
    //   ?.toLowerCase()
    //   ?.includes(searchText?.toLowerCase()) ||
    // filt1?.phone_number_2?.toLowerCase()?.includes(searchText?.toLowerCase())
    return isTextMatch
  }
  useEffect(() => {
    if (searchText) {
      setActivePage(1)
    }
  }, [searchText])

  const handlePageChange = (page: number) => {
    setActivePage(page)
  }

  const startIndex = (activePage - 1) * pageSize
  const endIndex = startIndex + pageSize

  const paginatedData = (reportData?.drivers as ResponseType[])?.slice(
    startIndex,
    endIndex
  )
  console.log(paginatedData)

  //----------------
  const handleDownload = () => {
    // Check if there is data to download
    if (!paginatedData || paginatedData?.length === 0) {
      toast.error("No data to download.")
      return
    }

    const change = paginatedData.map((item) => ({
      driver_code: item?.driver_code,
      full_name: item?.full_name,
      address: item?.address,
      phone_number_1: item?.phone_number_1,
      email: item?.email,
      id_card_type: item?.id_card_type,
      id_number: item?.id_number,
      network: item?.momo_wallet_details?.network,
      number: item?.momo_wallet_details?.number,
      bank_name: item?.bank_details?.bank_name,
      account_number: item?.bank_details?.account_number,
      created_at: moment(item?.created_at).format("LL"),
      company_staff_name: item?.company_staff
        ? item.company_staff?.name
        : `${item?.sub_agent?.first_name} ${item?.sub_agent?.last_name}`,

      total_collections: item?.total_collections?.aggregate?.sum?.amount ||0,
      total_withdrawals: item?.total_withdrawals?.aggregate?.sum?.amount||0,
      total_invest2insure_payments:
        item?.total_invest2insure_payments?.aggregate?.sum?.amount||0,
      total_invest2insure_payments_balance:
        item?.total_invest2insure_payments_balance?.aggregate?.sum?.premium_balance||0,
      driver_payments_schedules_aggregate:
        item?.driver_payments_schedules_aggregate?.aggregate?.sum?.arrears||0,

    }))
    // Flatten nested objects recursively
    const flattenObject = (
      obj: Record<string, any>,
      prefix = ""
    ): Record<string, any> => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        const newKey = prefix ? `${prefix}.${key}` : key
        if (typeof value === "object" && value !== null) {
          Object.assign(acc, flattenObject(value, newKey))
        } else {
          acc[newKey] = value
        }
        return acc
      }, {} as Record<string, any>)
    }
    // Extract table headers from the first item in paginatedData
    const tableHeaders = Object.keys(change[0])
    // Transform data for CSV
    const transformedData = change?.map((item:any) => {
      const transformedItem: Record<string, any> = {}
      tableHeaders.forEach((field) => {
        transformedItem[field] = item[field]
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: tableHeaders,
      data: transformedData!!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = "driver_report.csv"
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  const userData = localStorage.getItem("user")
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData)
      setColors(data.colors)
    }
  }, [])

  return (
    <>
      <CustomHeader title="Reports" />

      <CustomModal
        title="Beneficiaries"
        open={open}
        onClose={() => setModalOpen(false)}
        size={"xl"}
        showClose={true}
        className={
          selectedRow?.driver_beneficiaries?.length! > 5
            ? "h-full overflow-scroll"
            : ""
        }
        children={
          <>
            {selectedRow?.driver_beneficiaries?.length! > 0 ? (
              <CardBody className="overflow-x-scroll px-0">
                <table className="w-full min-w-max table-auto text-left">
                  <HeaderRenderer
                    tableHead={[
                      "first name",
                      "surname",
                      "relationship",
                      "share percentage",
                    ]}
                  />
                  <tbody>
                    {selectedRow?.driver_beneficiaries?.map(
                      (item, index: number) => {
                        const isLast = index === paginatedData?.length - 1
                        const classes = isLast
                          ? "px-2 py-3 text-center"
                          : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                        return (
                          <tr
                            key={index}
                            className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                          >
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.first_name}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize"
                              >
                                {item?.surname}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.relationship}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.share_percentage}%
                              </Typography>
                            </td>
                          </tr>
                        )
                      }
                    )}
                  </tbody>
                </table>
              </CardBody>
            ) : (
              <>
                <div className=" flex items-center justify-center">
                  <Typography color="black" variant="h6">
                    No driver beneficiaries found
                  </Typography>
                </div>
              </>
            )}
          </>
        }
      />

      {/* <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
        <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
          <div>
            <div className="relative flex w-full max-w-[24rem]">
              <Button
                ripple={false}
                variant="text"
                color="blue-gray"
                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
              >
                {" "}
                From
              </Button>
              <Input
                crossOrigin="anonymous"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Mobile Number"
                className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                size="md"
                containerProps={{
                  className: "min-w-0",
                }}
              />
            </div>
          </div>
          <div>
            <div className="relative flex w-full max-w-[24rem]">
              <Button
                ripple={false}
                variant="text"
                color="blue-gray"
                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
              >
                {" "}
                To
              </Button>
              <Input
                crossOrigin="anonymous"
                type="date"
                placeholder="Mobile Number"
                className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                size="md"
                containerProps={{
                  className: "min-w-0",
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>

          <Button
            // onClick={() => refetch()}
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className="text-xs font-normal w-1/2"
            size="md"
          >
            Generate Report
          </Button>
        </div>
      </div> */}

      <div className="mt-5 bg-white p-3 rounded-lg">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="w-full  flex flex-col gap-y-6 mb-4">
            <div className="flex shrink-0 flex-col justify-end gap-2 sm:flex-row">
              <Tooltip content="Export as CSV">
                <Button
                  onClick={handleDownload}
                  variant="filled"
                  className="capitalize text-sm font-normal flex flex-wrap gap-2"
                  size="sm"
                >
                  <FiDownloadCloud size={20} />
                  <span>Download csv</span>
                </Button>
              </Tooltip>
            </div>

            <div className=" flex gap-x-4 items-center flex-col 2xl:flex-row gap-y-3 mb-5">
              <div className="w-full ">
                <Input
                  crossOrigin="anonymous"
                  value={searchText}
                  onChange={handleSearch}
                  label="Search"
                  icon={<BiSearch className="h-5 w-5" />}
                />
              </div>

              <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">From</label>
                  <Input
                    type="datetime-local"
                    value={startDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleStartDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">To</label>
                  <Input
                    type="datetime-local"
                    value={endDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleEndDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 sticky overflow-y-auto py-0">
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              <table className="w-full min-w-max table-auto text-left">
                <HeaderRenderer tableHead={Table_Headers} />
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    console.log(item.driver_payments_schedules_aggregate)
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-2 py-3 text-center"
                      : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedRow(item)
                          setModalOpen(true)
                        }}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.driver_code}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.full_name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.address}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.phone_number_1}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs "
                          >
                            {item?.email}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.id_card_type}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.id_number}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Network:{" "}
                              <span className="font-normal">
                                {item?.momo_wallet_details?.network}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Number:{" "}
                              <span className="font-normal">
                                {item?.momo_wallet_details?.number}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        {/* <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.bank_details?.bank_name}
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.bank_details?.account_number}
                      </Typography>
                    </td> */}
                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Bank name:{" "}
                              <span className="font-normal">
                                {item?.bank_details?.bank_name}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              account number:{" "}
                              <span className="font-normal">
                                {item?.bank_details?.account_number}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.created_at).format("LL")}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Full name:{" "}
                              <span className="font-normal">
                                {item?.company_staff
                                  ? `${item?.company_staff?.name}`
                                  : `${item?.sub_agent?.first_name} ${item?.sub_agent?.last_name}`}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Phone number:{" "}
                              <span className="font-normal">
                                {item?.company_staff
                                  ? item?.company_staff?.phone_number_one
                                  : item?.sub_agent?.phone_number}
                              </span>
                            </Typography>

                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              User type:{" "}
                              <span className="font-normal">
                                {item?.company_staff
                                  ? "Company Staff"
                                  : "Sub Agent"}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS{" "}
                            {(
                              item?.total_collections.aggregate?.sum?.amount ??
                              0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS{" "}
                            {(
                              item?.total_withdrawals.aggregate?.sum?.amount ??
                              0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS{" "}
                            {(
                              item?.total_invest2insure_payments.aggregate?.sum
                                ?.amount ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS{" "}
                            {(
                              item?.total_invest2insure_payments_balance
                                ?.aggregate?.sum?.premium_balance ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS{" "}
                            {(item?.balance ?? 0).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS{" "}
                            {(item?.driver_payments_schedules_aggregate?.aggregate?.sum?.arrears ?? 0).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}


                          </Typography>
                        </td>
                        <td className={classes}>
                          <Tooltip content="Edit Admin">
                            <Button
                              color="green"
                              size="sm"
                              className="text-xs font-normal capitalize"
                              onClick={() => {
                                setSelectedRow(item)
                                setModalOpen(true)
                              }}
                              variant="filled"
                            >
                              View Beneficiaries
                            </Button>
                          </Tooltip>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          {(loading || loadingData) && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {(paginatedData?.length === 0 ||
            reportData?.drivers?.length === 0) && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
        </CardBody>
      </div>

      {paginatedData &&
        paginatedData?.length !== 0 &&
        (!loading || !loadingData) && (
          <GeneralPagination
            totalItems={
              (searchText ? reportData?.drivers?.length : totalPageSize!) ?? 0
            }
            itemsPerPage={pageSize}
            currentPage={activePage}
            onPageChange={handlePageChange}
          />
        )}

      {/* {paginatedData && (
        <div className="my-10 flex items-center justify-center">
          <div className="flex items-center gap-8">
            <IconButton
              size="sm"
              color="white"
              variant="filled"
              onClick={prevPage}
              disabled={activePage === 1}
            >
              <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="white" className="font-normal">
              Page <strong className="white">{activePage}</strong>
            </Typography>
            <IconButton
              size="sm"
              color="white"
              variant="filled"
              onClick={nextPage}
              disabled={data?.length < pageSize}
            >
              <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
        </div>
      )} */}
    </>
  )
}

const HeaderRenderer = ({ tableHead }: { tableHead: string[] }) => (
  <thead className="bg-white sticky z-50 top-0">
    <tr>
      {tableHead.map((head) => (
        <th
          key={head}
          className="capitalize text-center border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
        >
          <Typography
            variant="small"
            color="blue-gray"
            className="font-bold leading-none opacity-70"
          >
            {head}
          </Typography>
        </th>
      ))}
    </tr>
  </thead>
)

export default Invest2InsureReport
