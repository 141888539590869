import React, { useEffect, useRef, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import { Button, Card, Checkbox, Tooltip } from "@material-tailwind/react"
import {
  CardBody,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react"
import moment from "moment"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { toast } from "react-toastify"
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util"
import { GiCheckMark } from "react-icons/gi"
import { GrClose } from "react-icons/gr"
import { DetailDrawer } from "../../../components"
import CollectionDetail from "../../../components/Details/collection-detail copy"
import { UserType } from "../../../types/user"
import SendMessageDetails from "../../../components/Details/SendMessageDetails"
import { DriverModal } from "../../../components/modal/DriverModal"
import dataDemo from "../../../data"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import { useHistory } from "react-router-dom"
import { DRIVERAGGREGATE, GET_ALL_DRIVERS } from "../../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import DriverColectionDetails, {
  CollectionRequest,
} from "../../../components/Details/driver-collection-detail"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { RiAdminLine } from "react-icons/ri"
import { DriverAggreateType } from "../../../types/query"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import DriverArrears from "./DriverArrears"

export type ResponseType = {
  amount: number
  created_at: string
  created_by: string
  payment_mode: string
  driver_current_balance: number
  id: string
  reference_number: string
  status: string
  company_staff: {
    id: string
    name: string
    phone_number_one: string
  }
  sub_agent: {
    id: string
    first_name: string
    last_name: string
    phone_number: string
  }
  driver: {
    account_status: string
    address: string
    balance: number
    bank_details: null
    consent: false
    dob: null
    email: string
    gender: string
    id_card_type: string
    id: string
    id_number: string
    momo_wallet_details: {
      network: string
      number: string
    }
    phone_number_1: string
    phone_number_2: string
    full_name: string
  }
  agent_staff_details: {
    id: string
    phone_number: string
    first_name: string
    last_name: string
  }
}
const data11 = [
  {
    account_status: "Active",
    balance: 233,
    date_created: "2021-09-29T14:00:00.000Z",
    driver_code: "DR-0001",
    driver_id: "1",
    driver_name: "Kwame Nkrumah",
    phone_number: "0245678901",
    total_number_of_policies: 500,
    total_premium_balance: 100,
    total_premium_paid: 200,
    total_premium_payable: 900,
    wallet_number: "0245678901",
  },
  {
    account_status: "Active",
    balance: 233,
    date_created: "2021-09-29T14:00:00.000Z",
    driver_code: "DR-0001",
    driver_id: "2",
    driver_name: "Kwame Nkrumah",
    phone_number: "0245678901",
    total_number_of_policies: 500,
    total_premium_balance: 100,
    total_premium_paid: 200,
    total_premium_payable: 900,
    wallet_number: "0245678901",
  },
  {
    account_status: "Active",
    balance: 233,
    date_created: "2021-09-29T14:00:00.000Z",
    driver_code: "DR-0001",
    driver_id: "3",
    driver_name: "Kwame Nkrumah",
    phone_number: "0245678901",
    total_number_of_policies: 500,
    total_premium_balance: 100,
    total_premium_paid: 200,
    total_premium_payable: 900,
    wallet_number: "0245678901",
  },
  {
    account_status: "Active",
    balance: 233,
    date_created: "2021-09-29T14:00:00.000Z",
    driver_code: "DR-0001",
    driver_id: "4",
    driver_name: "Kwame Nkrumah",
    phone_number: "0245678901",
    total_number_of_policies: 500,
    total_premium_balance: 100,
    total_premium_paid: 200,
    total_premium_payable: 900,
    wallet_number: "0245678901",
  },
  {
    account_status: "Active",
    balance: 233,
    date_created: "2021-09-29T14:00:00.000Z",
    driver_code: "DR-0001",
    driver_id: "5",
    driver_name: "Kwame Nkrumah",
    phone_number: "0245678901",
    total_number_of_policies: 500,
    total_premium_balance: 100,
    total_premium_paid: 200,
    total_premium_payable: 900,
    wallet_number: "0245678901",
  },
]

export type DriversDataType = {
  account_status: string
  balance: number
  date_created: string
  driver_code: string
  driver_id: string
  driver_name: string
  phone_number: string
  total_number_of_policies: number
  total_premium_balance: number
  total_premium_paid: number
  total_premium_payable: number
  total_arrears: number
  wallet_number: string
  usertype: string
  created_by: string
}

const ManageDriver = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  // const [data1, setData] = useState<ResponseType[]>([])
  // const [activePage, setActivePage] = useState(1)
  // const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)
  const [selectedRow, setSelectedRow] = useState<DriversDataType | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [openSideNav, setOpenSideNav] = useState<boolean>(false)
  const [openSendMessage, setOpenMessage] = useState<boolean>(false)
  const [selectedMessagesRows, setSelectedMessagesRows] = useState<string[]>([])

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const {
    companyId,

    user: stateUser,
  } = useSelector((state: RootState) => state.auth)

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  const { data: totalDrivers, loading: load } =
    useQuery<DriverAggreateType>(DRIVERAGGREGATE)

  const {
    loading: Loading,
    error,
    data: driverData,
  } = useQuery<{
    view_driver_policy_totals_details: DriversDataType[]
  }>(GET_ALL_DRIVERS)

  //--------------------
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && paginatedData) {
      const allRows = paginatedData?.map((item) => item.driver_id)
      setSelectedMessagesRows(allRows)
    } else {
      setSelectedMessagesRows([])
    }
  }

  // console.log(driverData)

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string
  ) => {
    if (event.target.checked) {
      setSelectedMessagesRows([...selectedMessagesRows, item])
    } else {
      setSelectedMessagesRows(
        selectedMessagesRows.filter((obj) => obj !== item)
      )
    }
  }
  //---------------

  // const fetchData = async () => {
  //   setData([])
  //   setLoading(true)
  //   try {
  //     const res = await fetch(
  //       `${BASEURL}/get-all-collections?page=${activePage}&limit=${pageSize}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //     const req = await res.json()

  //     if (res?.ok) {
  //       if (req?.error) {
  //         toast.error(req?.data)
  //       } else {
  //         setData(req?.data)
  //       }
  //     }
  //     setLoading(false)
  //   } catch (error: any) {
  //     setLoading(false)
  //     toast.error(error?.message ?? "Something went wrong. Try again!")
  //   }
  // }

  // console.log(data)

  const filterData = (filter: DriversDataType) => {
    const date = moment(filter?.date_created).format("LLL")
    const isTextMatch =
      filter?.account_status
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      date?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.total_number_of_policies
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.total_premium_payable
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.total_premium_paid
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.total_premium_balance
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.balance
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver_code?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.driver_id?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.driver_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.phone_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.wallet_number?.toLowerCase()?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText || startDate || endDate) {
      setCurrentPage(1)
    }
  }, [searchText, startDate, endDate])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const totalItems = driverData?.view_driver_policy_totals_details
    ?.filter(filterData)
    ?.filter((item: any) => {
      const normalizeDate = (date: Date | string): Date => {
        const normalizedDate = new Date(date)
        normalizedDate.setHours(0, 0, 0, 0) // Set time to midnight to include the entire day
        return normalizedDate
      }
      const from =
        (startDate as any) instanceof Date
          ? normalizeDate(startDate)
          : normalizeDate(startDate)
      const to =
        (endDate as any) instanceof Date
          ? normalizeDate(endDate)
          : normalizeDate(endDate)
      const dateCreated = normalizeDate(item.date_created)
      // Convert date_created to Date object
      // Check if dateCreated falls within the range (inclusive)
      if (startDate && endDate) {
        return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
      } else if (startDate) {
        return dateCreated >= from // Only from date is set
      } else if (endDate) {
        return dateCreated <= to // Only to date is set
      }
      return true // No filter applied
    })

  const paginatedData = totalItems?.slice(startIndex, endIndex)

  

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [stateUser?.colors, userData])

  const handleDownload = () => {
    // Check if there is data to download
    if (driverData?.view_driver_policy_totals_details.length === 0) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter
    const change = driverData?.view_driver_policy_totals_details?.map(
      (item) => ({
        account_status: item?.account_status,
        balance: item.balance,
        date_created: item.date_created,
        driver_code: item.driver_code,
        driver_id: item.driver_id,
        driver_name: item.driver_name,
        phone_number: item.phone_number,
        total_number_of_policies: item.total_number_of_policies,
        total_premium_balance: item.total_premium_balance,
        total_premium_paid: item.total_premium_paid,
        total_premium_payable: item.total_premium_payable,
        total_arrears: item.total_arrears,
        wallet_number: item.wallet_number,
        usertype: item.usertype,
        created_by: item.created_by,
      })
    )
    const firstItem = change![0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = driverData?.view_driver_policy_totals_details.map(
      (item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      }
    )
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"driverdetails"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  // useEffect(() => {
  //   paginatedData?.map((item) => {})
  // }, [])
  return (
    <>
      <CustomHeader title="Manage Drivers" />
      {openSideNav ? (
        <DetailDrawer
          title="Driver's Information"
          open={openSideNav}
          size={500}
          onClose={() => setOpenSideNav(false)}
          children={<DriverColectionDetails content={selectedRow} />}
        />
      ) : openSendMessage ? (
        <DriverModal
          title=""
          open={openSendMessage}
          size={"md"}
          focus="message"
          onClose={() => setOpenMessage(false)}
          children={
            <SendMessageDetails
              driver={selectedMessagesRows}
              onClose={() => setOpenMessage(false)}
            />
          }
        />
      ) : null}

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-2 md:lg:gap-4 lg:gap-5">
        <Card className="bg-brand-info rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between">
            <div>
              <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                {load ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  totalDrivers?.total_drivers?.aggregate?.count
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                total drivers
              </Typography>
            </div>
            <RiAdminLine size={28} color="#cbcbcb" />
          </div>
        </Card>
      </div>

      <div className="mt-10 w-full ">
        <CardBody className="shadow-sm overflow-x-scroll w-full px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold leading-none opacity-70"
            >
              List of Drivers{" "}
              <span className="text-white">
                {totalDrivers?.total_drivers?.aggregate?.count}
              </span>
            </Typography>
          </div>
          <div className="w-full overflow-auto">
            <div className="">
              <div className="flex flex-col  gap-y-4  bg-white px-3 py-4">
                <div className="flex shrink-0 flex-col justify-end gap-2 sm:flex-row">
                  <Tooltip content="Export as CSV">
                    <Button
                      onClick={handleDownload}
                      variant="filled"
                      className="capitalize text-sm font-normal flex flex-wrap gap-2"
                      size="sm"
                    >
                      <FiDownloadCloud size={20} />
                      <span>Download csv</span>
                    </Button>
                  </Tooltip>
                </div>
                <div className=" p-3 flex gap-x-2 items-center flex-col 2xl:flex-row gap-y-3 mb-3">
                  <div className=" mr-3 flex  gap-x-3 w-full">
                    <Input
                      crossOrigin="anonymous"
                      label="Search"
                      placeholder=""
                      value={searchText}
                      onChange={handleSearch}
                      icon={<i className="fas fa-search text-base"></i>}
                    />
                  </div>
                  <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                    <div className="flex items-center space-x-2">
                      <label className="font-semibold text-gray-700">
                        From
                      </label>
                      <Input
                        type="datetime-local"
                        value={startDate.slice(0, -1)} // Remove 'Z' for input value
                        onChange={handleStartDateChange}
                        size="md"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <label className="font-semibold text-gray-700">To</label>
                      <Input
                        type="datetime-local"
                        value={endDate.slice(0, -1)} // Remove 'Z' for input value
                        onChange={handleEndDateChange}
                        size="md"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selectedMessagesRows.length >= 2 && (
            <div className=" bg-white w-full pl-3 flex pb-2">
              <h1
                className=" bg-blue-600 px-2 py-2 rounded w-auto text-white cursor-pointer "
                onClick={() => setOpenMessage(true)}
              >
                Send Messages ({selectedMessagesRows.length})
              </h1>
            </div>
          )}
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              <table className="w-full bg-white min-w-max table-auto">
                <thead className="bg-white sticky z-50 top-0">
                  <tr className="">
                    {[
                      "Driver Code",
                      "Driver Name",
                      "Phone Number",
                      "Wallet Number",
                      "Deposit Balance",
                      "Number of Policies",
                      "Policy Premium Payable",
                      "Policy Premium Paid",
                      "Policy Premium Balance",
                      "Arrears",
                      "Account Status",
                      "staff/agent",
                      "Date Registered",
                      "Actions",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-2 py-4  "
                      >
                        <Typography
                          color="black"
                          className="font-bold text-[0.8rem] text-black leading-none opacity-70 capitalize"
                        >
                          {head === "Driver Code" ? (
                            <div className=" flex items-center">
                              {" "}
                              <Checkbox
                                color="blue"
                                // checked={true}
                                onChange={handleSelectAll}
                                checked={
                                  selectedMessagesRows.length ===
                                  paginatedData?.length
                                }
                                size={3}
                                className=" text-xs mr-1"
                                value={undefined}
                              />
                              {head}
                            </div>
                          ) : (
                            head
                          )}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-2 py-3  text-center"
                      : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <>
                        <tr
                          onClick={(e) => {
                            setSelectedRow(item!)
                            e.stopPropagation()
                            setOpenSideNav(true)
                          }}
                          key={item?.driver_id}
                          className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                        >
                          <>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-[0.75rem] capitalize  "
                              >
                                <div className=" flex items-center mr-10">
                                  <Checkbox
                                    color="blue"
                                    onChange={(event) => {
                                      handleRowCheckboxChange(
                                        event,
                                        item.driver_id
                                      )
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                    }}
                                    checked={
                                      selectedMessagesRows.length > 0 &&
                                      selectedMessagesRows.includes(
                                        item.driver_id
                                      )
                                    }
                                    // checked={true}
                                    // onClick={(e) => {
                                    //   e.stopPropagation()
                                    // }}
                                    size={3}
                                    className=" text-xs mr-1"
                                    value={undefined}
                                  />
                                  {item?.driver_code}
                                </div>
                              </Typography>
                            </td>
                          </>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.driver_name}
                            </Typography>
                          </td>

                          {/* <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize"
                        >
                          GHS
                          {(item?.amount ?? 0).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </td> */}
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.phone_number}
                            </Typography>
                          </td>
                          {/* <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize"
                        >
                          GHS
                          {(item?.driver_current_balance ?? 0).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </Typography>
                      </td> */}
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.wallet_number}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              GHS {""}
                              {(item?.balance ?? 0).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.total_number_of_policies}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              GHS {""}
                              {(
                                item?.total_premium_payable ?? 0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              GHS {""}
                              {(item?.total_premium_paid ?? 0).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              GHS{" "}
                              {(
                                item?.total_premium_balance ?? 0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              GHS{" "}
                              {(item?.total_arrears ?? 0).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.account_status}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize text-left pl-7"
                            >
                              Name:{" "}
                              <span className="font-normal">
                                {item?.created_by}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize text-left pl-7"
                            >
                              Client Type:{" "}
                              <span className="font-normal">
                                {item?.usertype}
                              </span>
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {moment(item?.date_created).format("LLL")}
                            </Typography>
                          </td>

                          {/* <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize"
                        >
                          {new Date(item?.created_at).toLocaleDateString()}
                        </Typography>
                      </td>
                      */}

                          <td
                            className={classes}
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          >
                            <Button
                              // onClick={() => handleRowClick(item)}
                              onClick={() => {
                                localStorage.setItem(
                                  "driver",
                                  JSON.stringify({
                                    driver_id: item?.driver_id,
                                    driver_status: item?.account_status,
                                    driver_name: item?.driver_name,
                                    driver_phone: item?.phone_number,
                                    deposit_balance: item?.balance,
                                    date_created: item?.date_created,
                                  })
                                )
                                history.push("/dashboard/i2i/manage-driver")
                                //()
                              }}
                              variant="filled"
                              color="green"
                              className="font-normal text-xs w-20 py-1 capitalize"
                            >
                              View More
                            </Button>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          {Loading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {paginatedData?.length! === 0 && !Loading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}
        </CardBody>

        {paginatedData && paginatedData?.length !== 0 && !Loading && (
          <GeneralPagination
            totalItems={totalItems?.length!}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
          // <div className="my-10 flex items-center justify-center">
          //   <div className="flex items-center gap-8">
          //     <IconButton
          //       size="sm"
          //       variant="filled"
          //       color="white"
          //       onClick={prevPage}
          //       disabled={activePage === 1}
          //     >
          //       <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
          //     </IconButton>
          //     <Typography color="white" className="font-normal">
          //       Page <strong className="text-white">{activePage}</strong> of{" "}
          //       <strong className="text-white">
          //         {Math.ceil(
          //           (driverData?.view_driver_policy_totals_details?.filter(
          //             filterData
          //           ).length || 0) / PAGE_SIZE
          //         )}
          //       </strong>
          //     </Typography>
          //     <IconButton
          //       size="sm"
          //       variant="filled"
          //       color="white"
          //       onClick={nextPage}
          //       disabled={
          //         activePage ===
          //         Math.ceil(
          //           (driverData?.view_driver_policy_totals_details?.filter(
          //             filterData
          //           ).length || 0) / PAGE_SIZE
          //         )
          //       }
          //     >
          //       <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
          //     </IconButton>
          //   </div>
          // </div>
        )}
      </div>
    </>
  )
}

export default ManageDriver
