import React, { useEffect, useMemo, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  DialogFooter,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react"
import { useHistory } from "react-router-dom"
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { FaPlus, FaUsers } from "react-icons/fa6"
import { BASEURL, DEFAULTPRIMARYCOLOR, id_types } from "../../../util"
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { useQuery } from "@apollo/client"
import {
  GETALLBANKS,
  GETALLBRANCHES,
  GETALLCOMMMISSIONS,
  GETALLDIVISIONSORWORKGROUPS,
  GETCOMPANYROLES,
  GETCOMPANYSTAFF,
  GET_ALL_DIVISIONS,
} from "../../../GraphQl/queries"
import { ToastContainer, toast } from "react-toastify"
import { DASHBOARD_USERTYPE } from "../../../types/role"
import moment from "moment"
import { StaffDetail, DetailDrawer, CustomModal } from "../../../components"
import { UserType } from "../../../types/user"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { IoMdCheckmark } from "react-icons/io"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"

type FormType = {
  name: string
  dob: string
  email: string
  cash_limit: string
  phone_number_one: string
  phone_number_two: string
  id_type: string
  id_number: string
  tin_number: string
  division_id: string
  momo_details: {
    network: string
    number: string
  }
  bank_details: {
    bank_name: string
    acct_number: string
    branch: string
    account_name: string
    code: string
  }
  // commission_id: string;
  // settlement_mode: string;
  guarantor_details: {
    name: string
    address: string
    phone: string
    id_number: string
  }
  preferred_payment_channel: string
  role_id: string
  company_id: string
  branch_id: string
  usertype: string
  commisions_setup: [
    {
      commission_id: string
      settlement_mode: string
    }
  ]
  [key: string]: any
}

export type StaffResponseType = {
  phone_number_two: string
  phone_number_one: string
  created_at: string
  division_id: string
  cash_limit: string
  tin_number: string
  staff_number: string
  name: string
  momo_details: {
    network: string
    number: string
  }
  is_first_time: string
  guarantor_details: {
    address: string
    id_number: string
    name: string
    phone: string
  }
  preferred_payment_channel: string
  email: string
  id: string
  id_number: string
  id_type: string
  active_status: string
  bank_details: {
    acct_number: string
    bank_name: string
    branch: string
    account_name: string
    code: string
  }
  dob: string
  role_id: string
  branch: {
    id: string
    name: string
  }
}

// type pHonDataType =  {
//   status: 'SUCCESS',
//   reason: 'Operation Successful',
//   transactionid: 3570839,
//   accountno: '233554092012',
//   accountname: 'FRANCIS ATINGA'
// }

// {
//   status: 'Unknown account at destination bank',
//   reason: 'Transaction could not be processed',
//   transactionid: null,
//   accountno: null,
//   accountname: null
// }

type BankData = {
  status: string
  reason: string
  transactionid: number
  accountno: string
  accountname: string
}

type PhoneDataType = {
  status: string
  reason: string
  transactionid: number
  accountno: string
  accountname: string
}

const Staff = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [open, setOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState<StaffResponseType>()
  const [showUpdateModal, setUpdateModal] = useState(false)
  const [checked, setChecked] = useState(false)
  const [cash_limit, setCashLimit] = useState("")
  const formattedUserString = localStorage.getItem("user")
  const [phoneNum, setPhoneNum] = useState("")
  const [network, setNetwork] = useState("")
  const [bankCode, setBankCode] = useState("")
  const [bankNum, setBankNum] = useState("")
  const [phoneData, setPhoneData] = useState<PhoneDataType | null>(null)
  const [bankData, setBankData] = useState<BankData | null>(null)
  const [loadVal, setLoadVal] = useState(false)
  const [loadValB, setLoadValB] = useState(false)
  const [phoneValidated, setPhoneValidated] = useState(false)
  const [bankValidated, setBankValidated] = useState(false)
  // const formattedUser = formattedUserString
  //   ? JSON.parse(formattedUserString)
  //   : null

  // let companyName: string | null = null // Initialize with null
  // if (formattedUser && typeof formattedUser === "object") {
  //   companyName = formattedUser.name
  // }

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const companyName = stateUser?.name

  const formatCurrency = (cashLimit: any) => {
    const cashLimitNumber = parseFloat(cashLimit)

    if (!isNaN(cashLimitNumber)) {
      return cashLimitNumber.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    } else {
      return ""
    }
  }
  const formatInput = (inputValue: any) => {
    const sanitizedValue = inputValue.replace(/[^\d.]/g, "")

    // Split the value into integer and decimal parts
    const [integerPart, decimalPart] = sanitizedValue.split(".")

    // Format the integer part with commas
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    )

    // If there is a decimal part, combine the formatted integer part with the decimal part
    const formattedValue = decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart

    // Return the formatted value with two decimal places
    return formattedValue
  }

  const handleChange = (e: any) => {
    const rawValue = e.target.value
    const formattedValue = formatInput(rawValue)
    setCashLimit(formattedValue)
  }

  const defaultValues = useMemo(() => {
    return {
      ...selectedRow,
    }
  }, [showUpdateModal])

  const handleOpen = (data: StaffResponseType) => {
    setOpen(!open)
    setSelectedRow(data)
  }

  const { data: roles, loading: loadingRoles } = useQuery<{
    company_role: {
      created_at: string
      description: string
      id: string
      name: string
    }[]
  }>(GETCOMPANYROLES, {
    variables: {
      id: companyId,
    },
  })

  const {
    data: staff,
    loading: loadingStaff,
    refetch: refreshStaff,
  } = useQuery<{
    company_staff: StaffResponseType[]
  }>(GETCOMPANYSTAFF, {
    variables: {
      company_id: companyId,
    },
  })
  // console.log(staff)

  const {
    data: divisions,
    loading: loadingDivisions,
    refetch: refetchDivision,
  } = useQuery<{
    divisions: {
      id: string
      name: string
    }[]
  }>(GETALLDIVISIONSORWORKGROUPS, {
    variables: {
      id: companyId,
    },
  })

  const {
    data: branches,
    loading,
    refetch,
  } = useQuery<{
    branch: {
      address: string
      code: string
      name: string
      id: string
    }[]
  }>(GETALLBRANCHES)

  const {
    data: banks,
    loading: loadingBanks,
    refetch: refechBanks,
  } = useQuery<{
    bank: {
      code: string
      id: string
      name: string
    }[]
  }>(GETALLBANKS)

  const {
    register,
    handleSubmit,
    reset,
    setValue: registersetValue,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<FormType>({
    defaultValues: {
      commisions_setup: [
        {
          commission_id: "",
          settlement_mode: "",
        },
      ],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "commisions_setup",
  })

  const {
    register: updateRegister,
    handleSubmit: handleUpdateSubmit,
    reset: resetUpdate,
    formState: { isSubmitting: updating, errors: updateError },
    setValue,
  } = useForm<FormType>({
    defaultValues: defaultValues,
  })

  const { data: commissions, loading: loadingCommissions } = useQuery<{
    commisions: {
      description: string
      id: string
      name: string
      status: string
      created_at: string
    }[]
  }>(GETALLCOMMMISSIONS)

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    const branch_code = branches?.branch?.find(
      (item) => item?.id === data?.branch_id
    )
    if (bankCode && !bankValidated) {
      return toast.error("Bank details not validated yet")
    }
    if (!phoneValidated) {
      return toast.error("Phone number is not validated")
    }
    if (data?.role_id === "Select role") {
      return toast.error("Select a valid role")
    }

    if (data?.branch_id === "Select Branch") {
      return toast.error("Select a valid branch")
    }
    if (data?.division_id === "Select division") {
      return toast.error("Select a valid division")
    }
    if (data?.preferred_payment_channel === "momo" && phoneData === null) {
      return toast.error("Please validate phone number")
    }
    if (
      data?.preferred_payment_channel === "bank" &&
      bankData?.accountname === null
    ) {
      return toast.error("Please validate bank number")
    }
    try {
      let stringifyData = {
        role_id: data?.role_id,
        name: data?.name,
        phone_number_one: data?.phone_number_one,
        phone_number_two: data?.phone_number_two,
        email: data?.email,
        // cash_limit: data?.cash_limit,
        tin_number: data?.tin_number,
        id_type: data?.id_type,
        id_number: data?.id_number,
        dob: data?.dob,
        guarantor_details: {
          name: data?.guarantor_details?.name,
          address: data?.guarantor_details?.address,
          phone: data?.guarantor_details?.phone,
          id_number: data?.guarantor_details?.id_number,
        },
        preferred_payment_channel: data?.preferred_payment_channel,
        branch_id: data?.branch_id,
        division_id: data?.division_id,
        bank_details: {
          bank_name: data?.bank_details?.bank_name,
          branch: data?.bank_details?.branch,
          acct_number: data?.bank_details?.acct_number,
          account_name: data?.bank_details.account_name,
          code: data?.bank_details.code,
        },
        momo_details: {
          network: network,
          number: phoneNum,
        },
        company_id: companyId,
        usertype: DASHBOARD_USERTYPE.company,
        client_type: DASHBOARD_USERTYPE.company,
        branch_code: branch_code?.code,
        staff_number: data?.phone_number_one,
        profile: "",
        commisions_setup: checked ? data?.commisions_setup : [],
      }

      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(stringifyData),
      }

      const req = await fetch(`${BASEURL}/create-staff`, options)

      const res = await req.json()

      if (req.ok) {
        if (res?.data?.id) {
          toast.success(res?.message ?? "Staff account created successfully")
          refreshStaff()
          reset()
        } else {
          toast.error(res?.detail ?? res?.data)
        }
      } else {
        toast.error(res?.message ?? "An error occured")
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const filterData = (filter: StaffResponseType) => {
    const isTextMatch =
      filter?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.id_type?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.phone_number_two
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.tin_number?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.staff_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.bank_details?.branch
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.bank_details?.bank_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.momo_details?.network
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.momo_details?.number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  //---------------------------
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalItems = staff?.company_staff
    ?.filter(filterData)
    ?.filter(filterData)
  const paginatedData = totalItems?.slice(startIndex, endIndex)

  //---------------------------

  // Filtered and paginated data for the current page
  // const paginatedData = staff?.company_staff
  //   ?.filter(filterData)
  //   .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  // const nextPage = () => {
  //   if (
  //     (activePage - 1) * PAGE_SIZE <
  //     (staff?.company_staff?.filter(filterData).length || 0)
  //   ) {
  //     setActivePage(activePage + 1);
  //   }
  // };

  // const prevPage = () => {
  //   if (activePage > 1) {
  //     setActivePage(activePage - 1);
  //   }
  // };

  const deleteStaff = async (staff_id: string) => {
    try {
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          staff_id,
          usertype: DASHBOARD_USERTYPE.company,
        }),
      }

      const req = await fetch(`${BASEURL}/delete-staff`, options)

      const res = await req.json()

      if (req.ok) {
        toast.success(res?.message ?? "Staff deleted successfully")
        refreshStaff()
      } else {
        toast.error(res?.message ?? "An error occured")
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  // {
  //   "accountnumber": “”,
  //   "bankcode": “”,
  //   "network": “VODAFONE”,
  //   "accounttype": "MOBILE_MONEY",
  //   "walletnumber": “0508305891”
  // }

  // for momo

  // {
  //   "accountnumber": “1272282738”,
  //   "bankcode": “021”,
  //   "network": “”,
  //   "accounttype": “BANK”,
  //   "walletnumber": “”
  // }
  // for bank

  const fxnPhone = async () => {
    try {
      setLoadVal(true)
      const res = await fetch(`${BASEURL}/validate-account-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          accountnumber: "",
          bankcode: "",
          network: network,
          accounttype: "MOBILE_MONEY",
          walletnumber: phoneNum,
        }),
      })

      if (!res.ok) {
        toast.error("validation faild")
        return
      }

      const data = await res.json()

      if (!data) {
        toast.error("validation failed")
        return
      }
      if (data?.status === "Rejected" || data?.status === "User not found") {
        toast.error("Validation failed")
        setPhoneValidated(false)
        setPhoneData(null)
        return
      } else {
        setPhoneData(data)
        setPhoneValidated(true)
        toast.success("Phone number validated successfully")
      }
    } catch (e) {
    } finally {
      setLoadVal(false)
    }
  }

  const fxnBank = async () => {
    try {
      setLoadValB(true)
      const res = await fetch(`${BASEURL}/validate-account-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          accountnumber: bankNum,
          bankcode: bankCode,
          network: "",
          accounttype: "BANK",
          walletnumber: "",
        }),
      })

      if (!res.ok) {
        toast.error("validation faild")
        return
      }

      const data = await res.json()

      if (!data.accountname || data === null) {
        toast.error("Validation failed")
        setBankValidated(false)
        return
      }
      setBankValidated(true)
      setBankData(data)
      toast.success("Bank details validated successfully")
    } catch (e) {
    } finally {
      setLoadValB(false)
    }
  }

  const handleUpdate: SubmitHandler<FormType> = async (data) => {
    if (data?.branch_id?.toLowerCase() === "select branch") {
      return toast.error("Select branch")
    }
    if (data?.division_id?.toLowerCase() === "select division") {
      return toast.error("Select division")
    }

    try {
      let stringifyData = {
        name: data?.name,
        dob: data?.dob,
        staff_id: selectedRow?.id,
        email: data?.email,
        phone_number_one: data?.phone_number_one,
        phone_number_two: data?.phone_number_two,
        cash_limit: data?.cash_limit,
        id_type: data?.id_type,
        id_number: data?.id_number,
        tin_number: data?.tin_number,
        division_id: data?.division_id,
        momo_details: {
          network: data?.momo_details?.network,
          number: data?.momo_details?.number,
        },
        preferred_payment_channel: data?.preferred_payment_channel,
        bank_details: {
          bank_name: data?.bank_details?.bank_name,
          acct_number: data?.bank_details?.acct_number,
          branch: data?.bank_details?.branch,
          account_name: data?.bank_details.account_name,
          code: data?.bank_details.code,
        },
        guarantor_details: {
          name: data?.guarantor_details?.name,
          address: data?.guarantor_details?.address,
          phone: data?.guarantor_details?.phone,
          id_number: data?.guarantor_details?.id_number,
        },
        role_id: data?.role_id,
        company_id: companyId,
        branch_id: data?.branch_id,
        usertype: DASHBOARD_USERTYPE.company,
      }

      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(stringifyData),
      }
      const req = await fetch(`${BASEURL}/edit-staff`, options)
      const res = await req.json()

      if (req.ok) {
        if (res?.data?.id) {
          toast.success(res?.message ?? "Staff account updated successfully")
          refreshStaff()
          resetUpdate()
          setUpdateModal(false)
        } else {
          toast.error(res?.detail ?? res?.data)
        }
      } else {
        toast.error(res?.message ?? "An error occured")
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  // set default values
  React.useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key as keyof typeof defaultValues as any, value)
      })
    }
  }, [defaultValues])

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  return (
    <>
      <CustomHeader title="Staff registration" />

      <DetailDrawer
        onClose={() => setOpen(false)}
        open={open}
        title="Staff Detail"
        size={400}
        children={<StaffDetail content={selectedRow} />}
      />

      {/* update modal */}
      <CustomModal
        onClose={() => {
          setPhoneNum("")
          setNetwork("")
          setBankCode("")
          setBankNum("")
          setPhoneData(null)
          setBankData(null)
          setPhoneValidated(false)
          setBankValidated(false)
          setUpdateModal(false)
        }}
        open={showUpdateModal}
        size={"xl"}
        title="Update Staff"
        className="h-full overflow-scroll scrolls"
        children={
          <>
            <ToastContainer />
            <div>
              <form onSubmit={handleUpdateSubmit(handleUpdate)}>
                <div className="scrolls w-full">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="p-3 bg-white rounded-b-xl">
                      <div className="mt-5 flex flex-col items-end justify-end">
                        <div className="w-full">
                          <div className="mb-6 flex flex-col gap-2">
                            <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-2 font-semibold text-[14px] w-1/3"
                              >
                                Role
                              </Typography>
                              <select
                                {...updateRegister("role_id", {
                                  required: true,
                                })}
                                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                <option disabled defaultChecked>
                                  {loadingRoles
                                    ? "Loading roles"
                                    : "Select role"}
                                </option>
                                {roles?.company_role?.map((role) => (
                                  <option
                                    key={role?.id}
                                    label={role?.name}
                                    value={role?.id}
                                  >
                                    {role?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-2 font-semibold text-[14px] w-1/3"
                              >
                                Name
                              </Typography>
                              <Input
                                crossOrigin="anonymous"
                                size="md"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                                error={!!updateError.name}
                                {...updateRegister("name", { required: true })}
                              />
                            </div>
                            <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-2 font-semibold text-[14px] w-1/3"
                              >
                                Phone number 1
                              </Typography>
                              <Input
                                crossOrigin="anonymous"
                                size="md"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                                {...updateRegister("phone_number_one", {
                                  required: true,
                                })}
                                error={!!updateError.phone_number_one}
                              />
                            </div>
                            <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-2 font-semibold text-[14px] w-1/3"
                              >
                                Phone number 2
                              </Typography>
                              <Input
                                crossOrigin="anonymous"
                                size="md"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                                {...updateRegister("phone_number_two", {
                                  required: false,
                                })}
                              />
                            </div>
                            {/* <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-2 font-semibold text-[14px] w-1/3"
                              >
                                Cash limit
                              </Typography>
                              <Input
                                crossOrigin="anonymous"
                                size="md"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                                {...updateRegister("cash_limit", {
                                  required: true,
                                })}
                              />
                            </div> */}
                            <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-2 font-semibold text-[14px] w-1/3"
                              >
                                Email
                              </Typography>
                              <Input
                                crossOrigin="anonymous"
                                size="md"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                                error={!!updateError.email}
                                {...updateRegister("email", { required: true })}
                              />
                            </div>
                            <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-2 font-semibold text-[14px] w-1/3"
                              >
                                TIN
                              </Typography>
                              <Input
                                crossOrigin="anonymous"
                                size="md"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                                {...updateRegister("tin_number", {
                                  required: true,
                                })}
                                error={!!updateError.tin_number}
                              />
                            </div>
                            <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-2 font-semibold text-[14px] w-1/3"
                              >
                                National id type
                              </Typography>
                              <select
                                {...updateRegister("id_type", {
                                  required: true,
                                })}
                                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                {id_types?.map((item) => (
                                  <option value={item?.name} label={item?.name}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-2 font-semibold text-[14px] w-1/3"
                              >
                                ID number
                              </Typography>
                              <Input
                                crossOrigin="anonymous"
                                size="md"
                                error={!!updateError.id_number}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                                {...updateRegister("id_number", {
                                  required: true,
                                })}
                              />
                            </div>
                            <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-2 font-semibold text-[14px] w-1/3"
                              >
                                Date of birth
                              </Typography>
                              <Input
                                error={!!updateError.dob}
                                crossOrigin="anonymous"
                                size="md"
                                type="date"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                                {...updateRegister("dob", { required: true })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <Typography className="text-md text-gray-600">
                        Guarantor Details
                      </Typography>
                      <hr className="my-1" />
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Guarantor name
                        </Typography>
                        <Input
                          crossOrigin="anonymous"
                          size="md"
                          error={!!updateError.guarantor_details?.name}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          {...updateRegister("guarantor_details.name", {
                            required: false,
                          })}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Address
                        </Typography>
                        <Input
                          crossOrigin="anonymous"
                          size="md"
                          error={!!updateError.guarantor_details?.address}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          {...updateRegister("guarantor_details.address", {
                            required: false,
                          })}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Phone number
                        </Typography>
                        <Input
                          crossOrigin="anonymous"
                          size="md"
                          error={!!updateError.guarantor_details?.phone}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          {...updateRegister("guarantor_details.phone", {
                            required: false,
                          })}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Guarantor ID
                        </Typography>
                        <Input
                          crossOrigin="anonymous"
                          size="md"
                          error={!!updateError.guarantor_details?.id_number}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          {...updateRegister("guarantor_details.id_number", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="p-3 bg-white rounded-b-xl">
                        <div className="mt-5 flex flex-col items-end justify-end">
                          <div className="w-full">
                            <div className="mb-6 flex flex-col gap-2">
                              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                                <Typography
                                  variant="h6"
                                  color="blue-gray"
                                  className="mb-2 font-semibold text-[14px] w-1/3"
                                >
                                  Branch
                                </Typography>
                                <select
                                  {...updateRegister("branch_id", {
                                    required: true,
                                  })}
                                  defaultChecked
                                  defaultValue={defaultValues?.branch?.id}
                                  placeholder="Division"
                                  className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                >
                                  <option selected defaultChecked>
                                    {loading ? "Loading..." : "Select Branch"}
                                  </option>
                                  {branches?.branch?.map((item) => (
                                    <option
                                      key={item?.id}
                                      value={item?.id}
                                      label={`${item?.name} (${item?.code})`}
                                    >
                                      {item?.name} (${item?.code})
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                                <Typography
                                  variant="h6"
                                  color="blue-gray"
                                  className="mb-2 font-semibold text-[14px] w-1/3"
                                >
                                  Division
                                </Typography>
                                <select
                                  {...updateRegister("division_id", {
                                    required: true,
                                  })}
                                  placeholder="Division"
                                  className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  defaultValue={defaultValues?.division_id}
                                >
                                  <option selected defaultChecked>
                                    {loadingDivisions
                                      ? "Loading..."
                                      : "Select division"}
                                  </option>
                                  {divisions?.divisions?.map((item) => (
                                    <option value={item?.id} label={item?.name}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Typography className="text-md text-gray-600">
                          Bank Detials
                        </Typography>
                        <hr className="my-1" />
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-1/3"
                          >
                            Bank transfer
                          </Typography>
                          <div className="w-full flex flex-col my-1 gap-2">
                            <select
                              {...updateRegister("bank_details.bank_name", {
                                required: false,
                              })}
                              onChange={(e) => {
                                const selectedBankCode =
                                  e.target.options[
                                    e.target.selectedIndex
                                  ].getAttribute("data-code")
                                setBankCode(selectedBankCode!)
                                setValue(
                                  "bank_details.code",
                                  selectedBankCode ? selectedBankCode : ""
                                )
                              }}
                              placeholder="Bank name"
                              className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              <option selected defaultChecked>
                                {loadingBanks ? "Loading..." : "Select Bank"}
                              </option>
                              {banks?.bank?.map((item) => (
                                <option
                                  key={item?.code}
                                  value={item?.name}
                                  data-code={item?.code}
                                  label={`${item?.name}`}
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Account name"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("bank_details.account_name", {
                                required: false,
                              })}
                            />
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Branch"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("bank_details.branch", {
                                required: false,
                              })}
                            />
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Account number"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("bank_details.acct_number", {
                                required: false,
                              })}
                              onChange={(e) => {
                                setBankNum(e.target.value)
                              }}
                            />
                            {(bankCode || bankNum) && (
                              <div className="flex items-center justify-between">
                                {bankValidated && bankData ? (
                                  <div className="text-green-500 flex items-center text-[0.85rem] gap-x-3">
                                    <div>{bankData.accountname}</div>
                                    <div>
                                      <IoMdCheckmark
                                        size={22}
                                        className=" text-green-500 text-xl"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="text-[0.85rem]">
                                    <p className=" text-red-500">
                                      Not validated yet{" "}
                                      <span className=" ml-1 text-red-500 text-[1.2rem]">
                                        x
                                      </span>
                                    </p>
                                    <p className="text-red-500">
                                      Bank details are required
                                    </p>
                                  </div>
                                )}

                                <button
                                  onClick={(e) => {
                                    e.preventDefault()
                                    fxnBank()
                                  }}
                                  className=" bg-green-400 px-[0.4rem] rounded-md text-white p-[0.2rem]"
                                >
                                  {loadValB ? "validating..." : "validate"}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <Typography className="text-md text-gray-600">
                          Mobile Money Details
                        </Typography>
                        <hr className="my-1" />
                        <div className="mt-3">
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              Wallet Type
                            </Typography>
                            <div className="w-full flex flex-col gap-2 my-1">
                              <select
                                {...updateRegister("momo_details.network", {
                                  required: true,
                                })}
                                onChange={(e) => {
                                  setNetwork(e.target.value)
                                }}
                                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                <option label="MTN" value={"MTN"}>
                                  MTN
                                </option>
                                <option label="AirtelTigo" value={"AirtelTigo"}>
                                  AirtelTigo
                                </option>
                                <option label="Vodafone" value={"Vodafone"}>
                                  Vodafone
                                </option>
                              </select>
                              <Input
                                value={undefined}
                                {...updateRegister("momo_details.number", {
                                  required: true,
                                })}
                                onChange={(e) => {
                                  setPhoneNum(e.target.value)
                                }}
                                crossOrigin="anonymous"
                                placeholder="Wallet Number"
                                size="md"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                              />
                              {(phoneNum || network) && (
                                <div className="flex items-center justify-between">
                                  {phoneValidated && phoneData ? (
                                    <div className="text-green-500 flex items-center text-[0.85rem] gap-x-3">
                                      <div>{phoneData?.accountname}</div>
                                      <div>
                                        <IoMdCheckmark
                                          size={22}
                                          className=" text-green-500 text-xl"
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="text-[0.85rem]">
                                      <p className=" text-red-500">
                                        Not validated yet{" "}
                                        <span className=" ml-1 text-red-500 text-[1.2rem]">
                                          x
                                        </span>
                                      </p>
                                      <p className="text-red-500">
                                        Wallet number is required
                                      </p>
                                    </div>
                                  )}

                                  <button
                                    onClick={(e) => {
                                      e.preventDefault()
                                      fxnPhone()
                                    }}
                                    className=" bg-green-400 px-[0.4rem] rounded-md text-white p-[0.2rem]"
                                  >
                                    {loadVal ? "validating..." : "validate"}
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <Typography className="text-md text-gray-600">
                          Preferred payment Method
                        </Typography>
                        <hr className="my-1" />
                        <div className="mt-3">
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              Payment Method
                            </Typography>
                            <div className="w-full flex flex-col gap-2 my-1">
                              <select
                                {...updateRegister(
                                  "preferred_payment_channel",
                                  { required: true }
                                )}
                                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                <option label="Momo" value={"momo"}>
                                  Momo
                                </option>
                                <option label="Bank" value={"bank"}>
                                  Bank
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <DialogFooter>
                  <Button
                    variant="text"
                    color="red"
                    onClick={() => setUpdateModal(false)}
                    className="mr-1"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    type="submit"
                    className="flex items-center justify-center"
                    variant="gradient"
                    color="green"
                  >
                    {updating ? (
                      <Spinner className="h-4 w-4 text-white" />
                    ) : (
                      <span>Update</span>
                    )}
                  </Button>
                </DialogFooter>
              </form>
            </div>
          </>
        }
      />

      <div className="">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5"
        >
          <div className=" rounded-lg overflow-hidden w-full">
            <div
              style={{
                background:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className=" p-3"
            >
              <Typography className="text-base text-white">
                User Details
              </Typography>
            </div>
            <div className="p-3 bg-white rounded-b-xl">
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Role <span className=" text-red-600">*</span>
                      </Typography>
                      <select
                        {...register("role_id", { required: true })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option defaultChecked>
                          {loadingRoles ? "Loading roles" : "Select role"}
                        </option>
                        {roles?.company_role?.map((role) => (
                          <option
                            key={role?.id}
                            label={role?.name}
                            value={role?.id}
                          >
                            {role?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Name <span className=" text-red-600">*</span>
                      </Typography>
                      <Input
                        value={undefined}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("name", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Phone number 1 <span className=" text-red-600">*</span>
                      </Typography>
                      <Input
                        value={undefined}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("phone_number_one", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Phone number 2
                      </Typography>
                      <Input
                        value={undefined}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("phone_number_two", { required: false })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Email <span className=" text-red-600">*</span>
                      </Typography>
                      <Input
                        value={undefined}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("email", { required: true })}
                      />
                    </div>
                    {/* <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Cash limit
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("cash_limit", { required: true })}
                      />
                    </div> */}
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        TIN <span className=" text-red-600">*</span>
                      </Typography>
                      <Input
                        value={undefined}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("tin_number", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        National id type{" "}
                        <span className=" text-red-600">*</span>
                      </Typography>
                      <select
                        {...register("id_type", { required: true })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {id_types?.map((item) => (
                          <option value={item?.name} label={item?.name}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        ID number <span className=" text-red-600">*</span>
                      </Typography>
                      <Input
                        value={undefined}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("id_number", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Date of birth <span className=" text-red-600">*</span>
                      </Typography>
                      <Input
                        value={undefined}
                        crossOrigin="anonymous"
                        size="md"
                        type="date"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("dob", { required: true })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Guarantor Details
              </Typography>
              <hr className="my-1" />
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Guarantor name
                </Typography>
                <Input
                  value={undefined}
                  crossOrigin="anonymous"
                  size="md"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  {...register("guarantor_details.name", { required: false })}
                />
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Address
                </Typography>
                <Input
                  value={undefined}
                  crossOrigin="anonymous"
                  size="md"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  {...register("guarantor_details.address", {
                    required: false,
                  })}
                />
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Phone number
                </Typography>
                <Input
                  value={undefined}
                  crossOrigin="anonymous"
                  size="md"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  {...register("guarantor_details.phone", { required: false })}
                />
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Guarantor ID
                </Typography>
                <Input
                  value={undefined}
                  crossOrigin="anonymous"
                  size="md"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  {...register("guarantor_details.id_number", {
                    required: false,
                  })}
                />
              </div>
            </div>
          </div>

          <div className=" rounded-lg overflow-hidden w-full">
            <div
              style={{
                background:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className=" p-3"
            >
              <Typography className="text-base text-white">
                Branch and Division
              </Typography>
            </div>
            <div className="p-3 bg-white rounded-b-xl">
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Branch <span className=" text-red-600">*</span>
                      </Typography>
                      <select
                        {...register("branch_id", { required: true })}
                        placeholder="Division"
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected defaultChecked>
                          {loading ? "Loading..." : "Select Branch"}
                        </option>
                        {branches?.branch?.map((item) => (
                          <option
                            key={item?.code}
                            value={item?.id}
                            label={`${item?.name} (${item?.code})`}
                          >
                            {item?.name} (${item?.code})
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Division <span className=" text-red-600">*</span>
                      </Typography>
                      <select
                        {...register("division_id", { required: true })}
                        placeholder="Division"
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected defaultChecked>
                          {loadingDivisions ? "Loading..." : "Select division"}
                        </option>
                        {divisions?.divisions?.map((item) => (
                          <option
                            key={item?.id}
                            value={item?.id}
                            label={item?.name}
                          >
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Cash limit
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        value={cash_limit}
                        onChange={handleChange}
                        // {...register("cash_limit", { required: true })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Bank Detials
              </Typography>
              <hr className="my-1" />
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Bank transfer
                </Typography>
                <div className="w-full flex flex-col my-1 gap-2">
                  <select
                    {...register("bank_details.bank_name", { required: false })}
                    onChange={(e) => {
                      const selectedBankCode =
                        e.target.options[e.target.selectedIndex].getAttribute(
                          "data-code"
                        )
                      setBankCode(selectedBankCode!)
                      registersetValue(
                        "bank_details.code",
                        selectedBankCode ? selectedBankCode : ""
                      )
                    }}
                    placeholder="Bank name"
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">
                      {loadingBanks ? "Loading..." : "Select Bank"}
                    </option>
                    {banks?.bank?.map((item) => (
                      <option
                        key={item?.code}
                        value={item?.name}
                        data-code={item?.code}
                        label={`${item?.name}`}
                      >
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  <Input
                    value={undefined}
                    crossOrigin="anonymous"
                    placeholder="Account name"
                    size="md"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    {...register("bank_details.account_name", {
                      required: false,
                    })}
                  />
                  <Input
                    value={undefined}
                    crossOrigin="anonymous"
                    placeholder="Branch"
                    size="md"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    {...register("bank_details.branch", { required: false })}
                  />
                  <Input
                    value={undefined}
                    crossOrigin="anonymous"
                    placeholder="Account number"
                    size="md"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    {...register("bank_details.acct_number", {
                      required: false,
                    })}
                    onChange={(e) => {
                      setBankNum(e.target.value)
                    }}
                  />
                  {(bankCode || bankNum) && (
                    <div className="flex items-center justify-between">
                      {bankValidated && bankData ? (
                        <div className="text-green-500 flex items-center text-[0.85rem] gap-x-3">
                          <div>{bankData.accountname}</div>
                          <div>
                            <IoMdCheckmark
                              size={22}
                              className=" text-green-500 text-xl"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="text-[0.85rem]">
                          <p className=" text-red-500">
                            Not validated yet{" "}
                            <span className=" ml-1 text-red-500 text-[1.2rem]">
                              x
                            </span>
                          </p>
                          <p className="text-red-500">
                            Bank details are required
                          </p>
                        </div>
                      )}

                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          fxnBank()
                        }}
                        className=" bg-green-400 px-[0.4rem] rounded-md text-white p-[0.2rem]"
                      >
                        {loadValB ? "validating..." : "validate"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Mobile Money Details
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Wallet Type <span className=" text-red-600">*</span>
                  </Typography>
                  <div className="w-full flex flex-col gap-2 my-1">
                    <select
                      {...register("momo_details.network", { required: true })}
                      onChange={(e) => {
                        setNetwork(e.target.value)
                      }}
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option label="" value={""}>
                        Select Network
                      </option>
                      <option label="MTN" value={"MTN"}>
                        MTN
                      </option>
                      <option label="AirtelTigo" value={"AirtelTigo"}>
                        AirtelTigo
                      </option>
                      <option label="Vodafone" value={"Vodafone"}>
                        Vodafone
                      </option>
                    </select>
                    <Input
                      value={phoneNum}
                      {...register("momo_details.number", { required: true })}
                      crossOrigin="anonymous"
                      onChange={(e) => {
                        setPhoneNum(e.target.value)
                      }}
                      placeholder="Wallet Number"
                      size="md"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <div className="flex items-center justify-between">
                      {phoneValidated && phoneData ? (
                        <div className="text-green-500 flex items-center text-[0.85rem] gap-x-3">
                          <div>{phoneData?.accountname}</div>
                          <div>
                            <IoMdCheckmark
                              size={22}
                              className=" text-green-500 text-xl"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="text-[0.85rem]">
                          <p className=" text-red-500">
                            Not validated yet{" "}
                            <span className=" ml-1 text-red-500 text-[1.2rem]">
                              x
                            </span>
                          </p>
                          <p className="text-red-500">
                            Wallet number is required
                          </p>
                        </div>
                      )}

                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          fxnPhone()
                        }}
                        className=" bg-green-400 px-[0.4rem] rounded-md text-white p-[0.2rem]"
                      >
                        {loadVal ? "validating..." : "validate"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Preferred payment Method
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Payment Method
                  </Typography>
                  <div className="w-full flex flex-col gap-2 my-1">
                    <select
                      {...register("preferred_payment_channel", {
                        required: true,
                      })}
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option label="Momo" value={"momo"}>
                        Momo
                      </option>
                      <option label="Bank" value={"bank"}>
                        Bank
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="my-5">
                <label className="flex items-center gap-1">
                  <input
                    // value={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    type="checkbox"
                    className="w-4 h-4"
                  />
                  <span className="text-xs font-normal">
                    Do you want to add commission to this staff
                  </span>
                </label>
              </div>
              {checked && (
                <>
                  <Typography className="text-md text-gray-600">
                    Commission Setup
                  </Typography>
                  <hr className="my-1" />{" "}
                  {fields.map((commisions_setup, index) => (
                    <div
                      key={index}
                      className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200"
                    >
                      <div className="mt-3 w-full">
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className=" font-semibold text-[14px] w-1/3"
                          >
                            Commission Type
                          </Typography>
                          <div className="w-full flex flex-col gap-2 my-1">
                            <select
                              {...register(
                                `commisions_setup.${index}.commission_id`,
                                { required: true }
                              )}
                              defaultValue={commisions_setup.commission_id}
                              className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              <option selected defaultChecked>
                                {loadingCommissions
                                  ? "Loading..."
                                  : "Select commission"}
                              </option>
                              {commissions?.commisions?.map((item) => (
                                <option
                                  key={item?.id}
                                  value={item?.id}
                                  label={`${item?.name}`}
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Select mode of settlement
                          </Typography>
                          <div className="w-full flex flex-col gap-2 my-1">
                            <select
                              placeholder="Mode of settlement"
                              {...register(
                                `commisions_setup.${index}.settlement_mode`,
                                { required: true }
                              )}
                              defaultValue={commisions_setup.commission_id}
                              className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              <option selected defaultChecked>
                                {loadingCommissions
                                  ? "Loading..."
                                  : "Select settlement mode"}
                              </option>
                              <option
                                label="Instant"
                                className="capitalize"
                                value={"Instant"}
                              >
                                Instant
                              </option>
                              <option
                                label="manual"
                                className="capitalize"
                                value={"manual"}
                              >
                                Manual
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div>
                        <Button
                          className={`${index === 0 && "mt-6"}`}
                          size="md"
                          color="red"
                          type="button"
                          onClick={() => remove(index)}
                        >
                          <BiTrash />
                        </Button>
                      </div>
                    </div>
                  ))}
                  <Button
                    className="mt-4 flex items-center gap-1 capitalize font-normal"
                    type="button"
                    size="sm"
                    onClick={() =>
                      append({
                        commission_id: "",
                        settlement_mode: "",
                      })
                    }
                  >
                    <FaPlus />
                    <span>Add commission</span>
                  </Button>
                </>
              )}
              <div className="flex items-end justify-end">
                <Button
                  type="submit"
                  size="sm"
                  style={{
                    background:
                      colors?.length! > 1
                        ? `${colors![0]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`,
                  }}
                  className="flex flex-row items-center font-normal"
                >
                  <BiPlus size={20} />

                  {isSubmitting ? (
                    <Spinner className="w-4 h-4" />
                  ) : (
                    "Create client"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>

        <div className="mt-10 w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="text-base"
            >
              List of Staff of {companyName}
            </Typography>
          </div>
          <div className="py-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-center">
                <thead className="">
                  <tr>
                    {[
                      "name",
                      // "Profile",
                      "Phone number",
                      "TIN",
                      "Bank Details",
                      "Momo Details",
                      "Date created",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-4 py-2  text-center"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={item?.name + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200  hover:cursor-pointer"
                      >
                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.name}
                          </Typography>
                        </td>

                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              number one:{" "}
                              <span className="font-normal">
                                {item?.phone_number_one}
                              </span>
                            </Typography>
                            {item?.phone_number_two?.length > 1 && (
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                Number Two:{" "}
                                <span className="font-normal">
                                  {item?.phone_number_two}
                                </span>
                              </Typography>
                            )}
                          </div>
                        </td>

                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs "
                          >
                            {item?.tin_number}
                          </Typography>
                        </td>

                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Bank:{" "}
                              <span className="font-normal">
                                {item?.bank_details?.bank_name}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Branch:{" "}
                              <span className="font-normal">
                                {item?.bank_details?.branch}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Network:{" "}
                              <span className="font-normal">
                                {item?.momo_details?.network}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              number:{" "}
                              <span className="font-normal">
                                {item?.momo_details?.number}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize "
                          >
                            <span className="font-normal">
                              {moment(item?.created_at).format("LLLL")}
                            </span>
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Button
                            size="sm"
                            color="teal"
                            className="text-xs font-normal capitalize bg-brand-teal"
                            onClick={() => {
                              const branch_name = branches?.branch?.find(
                                (branch) => item?.branch?.id === branch?.id
                              )
                              localStorage.setItem(
                                "staff",
                                JSON.stringify({
                                  id: item?.id,
                                  number: item?.staff_number,
                                  name: item?.name,
                                  phone_number: item?.phone_number_one,
                                  role_id: item?.role_id,
                                  intermediary_type: "staff",
                                  branch: branch_name?.address,
                                })
                              )
                              history.push("/dashboard/setup/manage-staff")
                              //()
                            }}
                          >
                            Manage staff
                          </Button>
                          <span className="mx-1"></span>
                          <Button
                            onClick={() => {
                              setSelectedRow(item)
                              setUpdateModal(true)
                              setPhoneNum("")
                              setNetwork("")
                              setBankCode("")
                              setBankNum("")
                              setPhoneData(null)
                              setBankData(null)
                              setPhoneValidated(false)
                              setBankValidated(false)
                            }}
                            size="sm"
                            color="teal"
                            className="text-xs font-normal capitalize bg-brand-green"
                          >
                            Edit
                          </Button>
                          <span className="mx-1"></span>
                          <IconButton
                            onClick={() => deleteStaff(item?.id)}
                            color="red"
                            size="sm"
                          >
                            <BiTrash />
                          </IconButton>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>

            {loadingStaff && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loadingStaff && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {paginatedData?.length! === 0 && !loadingStaff && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="white" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {paginatedData && paginatedData?.length !== 0 && !loadingStaff && (
              <GeneralPagination
                color="black"
                totalItems={totalItems?.length!}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  )
}

export default Staff
