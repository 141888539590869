import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react"
import { BiPlus, BiTrash } from "react-icons/bi"
// import { IconEdit } from "@tabler/icons";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "@apollo/client"
import {
  GET_BUSINESS_CLASSES,
  GET_COVERTYPES_SUBCLASS,
  GET_SUBCLASSES,
  GETBUSINESSCLASSES,
  GETCOVERTYPES,
  GETPREMIUMRATES,
  GETSUBCLASSES,
} from "../../../GraphQl/queries"
import { GET_PREMIUM_RATES } from "../../../GraphQl/queries"
import { toast } from "react-toastify"
import {
  ADDPREMIUMRATE,
  CREATECOVER_TYPE_PERIL_RATES,
  DELETEPREMIUMRATE,
  UPDATEPREMIUMRATE,
} from "../../../GraphQl/mutations"
import { SubClassType } from "../business-class/manage"
import { CoverTypes, FormType, PremuimRateType } from "../../../types/query"
import { BusinessClassesType, BusinessClassType } from "../../../types/class"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { set } from "lodash"
import { useHistory } from "react-router-dom"

type CoverType2 = {
  mainId: string
  coverId: string
  coverName: string
  coverDisc: string
  class: string
  subclass: string
}

type CoverType = {
  id: string
  name: string
  description: string
}

type Subclass = {
  id: string
  name: string
  __typename: string
}

type CompanySubclassCovertypes = {
  description: string
  id: string
  subclass: Subclass
  covertypes: CoverType[] | null
  __typename: string
}

type CoverType1 = {
  id: string
  covertypes: [
    {
      id: string
      name: string
      status: string
      description: string
    }
  ]
  description: string | null
  __typename: string
}

const PremiumRatesSetup = () => {
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const PAGE_SIZE = 10
  const [isEditing, setIsEditing] = useState(false)
  const [editId, setEditId] = useState<any>()
  const [subClassData, checkSubClassData] = useState<CoverType[] | []>([])

  const history = useHistory()
  const [selectedClass, setSelectedClass] = useState({ id: "", name: "" })
  const [selectedSubClass, setSelectedSubClass] = useState({ id: "", name: "" })

  const handleChange = (e: any) => {
    const selectedId = e.target.value // Get the selected ID
    const selectedName = e.target.options[e.target.selectedIndex].text // Get the selected name

    // Update state with both ID and name
    setSelectedClass({
      id: selectedId,
      name: selectedName,
    })
  }

  console.log(selectedClass)
  const handleChangeSubClass = (e: any) => {
    const selectedId = e.target.value // Get the selected ID
    const selectedName = e.target.options[e.target.selectedIndex].text // Get the selected name

    // Update state with both ID and name
    setSelectedSubClass({
      id: selectedId,
      name: selectedName,
    })
  }

  console.log(selectedSubClass)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm<FormType>()

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const watchClass = watch("class")
  const watchSubClass = watch("subclass")
  console.log(watchSubClass, watchClass)

  const { data, loading: loadingSubClass } = useQuery<{
    company_subclass_covertypes: CompanySubclassCovertypes[]
  }>(GET_SUBCLASSES, {
    variables: {
      company_id: companyId,
      business_class: selectedClass?.id,
    },
  })

  const {
    data: businessClasses,
    refetch: refreshBusinessClass,
    loading: loadingData,
  } = useQuery<{
    business_classes: BusinessClassesType[]
  }>(GET_BUSINESS_CLASSES)

  const {
    data: coverTypes,
    refetch: refetchCover,
    loading: loadingCover,
  } = useQuery<{
    company_subclass_covertypes: CoverType1[]
  }>(GET_COVERTYPES_SUBCLASS, {
    variables: {
      sub_class_id: selectedSubClass?.id,
      company_id: companyId,
    },
  })
  // console.log(watchSubClass)
  // console.log(coverTypes)

  const checkBisClass = businessClasses?.business_classes?.filter(
    (item) => item?.id === watchClass
  )

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const filterData = (filter: CoverType2) => {
    const isTextMatch =
      filter?.coverDisc?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.coverName?.toLowerCase()?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  const coverTypesArr = coverTypes?.company_subclass_covertypes
    ?.map((cover) => {
      return cover?.covertypes?.map((item) => {
        return {
          mainId: cover?.id,
          coverId: item?.id,
          coverName: item?.name,
          coverDisc: item?.description,
          class: selectedClass?.name,
          subclass: selectedSubClass?.name,
        }
      })
    })
    ?.flat()

  // console.log(coverTypesArr)

  const takeSubClass = data?.company_subclass_covertypes?.filter(
    (item) => watchSubClass === item?.subclass?.id
  )

  const handleCoverType = () => {
    const cover = takeSubClass![0].covertypes
    if (takeSubClass === null || cover === null) {
      checkSubClassData([])
    } else {
      checkSubClassData(cover)
    }
  }

  const data1 =
    takeSubClass === undefined
      ? []
      : takeSubClass![0]?.covertypes === null
      ? []
      : takeSubClass![0]?.covertypes

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalItems = (coverTypesArr as CoverType2[])?.filter(filterData)

  const paginatedData = totalItems?.slice(startIndex, endIndex)

  const [CreatePremiumRate, { loading: RequestLoading }] = useMutation(
    CREATECOVER_TYPE_PERIL_RATES,
    {
      onCompleted: (data) => {
        toast.success("Premium rate Successfully added")
      },
      onError: (error) =>
        toast.error(error?.message ?? "Error creating premium rate"),
    }
  )

  const handleEdit = (item: PremuimRateType) => {
    setIsEditing(true)
    setEditId(item.id)
    reset({
      class: item.class_id || "all",
      subclass: item.subclass_id || "all",
      cover: item.covertype_id || "all",
      rate: item.premium_rate,
      description: item.premium_description,
    })
  }
  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  useEffect(() => {
    refreshBusinessClass()
  }, [watchClass])

  return (
    <>
      <CustomHeader title="Coverage Management" />
      <div className="bg-black p-3 text-white">
        <Typography
          style={{
            color:
              colors?.length! > 1
                ? `${colors![0]?.toLowerCase()}`
                : `${DEFAULTPRIMARYCOLOR}`,
          }}
          className="text-base"
        >
          List of all cover types
        </Typography>
      </div>

      <div className="w-full p-4 bg-white rounded-lg shadow-md">
        <CardBody className="shadow-none  px-0 py-0  rounded-none">
          <div className="p-4 flex justify-between gap-6 w-[100%] mb-6">
            <div className="w-full mr-3">
              <Input
                crossOrigin="anonymous"
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div>

            <div className="w-full flex items-center">
              <Typography
                variant="h6"
                color="blue-gray"
                className=" font-semibold text-[17px]"
              >
                Class of Business
              </Typography>
              <select
                id="Class"
                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                {...register("class", { required: true })}
                onChange={handleChange}
              >
                <option label="All" value="all">
                  {loadingData ? "Loading..." : "All"}
                </option>
                {businessClasses?.business_classes?.map((data) => (
                  <option label={data?.name} value={data?.id}>
                    {data?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full flex gap-x-3 items-center">
              <Typography
                variant="h6"
                color="blue-gray"
                className=" font-semibold text-[17px]"
              >
                Subclass
              </Typography>

              <select
                id="subclass"
                // onClick={(e)}
                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                {...register("subclass", { required: true })}
                onChange={handleChangeSubClass}
              >
                <option label="All" value="all">
                  {loadingSubClass ? "Loading..." : "All"}
                </option>
                {data?.company_subclass_covertypes?.map((data) => (
                  <option
                    label={data?.subclass?.name}
                    value={data?.subclass?.id}
                  >
                    {data?.subclass?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {[
                  "no",
                  "Business class",
                  "Subclass",
                  "Covertype",
                  "Description",
                  "",
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((item, index: number) => {
                const isLast = index === paginatedData?.length - 1
                const classes = isLast
                  ? "px-4 py-2  text-left"
                  : "px-4 py-2 border-b-2 border-blue-gray-50 text-left"

                return (
                  <tr
                    key={item?.mainId + index.toString()}
                    className="hover:bg-gray-100 transition-all even:bg-gray-200"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {index + 1}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.class}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.subclass}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.coverName}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.coverDisc}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-row items-center gap-x-2">
                        <Button
                          onClick={() => {
                            localStorage.setItem(
                              "prates",
                              JSON.stringify({
                                id: item?.coverId,
                                busClass: selectedClass?.id,
                                sub_classId: selectedSubClass?.id,
                                busClassName: checkBisClass![0]?.name,
                                cover: item?.coverName,
                                description: item?.coverDisc,
                              })
                            )

                            history.push(
                              "/dashboard/setup/manage-premium-rates"
                            )
                          }}
                          size="sm"
                          color="teal"
                          className="text-xs font-normal capitalize bg-brand-teal"
                        >
                          Manage perils
                        </Button>
                        {/* <IconButton
                              onClick={() => deleteCommission(item?.id)}
                              color="red"
                              size="sm"
                            >
                              <BiTrash />
                            </IconButton> */}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </CardBody>
        {loadingData && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-10 h-10" />
          </div>
        )}

        {(!paginatedData || !businessClasses || paginatedData?.length! === 0) &&
          !loadingData && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="black" variant="h6">
                No data found
              </Typography>
            </div>
          )}
        {/* {paginatedData?.length! === 0 && !loadingData && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="black" variant="h6">
              No data found
            </Typography>
          </div>
        )} */}

        {paginatedData && paginatedData?.length !== 0 && !loadingData && (
          <GeneralPagination
            color="black"
            totalItems={totalItems?.length!}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </div>

      <div className="h-20" />
    </>
  )
}

export default PremiumRatesSetup
