import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { useMutation, useQuery } from "@apollo/client"
import { GET_ALL_DIVISIONS } from "../../../GraphQl/queries"
import {
  CREATE_NEW_DIVISION,
  DELETE_DIVISION,
} from "../../../GraphQl/mutations"
import ConfirmationDialog from "../../../config/confirmationbox"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import { UserType } from "../../../types/user"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
type FormData = {
  name: ""
  description: ""
}

type ResponseType = {
  description: string
  id: string
  name: string
}

const SetupWorkGroupView = () => {
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const PAGE_SIZE = 10

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)
  const {
    data: divisions,
    loading,
    refetch,
  } = useQuery<{
    divisions: ResponseType[]
  }>(GET_ALL_DIVISIONS, {
    variables: {
      id: companyId,
    },
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormData>()

  const [DeleteWorkgroup] = useMutation(DELETE_DIVISION, {
    onCompleted: (data) => {
      toast.success(data?.message ?? "Workgroup deleted successfully")
      refetch()
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error deleting workgroup"),
  })

  const [CreateDivision, { error, loading: RequestLoading }] = useMutation(
    CREATE_NEW_DIVISION,
    {
      onCompleted: (data) => {
        toast.success(
          data?.message ?? "You have successfully added a new division"
        )
        refetch()
        reset()
      },
      onError: (error) =>
        toast.error(error?.message ?? "An error occured. Try again"),
    }
  )

  const onSubmit: SubmitHandler<FormData> = (data) => {
    CreateDivision({
      variables: {
        name: data?.name,
        desc: data.description,
        id: companyId,
      },
    })
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const filterData = (filter: ResponseType) => {
    const isTextMatch =
      filter?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.description?.toLowerCase()?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  //------------------------------
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  useEffect(() => {
    if (searchText) {
      setCurrentPage(1)
    }
  }, [searchText])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalItems = divisions?.divisions?.filter(filterData)
  const paginatedData = totalItems?.slice(startIndex, endIndex)

  // console.log(paginatedData)

  //-------------------------------

  // Filtered and paginated data for the current page
  // const paginatedData = divisions?.divisions
  //   ?.filter(filterData)
  //   .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  // const nextPage = () => {
  //   if (
  //     (activePage - 1) * PAGE_SIZE <
  //     (divisions?.divisions?.filter(filterData).length || 0)
  //   ) {
  //     setActivePage(activePage + 1);
  //   }
  // };

  // const prevPage = () => {
  //   if (activePage > 1) {
  //     setActivePage(activePage - 1);
  //   }
  // };

  const deleteworkgroup = (id: string) => {
    try {
      DeleteWorkgroup({
        variables: {
          id,
        },
      })
    } catch (error: any) {
      toast.error(error?.message ?? "Error deleting Workgroup")
    }
  }

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  return (
    <>
      <CustomHeader title="Create Workgroup/Division" />

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className=" p-3"
          >
            <Typography className="text-xl text-white">
              Create Workgroup/Division
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Name
                    </Typography>
                    <Input
                      value={undefined}
                      crossOrigin="anonymous"
                      size="lg"
                      {...register("name", { required: true })}
                      name="name"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Description
                    </Typography>
                    <Textarea
                      size="lg"
                      {...register("description", { required: true })}
                      name="description"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
                className="flex flex-row items-center font-normal"
              >
                <BiPlus size={20} />
                {RequestLoading || isSubmitting ? "Saving..." : "Save"}
              </Button>
            </div>
          </form>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="text-lg"
            >
              List of All Workgroups
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-center">
                <thead className="">
                  <tr>
                    {["No", "name", "description", ""].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-4 py-2  text-center"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={item?.id + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.description}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <IconButton
                            onClick={() => deleteworkgroup(item?.id)}
                            color="red"
                            size="sm"
                          >
                            <BiTrash />
                          </IconButton>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
            {paginatedData?.length! === 0 && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="white" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {paginatedData && paginatedData?.length !== 0 && !loading && (
              <GeneralPagination
                color="black"
                totalItems={totalItems?.length!}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
            {/* Paginator */}
            {/* {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (divisions?.divisions?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (divisions?.divisions?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  )
}

export default SetupWorkGroupView
