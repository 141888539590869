import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react"
import { BiPlus, BiTrash } from "react-icons/bi"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { SubmitHandler, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { useMutation, useQuery } from "@apollo/client"
import {
  GET_DISCOUNT_RATE,
  GET_DISCOUNT_RATES,
  GETALLCOMMMISSIONS,
} from "../../../GraphQl/queries"
import { ADDCOMMISSION, DELETECOMMISSIONS } from "../../../GraphQl/mutations"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import { DriverModal } from "../../../components/modal/DriverModal"
import UpdateRateAndAdditionalBenefits from "./update"

type FormType = {
  name: string
  description: string
}

type CommissionType = {
  description: string
  id: string
  name: string
  status: string
  created_at: string
}
type DiscountType = {
  id: string
  rate: string
  name: string
  status: string
}
type CompanyDiscount = {
  discount: {
    id: string
    name: string
    description: string
    businessClassByBusinessClass: {
      id: string
      name: string
      __typename: string
    }
    __typename: string
  }
  created_at: string
  id: string
  __typename: string
}

const DiscountRatesSetup = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [rateValue, setRateValue] = useState<number | null>(null)
  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormType>()

  // console.log(selectedId)

  const { data, loading, refetch } = useQuery<{
    company_discounts: CompanyDiscount[]
  }>(GET_DISCOUNT_RATES, {
    variables: {
      company_id: companyId,
    },
  })
  // console.log(companyId)
  // const { data:d, loading:l, refetch:r } = useQuery<{
  //   company_discounts: CompanyDiscount[]
  // }>(GET_DISCOUNT_RATES, {
  //   variables: {
  //     comp: companyId,
  //     discount_id:""
  //   },
  // })

  // console.log(data)

  const [CreateCommission, { loading: RequestLoading }] = useMutation(
    ADDCOMMISSION,
    {
      onCompleted: (data) => {
        toast.success("Commission Successfully added")
        reset()
        refetch()
      },
      onError: (error) =>
        toast.error(error?.message ?? "Error creating commission"),
    }
  )

  const [DeleteCommission] = useMutation(DELETECOMMISSIONS, {
    onCompleted: (data) => {
      toast.success("Commission deleted successfully")
      refetch()
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error deleting commission"),
  })

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const filterData = (filter: CompanyDiscount) => {
    const isTextMatch =
      filter?.discount?.businessClassByBusinessClass?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.discount?.description
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  // Filtered and paginated data for the current page
  //------------------------------
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalItems = data?.company_discounts?.filter(filterData)
  const paginatedData = totalItems?.slice(startIndex, endIndex)

  const onSubmit: SubmitHandler<FormType> = (data) => {
    try {
      CreateCommission({
        variables: {
          name: data?.name,
          desc: data?.description,
        },
      })
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating commission")
    }
  }

  const deleteCommission = (id: string) => {
    try {
      DeleteCommission({
        variables: {
          id,
        },
      })
    } catch (error: any) {
      toast.error(error?.message ?? "Error deleting commission")
    }
  }

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])
  return (
    <>
      <CustomHeader title="Discount" />
      <DriverModal
        title={<p className=" text-gray-800 text-[1.2rem]">Update Rate</p>}
        open={open}
        size={400}
        focus="message"
        onClose={() => setOpen(false)}
        children={
          <UpdateRateAndAdditionalBenefits
            Id={selectedId}
            value={rateValue}
            type="Rate"
            onConfirm={() => refetch()}
            onClose={() => setOpen(false)}
          />
        }
      />

      <div className="w-full ">
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="text-lg"
            >
              List of All Discounts
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "no",
                      "Business Class",
                      "Discount",
                      "Description",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-4 py-2 text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left"

                    return (
                      <tr
                        key={item?.discount?.id + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index + 1}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.discount?.businessClassByBusinessClass
                              ?.name || "N/A"}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.discount?.name || "N/A"}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.discount?.description || "N/A"}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-row items-center gap-x-2">
                            <Button
                              onClick={() => {
                                localStorage.setItem(
                                  "discount",
                                  JSON.stringify({
                                    id: item?.id,
                                    discount_id: item?.discount?.id,
                                    name: item?.discount
                                      ?.businessClassByBusinessClass?.name,
                                    description: item?.discount?.description,
                                  })
                                )
                                history.push(
                                  "/dashboard/setup/manage-discount-rates"
                                )
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-brand-teal"
                            >
                              Manage Discount Rates
                            </Button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-10 h-10" />
              </div>
            )}

            {!paginatedData && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
            {paginatedData?.length! === 0 && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {paginatedData && paginatedData?.length !== 0 && !loading && (
              <GeneralPagination
                color="black"
                totalItems={totalItems?.length!}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  )
}

export default DiscountRatesSetup
