import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react"
import {
  NavLink,
  Link,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom"
// import Logo from "../../assets/logo.png";
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import {
  MdApproval,
  MdAutorenew,
  MdDashboard,
  MdOutlineDiscount,
  MdOutlinePolicy,
  MdPayments,
  MdReport,
  MdAutoFixOff,
  MdSettings,
  MdSpaceDashboard,
  MdWorkspacePremium,
} from "react-icons/md"
import { GiTakeMyMoney } from "react-icons/gi"
import { MdOutlinePhonelinkSetup } from "react-icons/md"
import { MdOutlineSecurity } from "react-icons/md"
import {
  FaClipboardUser,
  FaCoins,
  FaMapLocation,
  FaMoneyBillWave,
  FaRegHandshake,
  FaUserShield,
  FaUserTie,
  FaUsers,
} from "react-icons/fa6"
import { TiPointOfInterestOutline } from "react-icons/ti"
import { GiCoins } from "react-icons/gi"
import {
  BiBuilding,
  BiChevronDown,
  BiChevronRight,
  BiMoneyWithdraw,
} from "react-icons/bi"
import { IoBriefcase, IoSettings } from "react-icons/io5"
import { FaTruckLoading } from "react-icons/fa"
import { AiOutlineTransaction } from "react-icons/ai"
import { RiChatNewFill, RiFundsLine } from "react-icons/ri"
import { TbDeviceMobileCog } from "react-icons/tb"
import { HiOutlineBuildingOffice2 } from "react-icons/hi2"
import {
  GET_ROLE_PERMISSIONS,
  COMPANY_ROLES_PERMISSION,
} from "../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import { UserType } from "../../types/user"
import { DEFAULTPRIMARYCOLOR } from "../../util"
import { MdNoAccounts } from "react-icons/md"
import { GiMoneyStack } from "react-icons/gi"
import MENU_CONFIG from "./MenuConfig"
import { useDispatch } from "react-redux"
import { clearAuthState } from "../../store/authSlice"
import { RootState } from "../../store/store"
import { useSelector } from "react-redux"
import { getColorCode } from "../../colorCodes"

function SidebarContent() {
  const dispatch = useDispatch()
  const [logo, setLogo] = useState<string>("")
  const [colors, setColors] = useState<string[]>()
  const [user, setUser] = useState<UserType | undefined>()
  const [permissions, setPermissions] = useState([])
  const location = useLocation()
  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
  } = useSelector((state: RootState) => state.auth)
  const userData = stateUser

  const { data, loading } = useQuery(GET_ROLE_PERMISSIONS, {
    variables: {
      role_id: roleId,
    },
  })

  // const { data: role_data, loading: role_loading, error } = useQuery(COMPANY_ROLES_PERMISSION, {
  //   variables: { id: localStorage.getItem("role_id") },
  //   skip: localStorage.getItem("usertype") !== "staff",
  // });

  const {
    data: role_data,
    loading: role_loading,
    error,
  } = useQuery(COMPANY_ROLES_PERMISSION, {
    variables: { id: roleId },
    skip: usertype !== "staff",
  })


  // const shouldShowMenuItem = (menuName: any, permissions: any) => {
  //   console.log(permissions)
  //   const dashboardPermissions = permissions.find(
  //     (perm: any) => perm.permission_setup.name === 'Dashboard Menu'
  //   );
  //   console.log(dashboardPermissions)
  //   if (!dashboardPermissions) return false;

  //   return dashboardPermissions.selected_actions.some(
  //     (action: any) => action.name === menuName && action.action_type === 'show_menu'
  //   );
  // };

  // console.log(getColorCode(["Red", "Blue", "Yellow", "Green", "Orange"]))

  const shouldShowMenuItem = (menuName: string, permissions: any) => {
    return permissions.some(
      (action: any) =>
        action.name === menuName && action.action_type === "show_menu"
    )
  }

  useEffect(() => {
    if (role_data) {
      const dashboardPermissions = role_data.company_role_permissions.find(
        (perm: any) => perm.permission_setup.name === "Dashboard Menu"
      )

      console.log(dashboardPermissions)

      if (dashboardPermissions) {
        setPermissions(dashboardPermissions.selected_actions)
      }
    }
  }, [role_data])

  const history = useHistory()
  const [open, setOpen] = useState<string>(
    localStorage.getItem("sidebarOpen") ?? "0"
  )

  const handleOpen = (value: string) => {
    setOpen(open === value ? "0" : value)
    localStorage.setItem("sidebarOpen", open === value ? "0" : value)
  }

  const handleLogout = async () => {
    try {
      dispatch(clearAuthState())
      localStorage.removeItem("authState")
      localStorage.clear()

      // localStorage.clear()
      history?.push("/login")
    } catch (error) {
      toast.error("Failed to log out")
    }
  }
  console.log(stateUser?.colors)
  // console.log(colors?.length)

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)
      const data: UserType = userData
      // console.log(data?.colors)

      setUser(data)
      if (data?.logo_url?.length! > 5) {
        setLogo(data?.logo_url!)
      }
      // const change = data?.colors?.map((arr)=>arr?.toLowerCase()).map((arr)=>arr[0]?.toUpperCase() + arr?.slice(1))
      // console.log(change)
      const colorCodes = getColorCode(data?.colors!)
      console.log(colorCodes)
      setColors(colorCodes)
    }
  }, [userData])

  console.log(stateUser?.colors![0])

  return (
    <Card  style={{
      // background:
      // colors?.length! > 1
      //       ? `${colors![0]}`
      //       : `black`,
      //       color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
      background:
`black`,
            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
   
   

    }} className={`sidebar-container h-full overflow-y-scroll  w-full max-w-[20rem] rounded-none  p-4`}>
      <div className="mb-1 p-4">
        <img src={logo} alt="logo" className="w- object-cover" />
      </div>
      <List>
        {usertype !== "staff" && (
          <>
            <Link to="/dashboard">
              <ListItem
                style={{
                  background:
                    location?.pathname === "/dashboard"
                      ? `${colors?.length! > 1
                        ? `${colors![1]}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : "",
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }}
                className={` text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                }  capitalize`}
              >
                <ListItemPrefix>
                  <MdDashboard />
                </ListItemPrefix>
                Overview
              </ListItem>
            </Link>

            <Link to="/dashboard/client">
              <ListItem
                style={{
                  background:
                    location?.pathname === "/dashboard/client"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : "",
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
               
                }}
                className={` text-sm 
                   hover:text-black capitalize  hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"}`}
              >
                <ListItemPrefix>
                  <FaUserTie />
                </ListItemPrefix>
                Client 360
              </ListItem>
            </Link>

            <Link to="/dashboard/policy">
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard/policy"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : "",
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                   
                }}
                className={`text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                  }  capitalize`}
              >
                <ListItemPrefix>
                  <MdOutlinePolicy />
                </ListItemPrefix>
                Policy 360
              </ListItem>
            </Link>

            <Link to="/dashboard/resolve-policy-creation">
              <ListItem
                style={{
                  background:
                    location?.pathname === "/dashboard/resolve-policy-creation"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : "",
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }}
                className={` p-0 text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                }  py-2.5 ml-2  capitalize`}
              >
                <ListItemPrefix>
                  <MdAutoFixOff strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Resolve Policy Sticker Issues
              </ListItem>
            </Link>

            <Link to="/dashboard/account-balances">
              <ListItem
                style={{
                  background:
                    location?.pathname === "/dashboard/account-balances"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : "",
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }}
                className={` p-0 text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                }  py-2.5 ml-2  capitalize`}
              >
                <ListItemPrefix>
                  <MdAutoFixOff strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Account Balances
              </ListItem>
            </Link>
            
            <List style={{
      // background:
      // colors?.length! > 1
      //       ? `${colors![0]}`
      //       : `black`,
        
      //       color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,

      background:
      `black`,
                  color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,

    }} className={` -mt-1`}>
              <Accordion
                open={open === "8"}
                icon={
                  <BiChevronDown
                    strokeWidth={2.5}
                    className={`${colors?.length! > 2 ? `${colors![2]}` : "white"} mx-auto h-4 w-4 transition-transform ${open === "8" ? "rotate-180" : ""
                      }`}
                   
                  />
                }
              >
                <ListItem
                  style={{
                    background:
                      location?.pathname === "/dashboard/setup"
                        ? `${colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                        }`
                       : "",
                       color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  }}
                  className={` p-0 text-sm hover:${
                    colors?.length! > 1
                      ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                      : "bg-red-400"
                  }   capitalize`}
                  selected={open === "8"}
                >
                  <AccordionHeader
                    onClick={() => handleOpen("8")}
                    className="border-b-0 p-1 py-3 text-sm capitalize"
                    style={{
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                    }}
                  >
                    <ListItemPrefix>
                      <FaRegHandshake strokeWidth={3} className={`h-3 w-5 ${colors?.length! > 2 ? `${colors![2]}` : "white"}`} />
                    </ListItemPrefix>
                    <Typography
                    
                      className="text-sm mr-auto font-normal"
                    >
                      Replenishments
                    </Typography>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody  style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                      
                     
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  background:
                  `black`,
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }} className="py-1  capitalize">
                  <List className="p-0 z-50 ">
                    <Link to="/dashboard/make-Payments">
                      <ListItem
                        style={{
                          background:
                            location?.pathname === "/dashboard/make-Payments"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : "",
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  ${
                          location.pathname === "/dashboard/make-Payments"
                            ? "mt-2 "
                            : "-mt-1"
                          }`}
                      >
                        <ListItemPrefix>
                          <FaCoins />
                        </ListItemPrefix>
                        Replenish or Clear
                      </ListItem>
                    </Link>
                    <Link to="/dashboard/agent-receipts">
                      <ListItem
                        style={{
                          background:
                            location?.pathname === "/dashboard/agent-receipts"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                           color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        } capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <FaMoneyBillWave  />
                        </ListItemPrefix>
                        Replenishment Receipts
                      </ListItem>
                    </Link>
                    {/* <Link to="/dashboard/replenishment-requests">
                      <ListItem
                        style={{
                          background:
                            location?.pathname ===
                            "/dashboard/replenishment-requests"
                              ? `${
                                  colors?.length! > 1
                                    ? `${colors![1]?.toLowerCase()}`
                                    : `${DEFAULTPRIMARYCOLOR}`
                                }`
                             : ""
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        } hover:text-white text-white  ${
                          location.pathname ===
                          "/dashboard/replenishment-requests"
                            ? "mt-2 "
                            : "-mt-1"
                        }`}
                      >
                        <ListItemPrefix>
                          <FaCoins />
                        </ListItemPrefix>
                        Replenishment Requests
                      </ListItem>
                    </Link> */}
                  </List>
                </AccordionBody>
              </Accordion>
            </List>
          </>
        )}

        {usertype !== "staff" &&
          user?.subscribed_business_class?.some(
            (cls: { name: string }) => cls.name === "Invest2Insure"
          ) && (
            <List style={{
              // background:
              // colors?.length! > 1
              //       ? `${colors![0]}`
              //       : `black`,
              //       color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
              background:
              `black`,
                          color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
            }} className="  -mt-3">
              <Accordion
                open={open === "i" || open === "9"}
                icon={
                  <BiChevronDown
                    strokeWidth={2.5}
                    className={`${colors?.length! > 2 ? `${colors![2]}` : "white"} mx-auto h-4 w-4 transition-transform ${open === "i" || open === "9" ? "rotate-180" : ""
                      }`}
                  />
                }
              >
                <ListItem
                  style={{
                    background:
                       location?.pathname === "/dashboard/Invest-2-Insure"
                        ? `${colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                        }`
                       : "",
                       color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  }}
                  className={` p-0 text-sm hover:${
                    colors?.length! > 1
                      ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                      : "bg-red-400"
                  }  capitalize mt-1`}
                  selected={open === "i"}
                >
                  <AccordionHeader
                    onClick={() => handleOpen("i")}
                    style={{
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                    }}
                    className="border-b-0 p-1 py-3 text-sm  capitalize"
                  >
                    <ListItemPrefix>
                      <IoBriefcase className={`${colors?.length! > 2 ? `${colors![2]}` : "white"}`} />
                    </ListItemPrefix>
                    <Typography
                   
                      className="text-sm mr-auto font-normal"
                    >
                      Invest-2-Insure
                    </Typography>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                      
                     
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                     background:
      `black`,
                  color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }} className="py-1  capitalize">
                  <List className="p-0 z-50 ">
                    <Link to="/dashboard/i2i/overview">
                      <ListItem
                        style={{
                          background:
                            location?.pathname === "/dashboard/i2i/overview"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : "",
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize ${
                          location.pathname === "/dashboard/i2i/overview"
                            ? "mt-2 "
                            : "-mt-1"
                          }`}
                      >
                        <ListItemPrefix>
                          <RiChatNewFill />
                        </ListItemPrefix>
                        overview
                      </ListItem>
                    </Link>

                    <Link to="/dashboard/i2i/collections">
                      <ListItem
                        style={{
                          background:
                            location?.pathname === "/dashboard/i2i/collections"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : "",
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <MdSpaceDashboard />
                        </ListItemPrefix>
                        Collections
                      </ListItem>
                    </Link>

                    <Link to="/dashboard/i2i/withdrawals">
                      <ListItem
                        style={{
                          background:
                            location?.pathname === "/dashboard/i2i/withdrawals"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : "",
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <BiMoneyWithdraw />
                        </ListItemPrefix>
                        Withdrawals
                      </ListItem>
                    </Link>

                    <Link to="/dashboard/i2i/manage-drivers">
                      <ListItem
                        style={{
                          background:
                            location?.pathname ===
                              "/dashboard/i2i/manage-drivers"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                           color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <GiTakeMyMoney />
                        </ListItemPrefix>
                        Manage Drivers
                      </ListItem>
                    </Link>

                    <Link to="/dashboard/i2i/invest-2-insure-payments">
                      <ListItem
                        style={{
                          background:
                            location?.pathname ===
                              "/dashboard/i2i/invest-2-insure-payments"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                           color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <BiMoneyWithdraw />
                        </ListItemPrefix>
                        Invest-2-Insure Policies
                      </ListItem>
                    </Link>

                    <List style={{
              // background:
              // colors?.length! > 1
              //       ? `${colors![0]}`
              //       : `black`,
              //       color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
              background:
              `black`,
                          color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
            }} className="  -mt-0">
                      <Accordion
                        open={open === "9"}
                        icon={
                          <BiChevronDown
                            strokeWidth={2.5}
                            className={`${colors?.length! > 2 ? `${colors![2]}` : "white"} mx-auto h-4 w-4 transition-transform ${open === "9" ? "rotate-180" : ""
                              }`}
                          
                          />
                        }
                      >
                        <ListItem
                          style={{
                            background:
                              history?.location?.pathname === "/dashboard/setup"
                                ? `${colors?.length! > 1
                                  ? `${colors![1]?.toLowerCase()}`
                                  : `${DEFAULTPRIMARYCOLOR}`
                                }`
                               : ""
                             ,
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                          }}
                          className={` p-0 text-sm hover:${
                            colors?.length! > 1
                              ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                              : "bg-red-400"
                          }  capitalize`}
                          selected={open === "9"}
                        >
                          <AccordionHeader
                            onClick={() => {
                              handleOpen(open === "i" ? "9" : "i")
                            }}
                            style={{
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                            }}
                            className="border-b-0 p-1 py-3 text-sm  capitalize"
                          >
                            <ListItemPrefix>
                              <FaMoneyBillWave className={`text-${colors?.length! > 2 ? `${colors![2]}` : "white"}`}  />
                            </ListItemPrefix>
                            <Typography
                             
                              className="text-sm mr-auto font-normal"
                            >
                              Financial Transactions
                            </Typography>
                          </AccordionHeader>
                        </ListItem>
                        <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                      
                     
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  background:
                  `black`,
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }} className="py-1 capitalize">
                          <List className="p-0 z-50 ">
                            {/* <Link to={"/dashboard/taxes"}>
                            <ListItem
                              style={{
                                background:
                                  history?.location?.pathname ===
                                  "/dashboard/taxes"
                                    ? `${
                                        colors?.length! > 1
                                          ? `${colors![1]?.toLowerCase()}`
                                          : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                   : ""
                              }}
                              className={` text-sm hover:${
                                colors?.length! > 1
                                  ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                                  : "bg-red-400"
                              } hover:text-white text-white capitalize -mt-1`}
                            >
                              <ListItemPrefix>
                                <FaMoneyBillWave color="white" />
                              </ListItemPrefix>
                              taxes
                            </ListItem>
                          </Link> */}

                            <Link to="/dashboard/i2i/pending_commissions">
                              <ListItem
                                style={{
                                  background:
                                    location.pathname ===
                                      "/dashboard/i2i/pending_commissions"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1].toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={`text-sm hover:${colors?.length! > 1
                                  ? `bg-${colors![1].toLowerCase()}-200`
                                  : "bg-red-400"
                                  }  capitalize ${location.pathname ===
                                    "/dashboard/i2i/pending_commissions"
                                    ? "mt-2 "
                                    : "-mt-1"
                                  }`}
                              >
                                <ListItemPrefix>
                                  <FaCoins />
                                </ListItemPrefix>
                                Pending Commissions
                              </ListItem>
                            </Link>

                            <Link to="/dashboard/i2i/paid-commissions">
                              <ListItem
                                style={{
                                  background:
                                    location.pathname ===
                                      "/dashboard/i2i/paid-commissions"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1].toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                   color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={`text-sm hover:${colors?.length! > 1
                                  ? `bg-${colors![1].toLowerCase()}-200`
                                  : "bg-red-400"
                                  } capitalize -mt-1`}
                              >
                                <ListItemPrefix>
                                  <FaCoins />
                                </ListItemPrefix>
                                Paid Commissions
                              </ListItem>
                            </Link>

                            <Link to="/dashboard/i2i/payment-orders">
                              <ListItem
                                style={{
                                  background:
                                    location.pathname ===
                                      "/dashboard/i2i/payment-orders"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1].toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                   color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={`text-sm hover:${colors?.length! > 1
                                  ? `bg-${colors![1].toLowerCase()}-200`
                                  : "bg-red-400"
                                  }  capitalize -mt-1`}
                              >
                                <ListItemPrefix>
                                  <FaCoins />
                                </ListItemPrefix>
                                Payment Orders
                              </ListItem>
                            </Link>

                            {/* <Link to="/dashboard/i2i/payment-approvals">
                              <ListItem
                                style={{
                                  background:
                                    location.pathname === "/dashboard/i2i/payment-approvals"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1].toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                }}
                                className={`text-sm hover:${colors?.length! > 1
                                  ? `bg-${colors![1].toLowerCase()}-400`
                                  : "bg-red-400"
                                  } hover:text-white text-white capitalize -mt-1`}
                              >
                                <ListItemPrefix>
                                  <FaCoins />
                                </ListItemPrefix>
                                Payment Approvals
                              </ListItem>
                            </Link> */}
                          </List>
                        </AccordionBody>
                      </Accordion>
                    </List>
                    <Link to="/dashboard/i2i/report">
                      <ListItem
                        style={{
                          background:
                            location.pathname === "/dashboard/i2i/report"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                           color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <MdReport />
                        </ListItemPrefix>
                        Report
                      </ListItem>
                    </Link>

                    <Link to="/dashboard/i2i/close-account-requests">
                      <ListItem
                        style={{
                          background:
                            location.pathname ===
                              "/dashboard/i2i/close-account-requests"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <MdNoAccounts />
                        </ListItemPrefix>
                        Close Account Requests
                      </ListItem>
                    </Link>
                  </List>
                </AccordionBody>
              </Accordion>
            </List>
          )}

        {usertype !== "staff" && (
          <>
            <List style={{
    //   background:
    //   colors?.length! > 1
    //         ? `${colors![0]}`
    //         : `black`,
        
    //  color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
    background:
    `black`,
                color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,

    }} className=" -mt-2">
              <Accordion
                open={open === "6"}
                icon={
                  <BiChevronDown
                    strokeWidth={2.5}
                    className={`text-${colors?.length! > 2 ? `${colors![2]}` : "white"} mx-auto h-4 w-4 transition-transform ${open === "6" ? "rotate-180" : ""
                      }`}
                 
                  />
                }
              >
                <ListItem
                  style={{
                    background:
                      history?.location?.pathname === "/dashboard/setup"
                        ? `${colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                        }`
                       : ""
                     ,
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                  }}
                  className={`p-0 text-sm hover:${
                    colors?.length! > 1
                      ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                      : "bg-red-400"
                  }  capitalize`}
                  selected={open === "6"}
                >
                  <AccordionHeader
                    onClick={() => handleOpen("6")}
                    style={{
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                    }}
                    className="border-b-0 p-1 py-3 text-sm capitalize"
                  >
                    <ListItemPrefix>
                      <AiOutlineTransaction className={`text-${colors?.length! > 2 ? `${colors![2]}` : "white"}`} />
                    </ListItemPrefix>
                    <Typography
                     
                      className="text-sm mr-auto font-normal"
                    >
                      Technical Transactions
                    </Typography>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                  background:
                  `black`,
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                    }
                    }
                      className="py-1  capitalize">
                  <List className="p-0 z-50">
                    <Link to={"/dashboard/quotations"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/quotations"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize ${
                          location.pathname === "/dashboard/quotations"
                            ? "mt-2 "
                            : "-mt-1"
                          }`}
                      >
                        <ListItemPrefix>
                          <FaUsers />
                        </ListItemPrefix>
                        Saved Quotations
                      </ListItem>
                    </Link>

                    <Link to={"/dashboard/new-policy"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/new-policy"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <RiChatNewFill />
                        </ListItemPrefix>
                        New Policies
                      </ListItem>
                    </Link>

                    <Link to={"/dashboard/renewals"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/renewals"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <MdAutorenew />
                        </ListItemPrefix>
                        Renewed Policies{" "}
                      </ListItem>
                    </Link>

                    <Link to={"/dashboard/lapsed"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname === "/dashboard/lapsed"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <MdAutorenew />
                        </ListItemPrefix>
                        Lapsed Policies
                      </ListItem>
                    </Link>
                  </List>
                </AccordionBody>
              </Accordion>
            </List>

            <List style={{
      // background:
      // colors?.length! > 1
      //       ? `${colors![0]}`
      //       : `black`,
      //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
      background:
      `black`,
                  color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
   

    }} className="  -mt-5">
              <Accordion
                open={open === "3"}
                icon={
                  <BiChevronDown
                    strokeWidth={2.5}
                    className={`mx-auto h-4 w-4 transition-transform ${open === "3" ? "rotate-180" : ""
                      }`}
                    
                  />
                }
              >
                <ListItem
                  style={{
                    background:
                      history?.location?.pathname === "/dashboard/setup"
                        ? `${colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                        }`
                       : ""
                     ,
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                  }}
                  className={` p-0 text-sm hover:${
                    colors?.length! > 1
                      ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                      : "bg-red-400"
                  }  capitalize`}
                  selected={open === "3"}
                >
                  <AccordionHeader
                    onClick={() => handleOpen("3")}
                    style={{
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                    }}
                    className="border-b-0 p-1 py-3 text-sm  capitalize"
                  >
                    <ListItemPrefix>
                      <FaMoneyBillWave  />
                    </ListItemPrefix>
                    <Typography
                    
                      className="text-sm mr-auto font-normal"
                    >
                      Financial Transaction
                    </Typography>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                      
                     
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  background:
                  `black`,
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }}
                 className="py-1  capitalize">
                  <List className="p-0 z-50 ">
                    <Link to={"/dashboard/taxes"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname === "/dashboard/taxes"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize ${
                          location.pathname === "/dashboard/taxes"
                            ? "mt-2 "
                            : "-mt-1"
                          }`}
                      >
                        <ListItemPrefix>
                          <FaMoneyBillWave />
                        </ListItemPrefix>
                        taxes
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/pending_commissions"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/pending_commissions"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        } capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <FaCoins />
                        </ListItemPrefix>
                        Pending Commissions
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/paid_commissions"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/paid_commissions"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <FaCoins />
                        </ListItemPrefix>
                        paid commissions
                      </ListItem>
                    </Link>
                    {/* <Link to={"/dashboard/requisition"}>
                  <ListItem
                    className={`${
                      history?.location?.pathname ===
                        "/dashboard/requisition" && "bg-red-400"
                    } textsm hover:${colors?.length! > 1 ? `bg-[${colors![1]?.toLowerCase()}-400` : "bg-red-400"} hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <FaCoins />
                    </ListItemPrefix>
                    Payment requisition
                  </ListItem>
                </Link> */}
                    <Link to={"/dashboard/orders"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname === "/dashboard/orders"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <FaCoins />
                        </ListItemPrefix>
                        Payment orders
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/approvals"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/approvals"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <FaCoins />
                        </ListItemPrefix>
                        Payment approvals
                      </ListItem>
                    </Link>
                  </List>
                </AccordionBody>
              </Accordion>
            </List>

            <List style={{
    //   background:
    //   colors?.length! > 1
    //         ? `${colors![0]}`
    //         : `black`,
        
    // color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
    background:
    `black`,
                color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,

    }} className=" -mt-4">
              <Accordion
                open={open === "1" || open === "2" ||open==="11" || open === "2.2"}
                icon={
                  <BiChevronDown
                    strokeWidth={2.5}
                    className={`mx-auto h-4 w-4 transition-transform ${open === "1" || open === "2"||open==="11" || open === "2.2"
                      ? "rotate-180"
                      : ""
                      }`}
                    
                  />
                }
              >
                <ListItem
                  style={{
                    background:
                      history?.location?.pathname === "/dashboard/setup"
                        ? `${colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                        }`
                       : ""
                     ,
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                  }}
                  className={`p-0 text-sm hover:${
                    colors?.length! > 1
                      ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                      : "bg-red-400"
                  }  capitalize`}
                  selected={open === "1" || open === "2" ||open==="11"|| open === "2.2"}
                >
                  <AccordionHeader
                    onClick={() => handleOpen("1")}
                    style={{
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                    }}
                    className="border-b-0 p-1 py-3 text-sm capitalize"
                  >
                    <ListItemPrefix>
                      <MdOutlinePhonelinkSetup  />
                    </ListItemPrefix>
                    <Typography
                   
                      className="text-sm mr-auto font-normal"
                    >
                      System Setup
                    </Typography>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                      
                     
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  background:
                  `black`,
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }} className="py-1  capitalize">
                  <List className="p-0 z-50">
                    <Link to={"/dashboard/setup/branches"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/branches"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize`}
                      >
                        <ListItemPrefix>
                          <BiBuilding />
                        </ListItemPrefix>
                        Branch
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/setup/workgroups"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/workgroups"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }   capitalize`}
                      >
                        <ListItemPrefix>
                          <FaClipboardUser />
                        </ListItemPrefix>
                        Workgroup/Divisions
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/setup/roles"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/roles"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize`}
                      >
                        <ListItemPrefix>
                          <MdSettings />
                        </ListItemPrefix>
                        Roles & Permissions
                      </ListItem>
                    </Link>
                    <List className="  -mt-1">
                      <Accordion
                        open={open === "2"}
                        icon={
                          <BiChevronDown
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === "2" ? "rotate-180" : ""
                              }`}
                         
                          />
                        }
                      >
                        <ListItem
                          style={{
                            background:
                              history?.location?.pathname === "/dashboard/setup"
                                ? `${colors?.length! > 1
                                  ? `${colors![1]?.toLowerCase()}`
                                  : `${DEFAULTPRIMARYCOLOR}`
                                }`
                               : ""
                             ,
                               color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                          }}
                          className={`p-0 text-sm hover:${
                            colors?.length! > 1
                              ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                              : "bg-red-400"
                          }  capitalize`}
                          selected={open === "2"}
                        >
                          <AccordionHeader
                            onClick={() => {
                              handleOpen(open === "1" ? "2" : "1")
                            }}
                            style={{
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                            }}
                            className="border-b-0 p-1 py-3 text-sm  capitalize"
                          >
                            <ListItemPrefix>
                              <TbDeviceMobileCog  />
                            </ListItemPrefix>
                            <Typography
                            
                              className="text-sm mr-auto font-normal"
                            >
                              App Users
                            </Typography>
                          </AccordionHeader>
                        </ListItem>
                        <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                      
                     
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  background:
                  `black`,
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }} className="py-1 capitalize">
                          <List className="p-0 z-50 ">
                            <Link to={"/dashboard/setup/staff"}>
                              <ListItem
                                style={{
                                  background:
                                    history?.location?.pathname ===
                                      "/dashboard/setup/staff" ||
                                      history?.location?.pathname ===
                                      "/dashboard/setup/manage-staff"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1]?.toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={` p-0 text-sm hover:${
                                  colors?.length! > 1
                                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                                    : "bg-red-400"
                                }  py-2.5 px-2  capitalize`}
                              >
                                <ListItemPrefix>
                                  <FaUserShield
                                    strokeWidth={3}
                                    className="h-3 w-5"
                                  />
                                </ListItemPrefix>
                                Staff
                              </ListItem>
                            </Link>

                            <Link to={"/dashboard/setup/agent"}>
                              <ListItem
                                style={{
                                  background:
                                    history?.location?.pathname ===
                                      "/dashboard/setup/agent" ||  history?.location?.pathname ===
                                      "/dashboard/setup/manage-agent"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1]?.toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={`p-0 text-sm hover:${
                                  colors?.length! > 1
                                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                                    : "bg-red-400"
                                }  py-2.5 px-2  capitalize`}
                              >
                                <ListItemPrefix>
                                  <HiOutlineBuildingOffice2
                                    strokeWidth={3}
                                    className="h-3 w-5"
                                  />
                                </ListItemPrefix>
                                Agents
                              </ListItem>
                            </Link>
                          </List>
                        </AccordionBody>
                      </Accordion>
                    </List>
                    <List className="  -mt-1">
                      <Accordion
                        open={open === "11"}
                        icon={
                          <BiChevronDown
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === "11" ? "rotate-180" : ""
                              }`}
                         
                          />
                        }
                      >
                        <ListItem
                          style={{
                            background:
                              history?.location?.pathname === "/dashboard/setup"
                                ? `${colors?.length! > 1
                                  ? `${colors![1]?.toLowerCase()}`
                                  : `${DEFAULTPRIMARYCOLOR}`
                                }`
                               : ""
                             ,
                               color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                          }}
                          className={`p-0 text-sm hover:${
                            colors?.length! > 1
                              ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                              : "bg-red-400"
                          }  capitalize`}
                          selected={open === "11"}
                        >
                          <AccordionHeader
                            onClick={() => {
                              handleOpen(open === "1" ? "11" : "1")
                            }}
                            style={{
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                            }}
                            className="border-b-0 p-1 py-3 text-sm  capitalize"
                          >
                            <ListItemPrefix>
                           
                            <TiPointOfInterestOutline     />
                            </ListItemPrefix>
                            <Typography
                            
                              className="text-sm mr-auto font-normal"
                            >
                          Policy Rates Setup
                            </Typography>
                          </AccordionHeader>
                        </ListItem>
                        <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                      
                     
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  background:
                  `black`,
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }} className="py-1 capitalize">
                          <List className="p-0 z-50 ">
                          <Link to={"/dashboard/setup/premium-rates-setup"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                            "/dashboard/setup/premium-rates-setup" ||  history?.location?.pathname ===
                            "/dashboard/setup/manage-premium-rates" ||  history?.location?.pathname === "/dashboard/setup/manage-perils"
                              ? `${
                                  colors?.length! > 1
                                    ? `${colors![1]?.toLowerCase()}`
                                    : `${DEFAULTPRIMARYCOLOR}`
                                }`
                             : "",
                               color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`p-0 text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }   py-2.5 px-2 capitalize -mt-0`}
                      >
                        <ListItemPrefix>
                          <TiPointOfInterestOutline
                            strokeWidth={2}
                            className="h-3 w-5"
                          />
                        </ListItemPrefix>
                        Premium Rates Setup
                      </ListItem>
                    </Link>
                            <Link to={"/dashboard/setup/discount-rates-setup"}>
                              <ListItem
                                style={{
                                  background:
                                    history?.location?.pathname ===
                                      "/dashboard/setup/discount-rates-setup" ||
                                      history?.location?.pathname ===
                                      "/dashboard/setup/manage-discount-rates"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1]?.toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={` p-0 text-sm hover:${
                                  colors?.length! > 1
                                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                                    : "bg-red-400"
                                }  py-2.5 px-2  capitalize`}
                              >
                                <ListItemPrefix>
                                <TiPointOfInterestOutline
                            strokeWidth={2}
                            className="h-3 w-5"
                          />
                                </ListItemPrefix>
                                Discount Rate Setup
                              </ListItem>
                            </Link>

                            <Link to={"/dashboard/setup/additional-rates-setup"}>
                              <ListItem
                                style={{
                                  background:
                                    history?.location?.pathname ===
                                      "/dashboard/setup/additional-rates-setup" ||  history?.location?.pathname ==='/dashboard/setup/manage-additional-rates'
                                      ? `${colors?.length! > 1
                                        ? `${colors![1]?.toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={`p-0 text-sm hover:${
                                  colors?.length! > 1
                                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                                    : "bg-red-400"
                                }  py-2.5 px-2  capitalize`}
                              >
                                <ListItemPrefix>
                                <TiPointOfInterestOutline
                            strokeWidth={2}
                            className="h-3 w-5"
                          />
                                </ListItemPrefix>
                                Addtional benefits Setup
                              </ListItem>
                            </Link>
                            <Link to={"/dashboard/setup/policy-loadings"}>
                              <ListItem
                                style={{
                                  background:
                                    history?.location?.pathname ===
                                      "/dashboard/setup/policy-loadings" ||  history?.location?.pathname ==='/dashboard/setup/manage-policy-loadings'
                                      ? `${colors?.length! > 1
                                        ? `${colors![1]?.toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={`p-0 text-sm hover:${
                                  colors?.length! > 1
                                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                                    : "bg-red-400"
                                }  py-2.5 px-2  capitalize`}
                              >
                                <ListItemPrefix>
                                <TiPointOfInterestOutline
                            strokeWidth={2}
                            className="h-3 w-5"
                          />
                                </ListItemPrefix>
                                Loadings
                              </ListItem>
                            </Link>
                          </List>
                        </AccordionBody>
                      </Accordion>
                    </List>

                    <Link to={"/dashboard/setup/commissions"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/commissions" ||   history?.location?.pathname === "/dashboard/setup/manage-commissions"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`p-0 text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  py-2.5 px-2capitalize -mt-2`}
                      >
                        <ListItemPrefix>
                          <TiPointOfInterestOutline
                            strokeWidth={3}
                            className="h-3 w-5"
                          />
                        </ListItemPrefix>
                        Commissions Setup
                      </ListItem>
                    </Link>
                    {/* <Link to={"/dashboard/setup/premium-rates-setup"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                            "/dashboard/setup/premium-rates-setup"
                              ? `${
                                  colors?.length! > 1
                                    ? `${colors![1]?.toLowerCase()}`
                                    : `${DEFAULTPRIMARYCOLOR}`
                                }`
                             : "",
                               color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`p-0 text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }   py-2.5 px-2 capitalize -mt-2`}
                      >
                        <ListItemPrefix>
                          <TiPointOfInterestOutline
                            strokeWidth={3}
                            className="h-3 w-5"
                          />
                        </ListItemPrefix>
                        Premium Rates Setup
                      </ListItem>
                    </Link> */}
                    <Link to={"/dashboard/setup/tax"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/tax" ||  history?.location?.pathname ===
                              "/dashboard/setup/manage-tax"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`p-0 text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  py-2.5 px-2  capitalize`}
                      >
                        <ListItemPrefix>
                          <TiPointOfInterestOutline
                            strokeWidth={3}
                            className="h-3 w-5"
                          />
                        </ListItemPrefix>
                        Tax Setup
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/setup/funding"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/funding"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`p-0 text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  py-2.5 px-2  capitalize`}
                      >
                        <ListItemPrefix>
                          <RiFundsLine strokeWidth={3} className="h-3 w-5" />
                        </ListItemPrefix>
                        Account funding
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/funding-approval"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/funding-approval"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` p-0 text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  py-2.5 px-2 capitalize`}
                      >
                        <ListItemPrefix>
                          <MdApproval strokeWidth={3} className="h-3 w-5" />
                        </ListItemPrefix>
                        funding approval
                      </ListItem>
                    </Link>
                  </List>
                </AccordionBody>
              </Accordion>
            </List>

            <Link to={"/dashboard/reports"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard/reports"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : ""
                   ,
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                }}
                className={` text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                } capitalize -mt-1`}
              >
                <ListItemPrefix>
                  <MdReport  />
                </ListItemPrefix>
                report
              </ListItem>
            </Link>

            <Link to={"/dashboard/settings"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard/settings"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : ""
                   ,
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                }}
                className={`text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                } e capitalize -mt-1`}
              >
                <ListItemPrefix>
                  <IoSettings  />
                </ListItemPrefix>
                settings
              </ListItem>
            </Link>
          </>
        )}

        {usertype === "staff" &&
          shouldShowMenuItem("Overview", permissions) && (
            <Link to={"/dashboard"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : ""
                   ,
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                }}
                className={` text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                }  capitalize`}
              >
                <ListItemPrefix>
                  <MdDashboard />
                </ListItemPrefix>
                Overview
              </ListItem>
            </Link>
          )}

        {usertype === "staff" &&
          shouldShowMenuItem("Client 360", permissions) && (
            <Link to={"/dashboard/client"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard/client"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : ""
                   ,
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                }}
                className={` text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                }  capitalize `}
              >
                <ListItemPrefix>
                  <FaUserTie />
                </ListItemPrefix>
                Client 360
              </ListItem>
            </Link>
          )}
        {usertype === "staff" &&
          shouldShowMenuItem("Policy 360", permissions) && (
            <Link to={"/dashboard/policy"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard/policy"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : ""
                   ,
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                }}
                className={` text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                  }  capitalize`}
              >
                <ListItemPrefix>
                  <MdOutlinePolicy />
                </ListItemPrefix>
                Policy 360
              </ListItem>
            </Link>
          )}
        {usertype === "staff" &&
          shouldShowMenuItem("Account Balances", permissions) && (
            <Link to={"/dashboard/account-balances"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname ===
                      "/dashboard/account-balances"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : ""
                   ,
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                }}
                className={` p-0 text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                }  py-2.5 ml-2  capitalize`}
              >
                <ListItemPrefix>
                  <MdAutoFixOff strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Account Balances
              </ListItem>
            </Link>
          )}
          {usertype === "staff" &&
          shouldShowMenuItem("Resolve Sticker", permissions) && (
            <Link to={"/dashboard/resolve-policy-creation"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname ===
                      "/dashboard/resolve-policy-creation"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : ""
                   ,
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                }}
                className={` p-0 text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                }  py-2.5 ml-2  capitalize`}
              >
                <ListItemPrefix>
                  <MdAutoFixOff strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Resolve Policy Sticker Issues
              </ListItem>
            </Link>
          )}


        {usertype && shouldShowMenuItem("Replenishment", permissions) && (
          <List className=" -mt-1">
            <Accordion
              open={open === "8"}
              icon={
                <BiChevronDown
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${open === "8" ? "rotate-180" : ""
                    }`}
              
                />
              }
            >
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard/setup"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : ""
                   ,
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                }}
                className={` p-0 text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                }  capitalize`}
                selected={open === "8"}
              >
                <AccordionHeader
                  onClick={() => handleOpen("8")}
                  style={{
                    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  }}
                  className="border-b-0 p-1 py-3 text-sm capitalize"
                >
                  <ListItemPrefix>
                    <FaRegHandshake strokeWidth={3} className="h-3 w-5" />
                  </ListItemPrefix>
                  <Typography
                    color="white"
                    className="text-sm mr-auto font-normal"
                  >
                    Replenishments
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                      
                     
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  background:
                  `black`,
                  color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }} className="py-1  capitalize">
                <List className="p-0 z-50 ">
                  <Link to={"/dashboard/make-payments"}>
                    <ListItem
                      style={{
                        background:
                          history?.location?.pathname ===
                            "/dashboard/make-payments"
                            ? `${colors?.length! > 1
                              ? `${colors![1]?.toLowerCase()}`
                              : `${DEFAULTPRIMARYCOLOR}`
                            }`
                           : ""
                         ,
                         color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                      }}
                      className={`text-sm hover:${
                        colors?.length! > 1
                          ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                          : "bg-red-400"
                      }   -mt-1`}
                    >
                      <ListItemPrefix>
                        <FaCoins />
                      </ListItemPrefix>
                      Replenish or Clear
                    </ListItem>
                  </Link>
                  <Link to={"/dashboard/agent-receipts"}>
                    <ListItem
                      style={{
                        background:
                          history?.location?.pathname ===
                            "/dashboard/agent-receipts"
                            ? `${colors?.length! > 1
                              ? `${colors![1]?.toLowerCase()}`
                              : `${DEFAULTPRIMARYCOLOR}`
                            }`
                           : ""
                         ,
                         color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                      }}
                      className={` text-sm hover:${
                        colors?.length! > 1
                          ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                          : "bg-red-400"
                      }  capitalize -mt-1`}
                    >
                      <ListItemPrefix>
                        <FaMoneyBillWave  />
                      </ListItemPrefix>
                      Replenishment Receipts
                    </ListItem>
                  </Link>
                </List>
              </AccordionBody>
            </Accordion>
          </List>
        )}
        {usertype === "staff" &&
          shouldShowMenuItem("Invest-2-Insure", permissions) &&
          user?.subscribed_business_class?.some(
            (cls: { name: string }) => cls.name === "Invest2Insure"
          ) && (
            <List style={{
              // background:
              // colors?.length! > 1
              //       ? `${colors![0]}`
              //       : `black`,
                
              //       color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
              background:
              `black`,
                          color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
        
            }} className=" -mt-3">
              <Accordion
                open={open === "i" || open === "9"}
                icon={
                  <BiChevronDown
                    strokeWidth={2.5}
                    className={`mx-auto h-4 w-4 transition-transform ${open === "i" || open === "9" ? "rotate-180" : ""
                      }`}
                   
                  />
                }
              >
                <ListItem
                  style={{
                    background:
                      history?.location?.pathname ===
                        "/dashboard/Invest-2-Insure"
                        ? `${colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                        }`
                       : ""
                     ,
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  }}
                  className={` p-0 text-sm hover:${
                    colors?.length! > 1
                      ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                      : "bg-red-400"
                  }  capitalize mt-1`}
                  selected={open === "i"}
                >
                  <AccordionHeader
                    onClick={() => handleOpen("i")}
                    style={{
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                    }}
                    className="border-b-0 p-1 py-3 text-sm capitalize"
                  >
                    <ListItemPrefix>
                      <IoBriefcase  />
                    </ListItemPrefix>
                    <Typography
                    
                      className="text-sm mr-auto font-normal"
                    >
                      Invest-2-Insure
                    </Typography>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody className="py-1capitalize">
                  <List className="p-0 z-50 ">
                  <Link to="/dashboard/i2i/overview">
                      <ListItem
                        style={{
                          background:
                            location?.pathname === "/dashboard/i2i/overview"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : "",
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize ${
                          location.pathname === "/dashboard/i2i/overview"
                            ? "mt-2 "
                            : "-mt-1"
                          }`}
                      >
                        <ListItemPrefix>
                          <RiChatNewFill />
                        </ListItemPrefix>
                        overview
                      </ListItem>
                    </Link>
                    <Link to="/dashboard/i2i/collections">
                      <ListItem
                        style={{
                          background:
                            location?.pathname === "/dashboard/i2i/collections"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : "",
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <MdSpaceDashboard />
                        </ListItemPrefix>
                        Collections
                      </ListItem>
                    </Link>
                    <Link to="/dashboard/i2i/withdrawals">
                      <ListItem
                        style={{
                          background:
                            location?.pathname === "/dashboard/i2i/withdrawals"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : "",
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <BiMoneyWithdraw />
                        </ListItemPrefix>
                        Withdrawals
                      </ListItem>
                    </Link>
                    <Link to="/dashboard/i2i/manage-drivers">
                      <ListItem
                        style={{
                          background:
                            location?.pathname ===
                              "/dashboard/i2i/manage-drivers"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                           color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <GiTakeMyMoney />
                        </ListItemPrefix>
                        Manage Drivers
                      </ListItem>
                    </Link>

                    <Link to="/dashboard/i2i/invest-2-insure-payments">
                      <ListItem
                        style={{
                          background:
                            location?.pathname ===
                              "/dashboard/i2i/invest-2-insure-payments"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                           color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <BiMoneyWithdraw />
                        </ListItemPrefix>
                        Invest-2-Insure Policies
                      </ListItem>
                    </Link>
                    <List style={{
              // background:
              // colors?.length! > 1
              //       ? `${colors![0]}`
              //       : `black`,
              //       color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
              background:
              `black`,
                          color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
            }} className=" -mt-0">
                      <Accordion
                        open={open === "9"}
                        icon={
                          <BiChevronDown
                          strokeWidth={2.5}
                          className={`${colors?.length! > 2 ? `${colors![2]}` : "white"} mx-auto h-4 w-4 transition-transform ${open === "9" ? "rotate-180" : ""
                            }`}
                          />
                        }
                      >
                        <ListItem
                               style={{
                                background:
                                  history?.location?.pathname === "/dashboard/setup"
                                    ? `${colors?.length! > 1
                                      ? `${colors![1]?.toLowerCase()}`
                                      : `${DEFAULTPRIMARYCOLOR}`
                                    }`
                                   : ""
                                 ,
                                  color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                              }}
                              className={` p-0 text-sm hover:${
                                colors?.length! > 1
                                  ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                                  : "bg-red-400"
                              }  capitalize`}
                              selected={open === "9"}
                        >
                          <AccordionHeader
                               onClick={() => {
                                handleOpen(open === "i" ? "9" : "i")
                              }}
                              style={{
                                color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                              }}
                              className="border-b-0 p-1 py-3 text-sm  capitalize"
                          >
                            <ListItemPrefix>
                            <FaMoneyBillWave className={`text-${colors?.length! > 2 ? `${colors![2]}` : "white"}`}  />
                            </ListItemPrefix>
                            <Typography
                             
                              className="text-sm mr-auto font-normal"
                            >
                              Financial Transactions
                            </Typography>
                          </AccordionHeader>
                        </ListItem>
                        <AccordionBody style={{
                  background:
                
                      colors?.length! > 1
                        ? `${colors![0]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`,
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }} className="py-1 capitalize">
                          <List className="p-0 z-50 ">
                            {/* <Link to={"/dashboard/taxes"}>
                            <ListItem
                              style={{
                                background:
                                  history?.location?.pathname ===
                                  "/dashboard/taxes"
                                    ? `${
                                        colors?.length! > 1
                                          ? `${colors![1]?.toLowerCase()}`
                                          : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                   : ""
                              }}
                              className={` text-sm hover:${
                                colors?.length! > 1
                                  ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                                  : "bg-red-400"
                              } hover:text-white text-white capitalize -mt-1`}
                            >
                              <ListItemPrefix>
                                <FaMoneyBillWave color="white" />
                              </ListItemPrefix>
                              taxes
                            </ListItem>
                          </Link> */}
                            <Link to="/dashboard/i2i/pending_commissions">
                              <ListItem
                                style={{
                                  background:
                                    location.pathname ===
                                      "/dashboard/i2i/pending_commissions"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1].toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={`text-sm hover:${colors?.length! > 1
                                  ? `bg-${colors![1].toLowerCase()}-200`
                                  : "bg-red-400"
                                  }  capitalize ${location.pathname ===
                                    "/dashboard/i2i/pending_commissions"
                                    ? "mt-2 "
                                    : "-mt-1"
                                  }`}
                              >
                                <ListItemPrefix>
                                  <FaCoins />
                                </ListItemPrefix>
                                Pending Commissions
                              </ListItem>
                            </Link>
                            <Link to="/dashboard/i2i/paid-commissions">
                              <ListItem
                                style={{
                                  background:
                                    location.pathname ===
                                      "/dashboard/i2i/paid-commissions"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1].toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                   color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={`text-sm hover:${colors?.length! > 1
                                  ? `bg-${colors![1].toLowerCase()}-200`
                                  : "bg-red-400"
                                  } capitalize -mt-1`}
                              >
                                <ListItemPrefix>
                                  <FaCoins />
                                </ListItemPrefix>
                                Paid Commissions
                              </ListItem>
                            </Link>
                            {/* <Link to={"/dashboard/requisition"}>
                  <ListItem
                    className={`${
                      history?.location?.pathname ===
                        "/dashboard/requisition" && "bg-red-400"
                    } textsm hover:${colors?.length! > 1 ? `bg-[${colors![1]?.toLowerCase()}-400` : "bg-red-400"} hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <FaCoins />
                    </ListItemPrefix>
                    Payment requisition
                  </ListItem>
                </Link> */}
                          <Link to="/dashboard/i2i/payment-orders">
                              <ListItem
                                style={{
                                  background:
                                    location.pathname ===
                                      "/dashboard/i2i/payment-orders"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1].toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                   color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={`text-sm hover:${colors?.length! > 1
                                  ? `bg-${colors![1].toLowerCase()}-200`
                                  : "bg-red-400"
                                  }  capitalize -mt-1`}
                              >
                                <ListItemPrefix>
                                  <FaCoins />
                                </ListItemPrefix>
                                Payment Orders
                              </ListItem>
                            </Link>
                            <Link to={"/dashboard/i2i/payment-approvals"}>
                              <ListItem
                                style={{
                                  background:
                                    history?.location?.pathname ===
                                      "/dashboard/i2i/payment-approvals"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1]?.toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={` text-sm hover:${
                                  colors?.length! > 1
                                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                                    : "bg-red-400"
                                }  capitalize -mt-1`}
                              >
                                <ListItemPrefix>
                                  <FaCoins />
                                </ListItemPrefix>
                                Payment approvals
                              </ListItem>
                            </Link>
                          </List>
                        </AccordionBody>
                      </Accordion>
                    </List>
                    <Link to="/dashboard/i2i/report">
                      <ListItem
                        style={{
                          background:
                            location.pathname === "/dashboard/i2i/report"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                           color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <MdReport />
                        </ListItemPrefix>
                        Report
                      </ListItem>
                    </Link>

                    <Link to="/dashboard/i2i/close-account-requests">
                      <ListItem
                        style={{
                          background:
                            location.pathname ===
                              "/dashboard/i2i/close-account-requests"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <MdNoAccounts />
                        </ListItemPrefix>
                        Close Account Requests
                      </ListItem>
                    </Link>
                  </List>
                </AccordionBody>
              </Accordion>
            </List>
          )}
        {usertype === "staff" &&
          shouldShowMenuItem("Technical Transactions", permissions) && (
            <List style={{
            //   background:
            //   colors?.length! > 1
            //         ? `${colors![0]}`
            //         : `black`,
                
            //  color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
            background:
            `black`,
                        color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
        
            }} className=" -mt-2">
              <Accordion
                open={open === "6"}
                icon={
                  <BiChevronDown
                    strokeWidth={2.5}
                    className={`text-${colors?.length! > 2 ? `${colors![2]}` : "white"} mx-auto h-4 w-4 transition-transform ${open === "6" ? "rotate-180" : ""
                      }`}
                    color="white"
                  />
                }
              >
                <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard/setup"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : ""
                   ,
                    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                }}
                className={`p-0 text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                }  capitalize`}
                selected={open === "6"}
                >
                  <AccordionHeader
                    onClick={() => handleOpen("6")}
                    style={{
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                    }}
                    className="border-b-0 p-1 py-3 text-sm capitalize"
                  >
                    <ListItemPrefix>
                      <AiOutlineTransaction className={`text-${colors?.length! > 2 ? `${colors![2]}` : "white"}`}   />
                    </ListItemPrefix>
                    <Typography
                    
                      className="text-sm mr-auto font-normal"
                    >
                      Technical Transactions
                    </Typography>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                      
                     
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                  background:
                  `black`,
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                     }} className="py-1 capitalize">
                  <List className="p-0 z-50 ">
                  <Link to={"/dashboard/quotations"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/quotations"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize ${
                          location.pathname === "/dashboard/quotations"
                            ? "mt-2 "
                            : "-mt-1"
                          }`}
                      >
                        <ListItemPrefix>
                          <FaUsers />
                        </ListItemPrefix>
                        Saved Quotations
                      </ListItem>
                    </Link>

                    <Link to={"/dashboard/new-policy"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/new-policy"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <RiChatNewFill />
                        </ListItemPrefix>
                        New Policies
                      </ListItem>
                    </Link>

                    <Link to={"/dashboard/renewals"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/renewals"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <MdAutorenew />
                        </ListItemPrefix>
                        Renewed Policies{" "}
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/lapsed"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname === "/dashboard/lapsed"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <MdAutorenew />
                        </ListItemPrefix>
                        Lapsed Policies
                      </ListItem>
                    </Link>
                  </List>
                </AccordionBody>
              </Accordion>
            </List>
          )}
        {usertype === "staff" &&
          shouldShowMenuItem("Financial Details", permissions) && (
            <List style={{
              // background:
              // colors?.length! > 1
              //       ? `${colors![0]}`
              //       : `black`,
              //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
              background:
              `black`,
              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
           
        
            }} className=" -mt-5">
              <Accordion
                open={open === "3"}
                icon={
                  <BiChevronDown
                    strokeWidth={2.5}
                    className={`mx-auto h-4 w-4 transition-transform ${open === "3" ? "rotate-180" : ""
                      }`}
                    color="white"
                  />
                }
              >
                <ListItem
                   style={{
                    background:
                      history?.location?.pathname === "/dashboard/setup"
                        ? `${colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                        }`
                       : ""
                     ,
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                  }}
                  className={` p-0 text-sm hover:${
                    colors?.length! > 1
                      ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                      : "bg-red-400"
                  }  capitalize`}
                  selected={open === "3"}
                >
                  <AccordionHeader
                    onClick={() => handleOpen("3")}
                    style={{
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                    }}
                    className="border-b-0 p-1 py-3 text-sm  capitalize"
                  >
                    <ListItemPrefix>
                      <FaMoneyBillWave  />
                    </ListItemPrefix>
                    <Typography
                    
                      className="text-sm mr-auto font-normal"
                    >
                      Financial Transaction
                    </Typography>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                      
                     
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  background:
                  `black`,
                  color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }} className="py-1  capitalize">
                  <List className="p-0 z-50 ">
                  <Link to={"/dashboard/taxes"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname === "/dashboard/taxes"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize ${
                          location.pathname === "/dashboard/taxes"
                            ? "mt-2 "
                            : "-mt-1"
                          }`}
                      >
                        <ListItemPrefix>
                          <FaMoneyBillWave />
                        </ListItemPrefix>
                        taxes
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/pending_commissions"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/pending_commissions"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        } capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <FaCoins />
                        </ListItemPrefix>
                        Pending Commissions
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/paid_commissions"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/paid_commissions"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <FaCoins />
                        </ListItemPrefix>
                        paid commissions
                      </ListItem>
                    </Link>
                    {/* <Link to={"/dashboard/requisition"}>
               <ListItem
                 className={`${
                   history?.location?.pathname ===
                     "/dashboard/requisition" && "bg-red-400"
                 } textsm hover:${colors?.length! > 1 ? `bg-[${colors![1]?.toLowerCase()}-400` : "bg-red-400"} hover:text-white text-white capitalize -mt-1`}
               >
                 <ListItemPrefix>
                   <FaCoins />
                 </ListItemPrefix>
                 Payment requisition
               </ListItem>
             </Link> */}
                    <Link to={"/dashboard/orders"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname === "/dashboard/orders"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <FaCoins />
                        </ListItemPrefix>
                        Payment orders
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/approvals"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/approvals"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                            color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize -mt-1`}
                      >
                        <ListItemPrefix>
                          <FaCoins />
                        </ListItemPrefix>
                        Payment approvals
                      </ListItem>
                    </Link>
                  </List>
                </AccordionBody>
              </Accordion>
            </List>
          )}
        {usertype === "staff" &&
          shouldShowMenuItem("System Setup", permissions) && (
            
            <List style={{
            //   background:
            //   colors?.length! > 1
            //         ? `${colors![0]}`
            //         : `black`,
                
            // color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
            background:
            `black`,
                        color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
        
            }} className=" -mt-4">
              <Accordion
                open={open === "1" || open === "2" || open === "2.2"}
                icon={
                  <BiChevronDown
                    strokeWidth={2.5}
                    className={`mx-auto h-4 w-4 transition-transform ${open === "1" || open === "2" || open === "2.2"
                      ? "rotate-180"
                      : ""
                      }`}
             
                  />
                }
              >
                <ListItem
                  style={{
                    background:
                      history?.location?.pathname === "/dashboard/setup"
                        ? `${colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                        }`
                       : ""
                     ,
                      color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                  }}
                  className={`p-0 text-sm hover:${
                    colors?.length! > 1
                      ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                      : "bg-red-400"
                  }  capitalize`}
                  selected={open === "1" || open === "2" || open === "2.2"}
                >
                  <AccordionHeader
                     onClick={() => handleOpen("1")}
                     style={{
                       color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                     }}
                     className="border-b-0 p-1 py-3 text-sm capitalize"
                  >
                    <ListItemPrefix>
                      <MdOutlinePhonelinkSetup  />
                    </ListItemPrefix>
                    <Typography
                     
                      className="text-sm mr-auto font-normal"
                    >
                      System Setup
                    </Typography>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                      
                     
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  background:
                  `black`,
                  color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }} className="py-1  capitalize">
                  <List className="p-0 z-50 ">
                  <Link to={"/dashboard/setup/branches"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/branches"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize`}
                      >
                        <ListItemPrefix>
                          <BiBuilding />
                        </ListItemPrefix>
                        Branch
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/setup/workgroups"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/workgroups"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }   capitalize`}
                      >
                        <ListItemPrefix>
                          <FaClipboardUser />
                        </ListItemPrefix>
                        Workgroup/Divisions
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/setup/roles"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/roles"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  capitalize`}
                      >
                        <ListItemPrefix>
                          <MdSettings />
                        </ListItemPrefix>
                        Roles & Permissions
                      </ListItem>
                    </Link>
                    <List className=" -mt-1">
                      <Accordion
                        open={open === "2"}
                        icon={
                          <BiChevronDown
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === "2" ? "rotate-180" : ""
                              }`}
                         
                          />
                        }
                      >
                        <ListItem
                          style={{
                            background:
                              history?.location?.pathname === "/dashboard/setup"
                                ? `${colors?.length! > 1
                                  ? `${colors![1]?.toLowerCase()}`
                                  : `${DEFAULTPRIMARYCOLOR}`
                                }`
                               : ""
                             ,
                               color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                          }}
                          className={`p-0 text-sm hover:${
                            colors?.length! > 1
                              ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                              : "bg-red-400"
                          }  capitalize`}
                          selected={open === "2"}
                        >
                          <AccordionHeader
                            onClick={() => {
                              handleOpen(open === "1" ? "2" : "1")
                            }}
                            style={{
                              color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                            }}
                            className="border-b-0 p-1 py-3 text-sm  capitalize"
                          >
                            <ListItemPrefix>
                              <TbDeviceMobileCog  />
                            </ListItemPrefix>
                            <Typography
                          
                              className="text-sm mr-auto font-normal"
                            >
                              App Users
                            </Typography>
                          </AccordionHeader>
                        </ListItem>
                        <AccordionBody style={{
                  // background:
                
                  //     colors?.length! > 1
                  //       ? `${colors![0]?.toLowerCase()}`
                  //       : `${DEFAULTPRIMARYCOLOR}`,
                  //    color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                  background:
                  `black`,
                  color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`,
                }}   className="py-1 capitalize">
                          <List className="p-0 z-50 ">
                          <Link to={"/dashboard/setup/staff"}>
                              <ListItem
                                style={{
                                  background:
                                    history?.location?.pathname ===
                                      "/dashboard/setup/staff" ||
                                      history?.location?.pathname ===
                                      "/dashboard/setup/manage-staff"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1]?.toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={` p-0 text-sm hover:${
                                  colors?.length! > 1
                                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                                    : "bg-red-400"
                                }  py-2.5 px-2  capitalize`}
                              >
                                <ListItemPrefix>
                                  <FaUserShield
                                    strokeWidth={3}
                                    className="h-3 w-5"
                                  />
                                </ListItemPrefix>
                                Staff
                              </ListItem>
                            </Link>

                            <Link to={"/dashboard/setup/agent"}>
                              <ListItem
                                style={{
                                  background:
                                    history?.location?.pathname ===
                                      "/dashboard/setup/agent"
                                      ? `${colors?.length! > 1
                                        ? `${colors![1]?.toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                      }`
                                     : ""
                                   ,
                                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                                }}
                                className={`p-0 text-sm hover:${
                                  colors?.length! > 1
                                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                                    : "bg-red-400"
                                }  py-2.5 px-2  capitalize`}
                              >
                                <ListItemPrefix>
                                  <HiOutlineBuildingOffice2
                                    strokeWidth={3}
                                    className="h-3 w-5"
                                  />
                                </ListItemPrefix>
                                Agents
                              </ListItem>
                            </Link>
                          </List>
                        </AccordionBody>
                      </Accordion>
                    </List>

                    <Link to={"/dashboard/setup/commissions"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/commissions"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`p-0 text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  py-2.5 px-2capitalize -mt-2`}
                      >
                        <ListItemPrefix>
                          <TiPointOfInterestOutline
                            strokeWidth={3}
                            className="h-3 w-5"
                          />
                        </ListItemPrefix>
                        Commissions Setup
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/setup/premium-rates-setup"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                            "/dashboard/setup/premium-rates-setup"
                              ? `${
                                  colors?.length! > 1
                                    ? `${colors![1]?.toLowerCase()}`
                                    : `${DEFAULTPRIMARYCOLOR}`
                                }`
                             : "",
                               color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`p-0 text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }   py-2.5 px-2 capitalize -mt-2`}
                      >
                        <ListItemPrefix>
                          <TiPointOfInterestOutline
                            strokeWidth={3}
                            className="h-3 w-5"
                          />
                        </ListItemPrefix>
                        Premium Rates Setup
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/setup/tax"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/tax"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`p-0 text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  py-2.5 px-2  capitalize`}
                      >
                        <ListItemPrefix>
                          <TiPointOfInterestOutline
                            strokeWidth={3}
                            className="h-3 w-5"
                          />
                        </ListItemPrefix>
                        Tax Setup
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/setup/funding"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/setup/funding"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={`p-0 text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  py-2.5 px-2  capitalize`}
                      >
                        <ListItemPrefix>
                          <RiFundsLine strokeWidth={3} className="h-3 w-5" />
                        </ListItemPrefix>
                        Account funding
                      </ListItem>
                    </Link>
                    <Link to={"/dashboard/funding-approval"}>
                      <ListItem
                        style={{
                          background:
                            history?.location?.pathname ===
                              "/dashboard/funding-approval"
                              ? `${colors?.length! > 1
                                ? `${colors![1]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                              }`
                             : ""
                           ,
                             color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                        }}
                        className={` p-0 text-sm hover:${
                          colors?.length! > 1
                            ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                            : "bg-red-400"
                        }  py-2.5 px-2 capitalize`}
                      >
                        <ListItemPrefix>
                          <MdApproval strokeWidth={3} className="h-3 w-5" />
                        </ListItemPrefix>
                        funding approval
                      </ListItem>
                    </Link>
                  </List>
                </AccordionBody>
              </Accordion>
            </List>
          )}
        {usertype === "staff" &&
          shouldShowMenuItem("Reports and Analytics", permissions) && (
            <Link to={"/dashboard/reports"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard/reports"
                      ? `${colors?.length! > 1
                        ? `${colors![1]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                      }`
                     : ""
                   ,
                     color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
                }}
                className={` text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                    : "bg-red-400"
                } capitalize -mt-1`}
              >
                <ListItemPrefix>
                  <MdReport />
                </ListItemPrefix>
                report
              </ListItem>
            </Link>
          )}
        {usertype === "staff" &&
          shouldShowMenuItem("Settings", permissions) && (
            <Link to={"/dashboard/settings"}>
            <ListItem
              style={{
                background:
                  history?.location?.pathname === "/dashboard/settings"
                    ? `${colors?.length! > 1
                      ? `${colors![1]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`
                    }`
                   : ""
                 ,
                   color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
              }}
              className={`text-sm hover:${
                colors?.length! > 1
                  ? `bg-${stateUser?.colors![1]?.toLowerCase()}-200`
                  : "bg-red-400"
              } e capitalize -mt-1`}
            >
              <ListItemPrefix>
                <IoSettings  />
              </ListItemPrefix>
              settings
            </ListItem>
          </Link>
          )}

        <div
          className={`hover:bg-${
            colors?.length! > 1 && stateUser?.colors![1]?.toLowerCase()
          }-200 rounded-xl items-start justify-start`}
        >
          <div className="h-0.5 w-fit  rounded-md" />
          <ListItem
          style={{
           
              
          color: `${colors?.length! > 2 ? `${colors![2]}` : "white"}`
      
          }}
            onClick={handleLogout}
            className="hover:bg-transparent  text-xs w-full"
          >
            <ListItemPrefix>
              <i className="fa fa-sign-out text-sm" aria-hidden="true"></i>
            </ListItemPrefix>
            Log Out
          </ListItem>
        </div>
      </List>
    </Card>
  )
}

export default SidebarContent
