import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import { UserType } from "../../../types/user"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import moment from "moment"
import { DriverModal } from "../../../components/modal/DriverModal"
import ReplenishmentRecueiptsViewMore from "../../../components/Details/replenishrecieptsviewmore"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { REPLENISHMENT_RECIEPTS } from "../../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import { RootState } from "../../../store/store"
import { useSelector, useDispatch } from "react-redux"
import { getColorCode } from "../../../colorCodes"

interface ViewCashChequePaymentSummary {
  date_replenished: string
  no_of_transactions: number
  payment_type: "cash" | "cheque" // Assuming it can be either 'cash' or 'cheque'
  reference_number: string
  replenished_by_name: string
  total_amount: number
  staff_number: string
  payee_name: string
  payee_code: string
}
type replenishType = {
  date_replenished: string
  number_of_trasactions: number
  amount: number
  reference: string
  replenished_by: string
}

const dummyData: replenishType[] = [
  {
    date_replenished: "2024-01-15",
    number_of_trasactions: 10,
    amount: 1500.75,
    reference: "REF123456",
    replenished_by: "John Doe",
  },
  // {
  //   date_replenished: "2024-02-20",
  //   number_of_trasactions: 5,
  //   amount: 750.5,
  //   reference: "REF789012",
  //   replenished_by: "Jane Smith",
  // },
]
const ReplenishmentsReciepts = () => {
  const [handleRow, setHandleRow] = useState<{
    id: string
    type: string
    name: string
    staff: string
    payee_name: string
    payee_code: string
  }>()
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [Loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const { companyId, user: stateUser } = useSelector(
    (state: RootState) => state.auth
  )

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }

  const { loading, data: replenishment_reciepts } = useQuery<{
    view_cash_cheque_payment_summary: ViewCashChequePaymentSummary[]
  }>(REPLENISHMENT_RECIEPTS, {
    variables: {
      id: companyId,
    },
  })
  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  console.log(replenishment_reciepts)
  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser])

  const filterData = (filter: ViewCashChequePaymentSummary) => {
    const date = moment(filter?.date_replenished).format("LL")
    const isTextMatch =
      filter?.total_amount
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.date_replenished
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.no_of_transactions
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.payment_type
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      date?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.reference_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.replenished_by_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  useEffect(() => {
    if (searchText || startDate || endDate) {
      setCurrentPage(1)
    }
  }, [searchText, startDate, endDate])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const totalNum =
    replenishment_reciepts?.view_cash_cheque_payment_summary?.length

  // console.log(replenishment_reciepts?.view_cash_cheque_payment_summary)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalItems = replenishment_reciepts?.view_cash_cheque_payment_summary
    ?.filter(filterData)
    ?.filter((item: any) => {
      const normalizeDate = (date: Date | string): Date => {
        const normalizedDate = new Date(date)
        normalizedDate.setHours(0, 0, 0, 0) // Set time to midnight to include the entire day
        return normalizedDate
      }
      const from =
        (startDate as any) instanceof Date
          ? normalizeDate(startDate)
          : normalizeDate(startDate)
      const to =
        (endDate as any) instanceof Date
          ? normalizeDate(endDate)
          : normalizeDate(endDate)
      const dateCreated = normalizeDate(item.date_replenished)

      if (startDate && endDate) {
        return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
      } else if (startDate) {
        return dateCreated >= from // Only from date is set
      } else if (endDate) {
        return dateCreated <= to // Only to date is set
      }
      return true // No filter applied
    })

  const paginatedData = totalItems?.slice(startIndex, endIndex)

  const handleDownload = () => {
    // Check if there is data to download
    if (paginatedData?.length === 0) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter

    const type = paginatedData?.map((item: ViewCashChequePaymentSummary) => ({
      reference_number: item?.reference_number,
      no_of_transactions: item?.no_of_transactions,
      total_amount: item?.total_amount,
      payment_type: item?.payment_type,
      date_replenished: item?.date_replenished,
      replenished_by_name: item?.replenished_by_name,
    }))
    const firstItem = type![0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"list-of-all-receipts"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  return (
    <>
      <CustomHeader title="Receipts" />
      <DriverModal
        title="REPLENISHMENT RECEIPT"
        open={open}
        size={"lg"}
        onClose={() => setOpen(false)}
        children={<ReplenishmentRecueiptsViewMore content={handleRow} />}
      />
      <div className="mt-10 w-full ">
        <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
          <div className="bg-black pt-5 rounded-t-2xl">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold leading-none opacity-70"
            >
              List of All Reciepts{" "}
              {totalNum && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {totalNum}
                </span>
              )}
            </Typography>
          </div>
          <div className="w-full overflow-auto">
            <div className="flex flex-col gap-2  bg-white p-3">
              <div className="flex shrink-0 flex-col gap-2 justify-end sm:flex-row pl-10">
                <Tooltip content="Export as CSV">
                  <Button
                    onClick={handleDownload}
                    variant="filled"
                    className="capitalize text-sm font-normal flex flex-wrap gap-2"
                    size="sm"
                  >
                    <FiDownloadCloud size={20} />
                    <span>Download csv</span>
                  </Button>
                </Tooltip>
              </div>
              <div className="p-3 flex gap-x-2 items-center flex-col 2xl:flex-row gap-y-3 mb-5">
                <div className=" mr-3 flex  gap-x-3 w-full">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    placeholder=""
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>

                <div className="flex flex-col md:flex-row gap-3 justify-between mb-0">
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">From</label>
                    <Input
                      type="datetime-local"
                      value={startDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleStartDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">To</label>
                    <Input
                      type="datetime-local"
                      value={endDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleEndDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              <table className="w-full bg-white table-auto">
                <thead className="bg-white sticky z-50 top-0">
                  <tr className="">
                    {[
                      "Reference",
                      "Number of Transaction",
                      "Amount",
                      "Payment Type",
                      "Date Replenished",
                      "Staff/Intermidiary",
                      "Replenished By",
                      "Action",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-2 py-4  "
                      >
                        <Typography
                          color="black"
                          className="font-bold text-[0.8rem] text-black leading-none opacity-70 "
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-2 py-3  text-center"
                      : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <>
                        <tr
                          onClick={(e) => {
                            // setSelectedRow(item?.driver_id)
                            e.stopPropagation()
                            // setOpenSideNav(true)
                          }}
                          key={index}
                          className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.reference_number}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.no_of_transactions}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              GHS{" "}
                              {Number.isNaN(item?.total_amount)
                                ? "0.00"
                                : (item?.total_amount).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.payment_type}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {moment(item?.date_replenished).format("LLL")}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.payee_name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.replenished_by_name}
                            </Typography>
                          </td>

                          <td
                            className={classes}
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          >
                            <Button
                              // onClick={() => }
                              onClick={() => {
                                setOpen(true)
                                setHandleRow({
                                  id: item?.reference_number,
                                  staff: item?.staff_number,
                                  type: item?.payment_type,
                                  name: item?.replenished_by_name,
                                  payee_name: item?.payee_name,
                                  payee_code: item?.payee_code,
                                })
                              }}
                              variant="filled"
                              color="green"
                              className="font-normal text-xs w-20 py-1 capitalize"
                            >
                              View More
                            </Button>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          {loading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {paginatedData?.length! === 0 && !loading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}
        </CardBody>

        {paginatedData && paginatedData?.length !== 0 && !loading && (
          <GeneralPagination
            totalItems={totalItems?.length!}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
        {/* {Loading && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-1o h-10" />
          </div>
        )} */}

        {/* {paginatedData?.length! < 1 && !loading && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )} */}

        {/* Paginator */}
        {/* <div className=" text-white">
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>
            Page {startingPage}-{endingPage} of {totalPages}
          </span>
          <button onClick={handleNextPage} disabled={endingPage === totalPages}>
            Next
          </button>
        </div> */}

        {/* {paginatedData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                color="white"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="white" className="font-normal">
                Page <strong className="text-white">{activePage}</strong> of{" "}
                <strong className="text-white">
                  {Math.ceil(
                    (driverData?.view_driver_policy_totals_details?.filter(
                      filterData
                    ).length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                color="white"
                onClick={nextPage}
                disabled={
                  activePage ===
                  Math.ceil(
                    (driverData?.view_driver_policy_totals_details?.filter(
                      filterData
                    ).length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )} */}
      </div>
    </>
  )
}

export default ReplenishmentsReciepts
