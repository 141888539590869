import { Typography } from "@material-tailwind/react"
import React, { useEffect, useState } from "react"
import HeaderIcon from "./HeaderIcon"
import { UserType } from "../../types/user"
import { DEFAULTPRIMARYCOLOR } from "../../util"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { getColorCode } from "../../colorCodes"

export const CustomHeader = ({
  title,
  description,
}: {
  title: string
  description?: string
}) => {
  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
  } = useSelector((state: RootState) => state.auth)

  // const userData = localStorage.getItem("user");
  const colors = getColorCode(stateUser?.colors)

  console.log("colors", colors)

  return (
    <div className="sticky top-0 z-50 mb-5 w-full">
      <div className="bg-[#222020] py-3 sticky top-0 z-50 w-full flex flex-row items-center justify-between">
        <div className="">
          <Typography
            style={{
              color:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className="capitalize font-bold text-lg lg:text-xl"
          >
            {title}
          </Typography>
          {description && (
            <span className="text-sm text-white">{description}</span>
          )}
        </div>
        <HeaderIcon />
      </div>
    </div>
  )
}
