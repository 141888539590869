import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../components/headers/CustomHeader"
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import moment from "moment"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { toast } from "react-toastify"
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../util"
import { GiCheckMark } from "react-icons/gi"
import { GrClose } from "react-icons/gr"
import { DetailDrawer } from "../../components"
import WithdrawalDetail from "../../components/Details/withdrawal-detail"
import { UserType } from "../../types/user"
import {
  DRIVER_WITHDRAWAL_REQUESTS,
  DRIVERAGGREGATE,
  GETDATAWITHDRAWAL,
} from "../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import { DASHBOARD_USERTYPE } from "../../types/role"
import ConfirmationDialog from "../../config/confirmationbox"
import GeneralPagination from "../../components/paginations/generalpagination"
import { DriverModal } from "../../components/modal/DriverModal"
import DisApproveRequest from "../../components/Details/disapprove-withdrewal-request-form"
import WithdrawalRequestDetail from "../../components/Details/withdrawal-request-details"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import Otp from "../../components/modal/enterOtpModal"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { RiAdminLine } from "react-icons/ri"
import { DriverAggreateType } from "../../types/query"
import { useDebounceSearch } from "../../hooks/useDebounce"
import { getColorCode } from "../../colorCodes"

type ResponseType = {
  status: string
  amount: number
  created_at: string
  created_by: string
  reference_number: string
  driver_current_balance: number
  id: string
  otp_code: string

  agent_details: {
    id: string
    first_name: string
    last_name: string
    phone_number: string
  }
  driver: {
    account_status: string
    address: string
    balance: number
    bank_details: {
      branch: string
      bank_name: string
      account_number: string
    }
    created_at: string
    consent: boolean
    dob: string
    email: string
    full_name: string
    gender: string
    id: string
    id_card_type: string
    id_number: string
    momo_wallet_details: {
      number: string
      network: string
    }
    phone_number_1: string
    phone_number_2: string
  }
  staff_details: {
    id: string
    phone_number_one: string
    name: string
  }
}

type DRIVER_REQUEST_TYPE = {
  __typename: "driver_withdrawal_request"
  id: string
  reference_number: string
  amount: number
  driver: {
    __typename: "drivers"
    momo_wallet_details: { number: string; network: string }
    phone_number: string
    full_name: string
    driver_code: string
    address: string
    balance: number
    id: string
    driver_station: {
      __typename: "driver_stations"
      id: string
      name: string
    }
  }
  company_staff: {
    __typename: "company_staff"
    id: string
    name: string
    phone_number_one: string
  }
  created_at: string
  driver_approval: boolean
  sub_agent: null | {
    __typename: "sub_agents"
    id: string
    first_name: string
    last_name: string
    phone_number: string
  }
  driver_current_balance: number
}

const Invest2InsureWithdrawal = () => {
  const [searchText, setSearchText] = useState("")
  const [data, setData] = useState<ResponseType[]>([])
  const [totalPageSize, setTotalPageSize] = useState(0)

  const [selectedRow, setSelectedRow] = useState<
    ResponseType | DRIVER_REQUEST_TYPE
  >()
  const [open, setOpen] = useState<boolean>(false)
  const [openSendDispproveMsg, setOpenSendDispproveMsg] =
    useState<boolean>(false)
  const [activePage, setActivePage] = useState(1)
  const [activePageWR, setActivePageWR] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)
  const [filterSelect, setFilterSelect] = useState("Withdrawal Requests")
  const [selectedMessagesRows, setSelectedMessagesRows] = useState<
    DRIVER_REQUEST_TYPE[] | []
  >([])
  const [selectedDisaproveRows, setSelecteddisaproveRows] =
    useState<DRIVER_REQUEST_TYPE | null>(null)
  const [openOtp, setOpenOtp] = useState(false)

  const [startDate1, setStartDate1] = useState("")
  const [endDate1, setEndDate1] = useState("")
  const [requestType, setRequestType] = useState("")

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const search = useDebounceSearch<string>(searchText)

  const {
    loading: loadingData,
    error,
    data: withdrawData,
  } = useQuery(GETDATAWITHDRAWAL, {
    variables: { searchText: `%${search}%`, jsonFilter: "" },
  })
  // console.log(withdrawData)

  const handleStartDateChange1 = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z"
    setStartDate1(formattedValue)
  }

  const handleEndDateChange1 = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z"
    setEndDate1(formattedValue)
  }

  useEffect(() => {
    if (filterSelect === "Withdrawals") {
      setStartDate1("")
      setStartDate1("")
    } else {
      setStartDate1("")
      setEndDate1("")
    }
  }, [filterSelect])

  const { data: totalWithdraw, loading: load } =
    useQuery<DriverAggreateType>(DRIVERAGGREGATE)

  const {
    loading: LOADINGDATA,
    data: REQUESTDATA,
    refetch: refetchallRequests,
  } = useQuery<{
    driver_withdrawal_request: DRIVER_REQUEST_TYPE[]
  }>(DRIVER_WITHDRAWAL_REQUESTS)

  // console.log(REQUESTDATA)

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && paginatedData) {
      setSelectedMessagesRows(REQUESTDATA?.driver_withdrawal_request!)
    } else {
      setSelectedMessagesRows([])
    }
  }
  // console.log(selectedMessagesRows)

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: DRIVER_REQUEST_TYPE
  ) => {
    if (event.target.checked) {
      setSelectedMessagesRows([...selectedMessagesRows, item])
      setSelecteddisaproveRows(item)
    } else {
      setSelectedMessagesRows(
        selectedMessagesRows.filter((obj) => obj.driver.id !== item.driver.id)
      )
      setSelecteddisaproveRows(null)
    }
  }

  // console.log(selectedDisaproveRows)

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const fetchData = async () => {
    setData([])
    setLoading(true)
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/get-withdrawals?page=${activePage}&limit=${pageSize}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const req = await res.json()
      if (res?.ok) {
        setData(req.data)
        // console.log(req)
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      toast.error(error?.message ?? "Something went wrong. Try again!")
    }
  }

  // console.log(selectedRow)

  //----------------------------
  const filterData1 = (filter: DRIVER_REQUEST_TYPE) => {
    const isTextMatch =
      filter?.amount
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.full_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.phone_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver_current_balance
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.reference_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.id
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.driver_code
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.address
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  const [currentPageWR, setCurrentPageWR] = useState(1)
  const [itemsPerPageWR] = useState(10)
  useEffect(() => {
    if (searchText || startDate1 || endDate1) {
      setCurrentPageWR(1)
    }
  }, [searchText, startDate1, endDate1])

  const handlePageChangeWR = (page: number) => {
    setCurrentPageWR(page)
  }

  const startIndex = (currentPageWR - 1) * itemsPerPageWR
  const endIndex = startIndex + itemsPerPageWR
  const totalLength = REQUESTDATA?.driver_withdrawal_request
    ?.filter(filterData1)
    ?.filter((item: any) => {
      const normalizeDate = (date: Date | string): Date => {
        const normalizedDate = new Date(date)
        normalizedDate.setHours(0, 0, 0, 0) // Set time to midnight to include the entire day
        return normalizedDate
      }
      const from =
        (startDate1 as any) instanceof Date
          ? normalizeDate(startDate1)
          : normalizeDate(startDate1)
      const to =
        (endDate1 as any) instanceof Date
          ? normalizeDate(endDate1)
          : normalizeDate(endDate1)
      const dateCreated = normalizeDate(item.created_at)
      // Convert date_created to Date object
      // Check if dateCreated falls within the range (inclusive)
      if (startDate1 && endDate1) {
        return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
      } else if (startDate1) {
        return dateCreated >= from // Only from date is set
      } else if (endDate1) {
        return dateCreated <= to // Only to date is set
      }
      return true // No filter applied
    })

  const paginatedDataWR = totalLength?.slice(startIndex, endIndex)

  //-------------------------

  const filterData = (filter: ResponseType) => {
    const isTextMatch =
      filter?.reference_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.full_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.email
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.gender
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.id_card_type
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.address
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.id_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.phone_number_1
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.driver?.momo_wallet_details?.network
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.staff_details?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.staff_details?.phone_number_one
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.otp_code?.toLowerCase()?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  // Filtered and paginated data for the current page
  const filteredData = data?.filter(filterData)
  // console.log(filteredData)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndexW = (currentPage - 1) * itemsPerPage
  const endIndexW = startIndexW + itemsPerPage
  const paginatedData = (
    withdrawData?.driver_withdrawal_request as ResponseType[]
  )?.slice(startIndexW, endIndexW)
  const paginatedData1 = filteredData?.filter((item: any) => {
    const normalizeDate = (date: Date | string): Date => {
      const normalizedDate = new Date(date)
      normalizedDate.setHours(0, 0, 0, 0) // Set time to midnight to include the entire day
      return normalizedDate
    }
    const from =
      (startDate1 as any) instanceof Date
        ? normalizeDate(startDate1)
        : normalizeDate(startDate1)
    const to =
      (endDate1 as any) instanceof Date
        ? normalizeDate(endDate1)
        : normalizeDate(endDate1)
    const dateCreated = normalizeDate(item.created_at)
    // Convert date_created to Date object
    // Check if dateCreated falls within the range (inclusive)
    if (startDate1 && endDate1) {
      return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
    } else if (startDate1) {
      return dateCreated >= from // Only from date is set
    } else if (endDate1) {
      return dateCreated <= to // Only to date is set
    }
    return true // No filter applied
  })

  console.log(paginatedData)
  const handleDownload = (select: string) => {
    // Check if there is data to download

   
    
    if (filterSelect === "Withdrawal Requests") {
      if( paginatedDataWR?.length === 0){
        alert("No data to download.")
        return
      }
      const type1 = paginatedDataWR?.map((item: any) => ({
        "Reference Number": item?.reference_number,
        "Driver Code": item?.driver?.driver_code,
        "Driver Name": item?.driver?.full_name,
        "Phone Number": item?.driver?.phone_number,
        Address: item.driver.address,
        "Wallet Details": `${item?.driver?.momo_wallet_details?.number} (${item.driver.momo_wallet_details?.network})`,
        "Station Name": item?.driver?.driver_station?.name || "",
        "Amount Requested": item?.amount,
        "Current Balance": item?.driver_current_balance,
        "Driver Approval": item?.driver_approval ? "Yes" : "No",
        "Agent/Staff Information": `${item?.company_staff?.name} (${item?.company_staff?.phone_number_one})`,
        "Date Requested": moment(item?.created_at).format("LLL"),
      }))

      const firstItem = type1![0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = type1?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"withdraw-request"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } else {
      if (paginatedData?.length === 0) {
        alert("No data to download.")
        return
      }
      const type = paginatedData?.map((item: ResponseType) => ({
        "Reference Number": item?.reference_number,
        "Driver's Name": item?.driver?.full_name,
        "Amount Requested": item?.amount,
        "Driver's Number": item?.driver?.phone_number_1,
        "Driver's Balance": item?.driver_current_balance,
        "Transaction Date": moment(item?.created_at).format("LLL"),
        "Approved by": item?.agent_details?.first_name,
        Status: item?.status,
      }))
      const firstItem = type![0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = type?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"withdrawals"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])
  // console.log(selectedMessagesRows)

  //pay withdrwal requests
  const [loadingCreating, setLoadingCreating] = useState(false)
  const [loadingRow, setLoadingRow] = useState("")
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [chosenId, setChosenId] = useState("")
  const handleCreateSinglePaymentOrder = async (id: string) => {
    try {
      setLoadingCreating(true)
      setLoadingRow(id)
      const payload = JSON.stringify([
        {
          withdrawal_id: id,
          reason: "",
        },
      ])

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/pay-all-withdrawal-requests`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: payload, // Place the body outside the headers object
        }
      )

      if (!response.ok) {
        toast.error("Error making payment")
        return
      }
      refetchallRequests()
      toast.success("Payment made successfull")
    } catch (error) {
      toast.error("Error making payment")
    } finally {
      setLoadingCreating(false)
      setLoading(false)
      setLoadingCreating(false)
      setLoadingRow("")
    }
  }

  //multiple payment order

  const handleCreateMultiplePaymentOrder = async () => {
    try {
      setLoadingCreating(true)

      const payload = JSON.stringify(
        selectedMessagesRows.map((row) => ({
          withdrawal_id: row.id,
          reason: "",
        }))
      )

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/pay-all-withdrawal-requests`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: payload, // Place the body outside the headers object
        }
      )

      if (!response.ok) {
        toast.error("Error making payments")
        return
      }
      refetchallRequests()
      toast.success("Payment made successfull")
    } catch (error) {
      toast.error("Error making payments")
    } finally {
      setLoadingCreating(false)
      setLoading(false)
      setLoadingCreating(false)
    }
  }

  return (
    <>
      <CustomHeader title="withdrawals" />
      <DetailDrawer
        title="Withdrawal Detail"
        open={open}
        size={400}
        onClose={() => setOpen(false)}
        children={
          filterSelect === "" ? (
            <WithdrawalDetail content={selectedRow as ResponseType} />
          ) : (
            <WithdrawalRequestDetail
              content={selectedRow as DRIVER_REQUEST_TYPE}
            />
          )
        }
      />
      <ConfirmationDialog
        show={showConfirmation}
        setShow={setShowConfirmation}
        onConfirm={() => setOpenOtp(true)}
      />
      <DriverModal
        title="Enter Your PIN"
        open={openOtp}
        size={"xs"}
        focus="message"
        onClose={() => setOpenOtp(false)}
        children={
          <Otp
            onConfirm={() =>
              requestType === "approve"
                ? handleCreateSinglePaymentOrder(chosenId)
                : requestType === "disapprove"
                ? setOpenSendDispproveMsg(true)
                : handleCreateMultiplePaymentOrder()
            }
            onClose={() => setOpenOtp(false)}
          />
        }
        // () => handleCreateSinglePaymentOrder(chosenId)
      />

      <DriverModal
        title={
          <p className=" text-gray-800 text-[1.2rem]">Disapprove Request?</p>
        }
        open={openSendDispproveMsg}
        size={400}
        focus="message"
        onClose={() => setOpenSendDispproveMsg(false)}
        children={
          <DisApproveRequest
            driver={selectedDisaproveRows?.id}
            onConfirm={()=> refetchallRequests()}
            onClose={() => setOpenSendDispproveMsg(false)}
          />
        }
      />
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-2 md:lg:gap-4 lg:gap-5">
        <Card className="bg-brand-danger rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between">
            <div>
              <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                {load ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  "GH₵" +
                  (
                    totalWithdraw?.total_withdrawals?.aggregate?.sum?.amount ??
                    0
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                total withdrawals
              </Typography>
            </div>
            <RiAdminLine size={28} color="#cbcbcb" />
          </div>
        </Card>
      </div>
      <div className="mt-10">
        <div className="mt-5 flex flex-col gap-y-6 bg-white p-3 rounded-lg">
          <div className=" flex justify-between items-center mt-3">
            <div className="flex flex-row items-center w-full lg:w-1/2 mr-3">
              <Typography className="w-28">Filter by</Typography>
              <select
                value={filterSelect}
                onChange={(e) => setFilterSelect(e.target.value)}
                className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected disabled>
                  Select filter
                </option>
                {["Withdrawal Requests", "Withdrawals"]?.map((item) => (
                  <option
                    className="capitalize"
                    value={item}
                    key={item}
                    label={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row pl-10">
              <Tooltip content="Export as CSV">
                <Button
                  onClick={() => {
                    handleDownload(filterSelect)
                  }}
                  variant="filled"
                  className="capitalize text-sm font-normal flex flex-wrap gap-2"
                  size="sm"
                >
                  <FiDownloadCloud size={20} />
                  <span>Download csv</span>
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className="p-3 flex gap-x-2 items-center flex-col 2xl:flex-row gap-y-3 mb-3">
            <div className="w-full">
              <Input
                crossOrigin="anonymous"
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div>
            <div className="flex flex-col md:flex-row gap-3 justify-between mb-0">
              <div className="flex items-center space-x-2">
                <label className="font-semibold text-gray-700">From</label>
                <Input
                  type="datetime-local"
                  value={startDate1.slice(0, -1)} // Remove 'Z' for input value
                  onChange={handleStartDateChange1}
                  size="md"
                  className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
              <div className="flex items-center space-x-2">
                <label className="font-semibold text-gray-700">To</label>
                <Input
                  type="datetime-local"
                  value={endDate1.slice(0, -1)} // Remove 'Z' for input value
                  onChange={handleEndDateChange1}
                  size="md"
                  className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10">
        {filterSelect === "Withdrawal Requests" ? (
          <>
            <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
              <div className="bg-black pt-5 rounded-t-2xl">
                <Typography
                  variant="h6"
                  style={{
                    color:
                      colors?.length! > 1
                        ? `${colors![0]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`,
                  }}
                  className="px-4 pb-4   font-bold leading-none opacity-70 "
                >
                  <p className="">
                    {" "}
                    List of All Withdrawal Requests{" "}
                    <span className="text-white">
                      {REQUESTDATA?.driver_withdrawal_request?.length}
                    </span>
                  </p>
                </Typography>
              </div>
              {selectedMessagesRows?.length >= 2 && (
                <Button
                  // onClick={() => handleCreateMultiplePaymentOrder()}
                  onClick={() => {
                    setRequestType("multiple")
                    setOpenOtp(true)
                  }}
                  variant="filled"
                  color="green"
                  className="font-normal capitalize text-[0.8rem] my-3"
                >
                  {loadingCreating ? (
                    <p>loading</p>
                  ) : (
                    <p>Make Payments ({selectedMessagesRows.length})</p>
                  )}
                </Button>
              )}

              <CardBody className="px-0 py-0">
                <div
                  className="overflow-x-auto overflow-y-auto"
                  style={{ maxHeight: "65vh" }}
                >
                  <table className="w-full min-w-max bg-white table-auto">
                    <thead className="bg-white sticky z-50 top-0">
                      <tr>
                        {[
                          "reference number",
                          "Driver code",
                          "Driver name",
                          "Phone number",
                          "Address",
                          "Wallet details",
                          "Station name",
                          "Amount Requested",
                          "Current balance",
                          "Driver Approval",
                          "Agent/Staff information",
                          "Date Requested",
                        ].map((head) => (
                          <th
                            key={head}
                            className=" border-y border-blue-gray-100 text-black bg-gray-50/50 py-4 p-2  text-center"
                          >
                            <Typography
                              color="black"
                              className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                            >
                              {head === "reference number" ? (
                                <div className=" flex items-center">
                                  {" "}
                                  <Checkbox
                                    color="blue"
                                    // checked={true}
                                    onChange={handleSelectAll}
                                    checked={
                                      selectedMessagesRows.length ===
                                      REQUESTDATA?.driver_withdrawal_request
                                        ?.length
                                    }
                                    size={3}
                                    className=" text-xs "
                                    value={undefined}
                                  />
                                  <p>{head}</p>
                                </div>
                              ) : (
                                head
                              )}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedDataWR?.map((item, index: number) => {
                        const isLast = index === paginatedData?.length - 1
                        const classes = isLast
                          ? "px-2 py-3  text-center"
                          : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                        return (
                          <tr
                            key={index.toString()}
                            onClick={() => {
                              setSelectedRow(item)
                              setOpen(true)
                            }}
                            className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                          >
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className=" font-normal text-xs capitalize "
                              >
                                <div className="pl-2  flex items-center ">
                                  <Checkbox
                                    color="blue"
                                    onChange={(event) =>
                                      handleRowCheckboxChange(event, item)
                                    }
                                    checked={
                                      selectedMessagesRows.length > 0 &&
                                      selectedMessagesRows.some(
                                        (row) =>
                                          row.driver.id === item.driver.id
                                      )
                                    }
                                    // checked={true}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                    }}
                                    size={3}
                                    className=" text-xs mr-2"
                                    value={undefined}
                                  />
                                  {item?.reference_number}
                                </div>
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.driver?.driver_code}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.driver?.full_name}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.driver?.phone_number}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.driver.address}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <div className="flex flex-col gap-2 text-left">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-xs capitalize "
                                >
                                  Wallet No.:{" "}
                                  <span className="font-normal">
                                    {item?.driver?.momo_wallet_details?.number}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-xs capitalize "
                                >
                                  Network:{" "}
                                  <span className="font-normal">
                                    {item?.driver?.momo_wallet_details?.network}
                                  </span>
                                </Typography>
                              </div>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.driver?.driver_station?.name}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                GHS
                                {(item?.amount ?? 0).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                GHS
                                {(item?.driver?.balance ?? 0).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.driver_approval ? "Yes" : "No"}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <div className="flex flex-col gap-2 text-left">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-xs capitalize "
                                >
                                  Full name:{" "}
                                  <span className="font-normal">
                                    {item?.company_staff?.name}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-xs capitalize "
                                >
                                  Phone No_:{" "}
                                  <span className="font-normal">
                                    {item?.company_staff?.phone_number_one}
                                  </span>
                                </Typography>

                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-xs capitalize "
                                >
                                  User type:{" "}
                                  <span className="font-normal">
                                    {item?.company_staff?.__typename}
                                  </span>
                                </Typography>
                              </div>
                            </td>

                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {moment(item?.created_at).format("LLL")}
                              </Typography>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </CardBody>
            {selectedMessagesRows.length < 2 && (
              <div className=" py-10 px-3 flex gap-x-3 items-center justify-end">
                <Button
                  onClick={() => {
                    setChosenId(selectedMessagesRows[0]?.id)
                    setShowConfirmation(true)
                    setRequestType("approve")
                  }}
                  disabled={
                    loadingRow === selectedMessagesRows[0]?.id ||
                    selectedMessagesRows.length === 0
                  }
                  variant="filled"
                  color="green"
                  className="font-normal capitalize text-[0.8rem]"
                >
                  {loadingRow === selectedMessagesRows[0]?.id
                    ? "Loading..."
                    : "Approve Withdrawal"}
                </Button>
                <Button
                  onClick={() => {
                    setOpenOtp(true)
                    setRequestType("disapprove")
                    // setOpenSendDispproveMsg(true)
                  }}
                  disabled={
                    loadingRow === selectedMessagesRows[0]?.id ||
                    selectedMessagesRows.length === 0
                  }
                  variant="filled"
                  color="red"
                  className="font-normal capitalize text-[0.8rem]"
                >
                  {loadingRow === selectedMessagesRows[0]?.id
                    ? "Loading..."
                    : "Disapprove"}
                </Button>
              </div>
            )}
            <>
              {LOADINGDATA && (
                <div className="mt-10 flex items-center justify-center">
                  <Spinner className="w-10 h-10" />
                </div>
              )}

              {paginatedDataWR?.length! === 0 && !LOADINGDATA && (
                <div className="mt-10 flex items-center justify-center">
                  <Typography color="white" variant="h6">
                    No data found
                  </Typography>
                </div>
              )}

              {paginatedDataWR &&
                paginatedDataWR?.length !== 0 &&
                !LOADINGDATA && (
                  <GeneralPagination
                    totalItems={totalLength?.length!}
                    itemsPerPage={itemsPerPageWR}
                    currentPage={currentPageWR}
                    onPageChange={handlePageChangeWR}
                  />
                  // <div className="my-10 flex items-center justify-center">
                  //   <div className="flex items-center gap-8">
                  //     <IconButton
                  //       size="sm"
                  //       variant="filled"
                  //       color="white"
                  //       onClick={prevPageWR}
                  //       disabled={activePageWR === 1}
                  //     >
                  //       <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  //     </IconButton>
                  //     <Typography color="white" className="font-normal">
                  //       Page{" "}
                  //       <strong className="text-white">{activePageWR}</strong> of{" "}
                  //       <strong className="text-white">
                  //         {Math.ceil(
                  //           (REQUESTDATA?.driver_withdrawal_request?.filter(
                  //             filterData1
                  //           ).length || 0) / PAGE_SIZE_1
                  //         )}
                  //       </strong>
                  //     </Typography>
                  //     <IconButton
                  //       size="sm"
                  //       variant="filled"
                  //       color="white"
                  //       onClick={nextPageWR}
                  //       disabled={
                  //         activePageWR ===
                  //         Math.ceil(
                  //           (REQUESTDATA?.driver_withdrawal_request?.filter(
                  //             filterData1
                  //           ).length || 0) / PAGE_SIZE_1
                  //         )
                  //       }
                  //     >
                  //       <AiOutlineArrowRight
                  //         strokeWidth={2}
                  //         className="h-4 w-4"
                  //       />
                  //     </IconButton>
                  //   </div>
                  // </div>
                )}
            </>
          </>
        ) : (
          <>
            {" "}
            <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
              <div className="bg-black pt-5">
                <Typography
                  variant="h6"
                  style={{
                    color:
                      colors?.length! > 1
                        ? `${colors![0]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`,
                  }}
                  className="px-4 pb-4  font-bold leading-none opacity-70 capitalize"
                >
                  <p className="">
                    {" "}
                    List of withdrawals{" "}
                    <span className="text-white">
                      {withdrawData?.driver_withdrawal_request?.length}
                    </span>
                  </p>
                </Typography>
              </div>
              {/* <div className="w-full">
              <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
                <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
                  <div className="w-full mr-3">
                    <Input
                      crossOrigin="anonymous"
                      label="Search"
                      value={searchText}
                      onChange={handleSearch}
                      icon={<i className="fas fa-search text-base"></i>}
                    />
                  </div>
                </div>
              </div>
            </div> */}
              <CardBody className="px-0 py-0">
                <div
                  className="overflow-x-auto overflow-y-auto"
                  style={{ maxHeight: "65vh" }}
                >
                  <table className="w-full bg-white table-auto">
                    <thead className="bg-white sticky z-50 top-0">
                      <tr>
                        {[
                          "reference number",
                          "Driver's Name",
                          "Amount Requested",
                          "Driver's Number",
                          "Driver's Balance",
                          "Transaction Date",
                          "approved by",
                          "Status",
                          "Staff/Agent",
                        ].map((head) => (
                          <th
                            key={head}
                            className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4  text-center"
                          >
                            <Typography
                              color="black"
                              className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData?.map((item, index: number) => {
                        const isLast = index === paginatedData?.length - 1
                        const classes = isLast
                          ? "px-2 py-3  text-center"
                          : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                        return (
                          <tr
                            key={index.toString()}
                            onClick={() => {
                              setSelectedRow(item)
                              setOpen(true)
                            }}
                            className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                          >
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize "
                              >
                                {item?.reference_number}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.driver?.full_name}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                GHS
                                {(item?.amount ?? 0).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.driver?.phone_number_1}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                GHS
                                {(
                                  item?.driver_current_balance ?? 0
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {moment(item?.created_at).format("LLL")}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                Mr. Joe
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color={
                                  item?.status?.toLowerCase() === "success"
                                    ? "green"
                                    : "red"
                                }
                                className="font-normal text-xs capitalize"
                              >
                                {item?.status}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <div className="flex flex-col gap-2 text-left">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-xs capitalize "
                                >
                                  Full name:{" "}
                                  <span className="font-normal">
                                    {item?.staff_details
                                      ? `${item?.staff_details?.name}`
                                      : `${item?.agent_details?.first_name} ${item?.agent_details?.last_name}`}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-xs capitalize "
                                >
                                  Phone number:{" "}
                                  <span className="font-normal">
                                    {item?.staff_details
                                      ? item?.staff_details?.phone_number_one
                                      : item?.agent_details?.phone_number}
                                  </span>
                                </Typography>

                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-xs capitalize "
                                >
                                  User type:{" "}
                                  <span className="font-normal">
                                    {item?.staff_details
                                      ? "Company Staff"
                                      : "Sub Agent"}
                                  </span>
                                </Typography>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </CardBody>
            {loadingData && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-10 h-10" />
              </div>
            )}
            {paginatedData?.length! === 0 && !loadingData && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="white" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
            {paginatedData && paginatedData?.length !== 0 && !loadingData && (
              <GeneralPagination
                totalItems={withdrawData?.driver_withdrawal_request?.length!}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Invest2InsureWithdrawal
