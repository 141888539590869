import React, { useState, useEffect, useMemo } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  CardHeader,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import { BiSearch } from "react-icons/bi"
import { FiDownloadCloud } from "react-icons/fi"
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util"
import useSWR from "swr"
import dayjs from "dayjs"
import Papa from "papaparse"
import { toast } from "react-toastify"
import { useQuery } from "@apollo/client"
import {
  COMMISSIONREPORT,
  Customers,
  GETREPORTDATA,
  GETSUBSCRIPTION,
  GETTAXREPORT,
  POLICYREPORT,
} from "../../../GraphQl/queries"
import { ReportType } from "../../../types/query"
import {
  Commissions_HEADERS,
  Customers_HEADERS,
  Policy_HEADERS,
  Subscriptions_Table_Head,
  Table_Headers,
  Tax_HEADERS,
} from "./data"
import moment from "moment"
import { UserType } from "../../../types/user"
import PolicyPage from "./PolicyPage"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { RootState } from "../../../store/store"
import { useSelector } from "react-redux"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { start } from "repl"

type CustomerType = {
  customer_type: string
  email: string
  first_name: string
  ghana_card: string
  id: string
  last_name: string
  phone_number: string
  date: string
  created_at: string
}

type SubscriptionType = {
  amount: number
  datecreated: string
  date: string
  paid_by: any
  reference_number: string
  status: string
  subscription_amount: number
  policy: {
    policy_number: string
    request: {
      amount: string
      businessClassByBusinessClass: {
        id: string
        name: string
      }
      cover_type: string
    }
  }
}

type TaxType = {
  amount: number
  created_at: string
  date: string
  rate: number
  id: string
  tax: {
    id: string
    name: string
  }
  commissions_payable: {
    commission_amount: number
    rate: string
    reference_amount: number
    status: string
    commision: {
      id: string
      name: string
    }
  }
}
type CommissionsType = {
  account_details: string
  created_by: any
  date: string
  payee_details: string
  payee_name: string
  payee_number: string
  payee_type: string
  payment_stage: string
  provider_transaction_id: string
  rate: string
  reference: string
  reference_amount: number
  status: string
  transaction_name: string
  transaction_category: string
  transaction_type_id: string
  policy_id: string
}

type PolicyType = {
  certificate_number: number
  debit_number: number
  policy_number: number
  date: string
  request: {
    amount: number
    payment_channel: string
    user_type: string
    sub_agent: {
      email: string
      first_name: string
      id: string
      last_name: string
    }
    business_class: {
      id: string
      name: string
    }
    company_staff: {
      id: string
      name: string
      email: string
    }
    cover_type: string
  }
}

const ReportView = () => {
  const options = [
    "subscriptions",
    "taxes payment",
    "Commissions",
    "policy",
    "Customers",
  ]
  const [activePage, setActivePage] = useState(1)
  const { companyId, user } = useSelector((state: RootState) => state.auth)
  // console.log(companyId)

  const [filter, setFilter] = useState("subscriptions")
  console.log(filter)
  const [tableHead, setTableHead] = useState<string[]>(Subscriptions_Table_Head)

  // const {
  //   companyId,
  //   roleId,
  //   usertype,
  //   user: stateUser,
  //   token,
  // } = useSelector((state: RootState) => state.auth)

  const PAGE_SIZE = 10
  const [filteredData, setFilteredData] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState("")

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }
  // console.log(startDate, endDate)

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }

  useEffect(() => {
    function getPastWeekAndCurrentDate() {
      const currentDate = moment().utc()
      const pastWeekDate = moment().utc().subtract(1, "weeks")

      return {
        currentDate: currentDate.format("YYYY-MM-DDTHH:mm:ss[Z]"),
        pastWeekDate: pastWeekDate.format("YYYY-MM-DDTHH:mm:ss[Z]"),
      }
    }

    // Usage
    const { currentDate, pastWeekDate } = getPastWeekAndCurrentDate()
    setStartDate(pastWeekDate)
    setEndDate(currentDate)
    // console.log("Current Date:", currentDate)
    // console.log("Date from one week ago:", pastWeekDate)
  }, [])

  // console.log(startDate, endDate, companyId)

  const { data: customer, loading: loadingCustomer } = useQuery<{
    customers: CustomerType[]
  }>(Customers, {
    variables: {
      company_id: companyId,
      start_date: startDate,
      end_date: endDate,
    },
  })
  console.log(customer)

  const { data: subscriptionsData, loading: loadingSubscriptionsData } =
    useQuery<{ subscription_payment: SubscriptionType[] }>(GETSUBSCRIPTION, {
      variables: {
        company_id: companyId,
        start_date: startDate,
        end_date: endDate,
      },
    })
  console.log(subscriptionsData)

  const { data: tax_payable, loading: loadingTaxPayable } = useQuery<{
    tax_payable: SubscriptionType[]
  }>(GETTAXREPORT, {
    variables: {
      company_id: companyId,
      start_date: startDate,
      end_date: endDate,
    },
  })
  console.log(tax_payable)

  const { data: commissionData, loading: loadingCommission } = useQuery<{
    view_payment_requistion_details: CommissionsType[]
  }>(COMMISSIONREPORT, {
    variables: {
      company_id: companyId,
      start_date: startDate,
      end_date: endDate,
    },
  })
  console.log(commissionData)

  const { data: policyData, loading: loadingPolicy } = useQuery<{
    policy: PolicyType[]
  }>(POLICYREPORT, {
    variables: {
      id: companyId,
      start_date: startDate,
      end_date: endDate,
    },
  })
  console.log(policyData)

  const handleFilterChange = (value: string) => {
    console.log(value)
    switch (value) {
      case "subscriptions":
        setTableHead(Subscriptions_Table_Head)
        break
      case "taxes payment":
        setTableHead(Tax_HEADERS)
        break
      case "Commissions":
        setTableHead(Commissions_HEADERS)
        break
      case "policy":
        setTableHead(Policy_HEADERS)
        break
      case "Customers":
        setTableHead(Customers_HEADERS)
        break
      default:
        setTableHead([])
        break
    }
    setFilter(value)
  }

  const currentData = useMemo(() => {
    switch (filter) {
      case "subscriptions":
        return subscriptionsData?.subscription_payment || []
      case "taxes payment":
        return tax_payable?.tax_payable || []
      case "Commissions":
        return commissionData?.view_payment_requistion_details || []
      case "policy":
        return policyData?.policy || []
      case "Customers":
        return customer?.customers || []
      default:
        return []
    }
  }, [
    filter,
    subscriptionsData,
    tax_payable,
    commissionData,
    policyData,
    customer,
  ])

  useEffect(() => {
    const filteredItems = (() => {
      if (filter === "subscriptions") {
        return (currentData as SubscriptionType[]).filter(
          (item) =>
            item?.amount
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.subscription_amount
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.policy?.request?.amount
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.policy?.request?.businessClassByBusinessClass?.name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.policy?.request?.cover_type
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.reference_number
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      } else if (filter === "taxes payment") {
        // Adjust the filtering logic for taxes payment
        return (currentData as any[]).filter(
          (item) =>
            // Replace with actual filtering logic
            true
        )
      } else if (filter === "Commissions") {
        return (currentData as CommissionsType[]).filter(
          (item) =>
            // item.account_details?.account_name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item?.payee_details
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.payee_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item?.payee_number
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.payee_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item?.payment_stage
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.rate
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.reference_amount
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.transaction_category
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.transaction_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      } else if (filter === "policy") {
        return (currentData as PolicyType[]).filter(
          (item) =>
            item?.debit_number
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.policy_number
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.certificate_number
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.request?.amount
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.request?.business_class?.name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.request?.company_staff?.name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.request?.cover_type
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.request?.payment_channel
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.request?.sub_agent?.first_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.request?.sub_agent?.last_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.request?.sub_agent?.email
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      } else if (filter === "Customers") {
        return (currentData as CustomerType[]).filter(
          (item) =>
            item?.customer_type
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item?.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item?.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item?.phone_number
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      } else {
        return []
      }
    })()

    console.log(filteredItems)

    setFilteredData(filteredItems)
  }, [currentData, searchTerm, filter])

  interface RequestData {
    "Staff/agent name"?: string
    Premium?: string
    "Reciept number"?: string
    "Business class"?: string
    Subclass?: string
    Duration?: string
    "Payment method"?: string
    "Policy start date"?: string
    "Policy end date"?: string
    Covertype?: string
    "Risk id"?: string
    "Benefit added"?: any
    "Amended premium details"?: any
    "Vehicle details"?: any
    Policyholder?: string
  }

  interface CommissionsPayables {
    "Commission Amount"?: string
    "Commission payment status"?: string
    "Total tax deducted"?: string
    "Tax payment status"?: string
    "Commission payee"?: string
  }

  interface SubscriptionPayments {
    "Subscription amount"?: string
  }

  interface FilteredDataItem {
    "Certificate number"?: string
    "Debit number"?: string
    "Policy number"?: string
    "Date created"?: string
    request?: RequestData
    commissions_payables?: CommissionsPayables
    subscription_payments?: SubscriptionPayments
    [key: string]: any // For any other dynamic properties
  }

 
  const handleDownload = () => {
    // Check if there is data to download
    if (filteredData?.length === 0) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter

    if(filter === "subscriptions"){
 const type = (filteredData as SubscriptionType[]).map((item) => ({
      "Policy Number": item?.policy?.policy_number,
      // "Certificate Number": item?.policy?.certificate_number,
      "Amount": item?.amount,
      "cover_type": item?.policy?.request?.cover_type,
      "policy_amount": item?.policy?.request?.amount,
      "Business_Class": item?.policy?.request?.businessClassByBusinessClass?.name,
      "Subscription Amount": item?.subscription_amount,
      "Reference Number": item?.reference_number,
      "Status": item?.status,
      "created_at": moment(item?.datecreated).format("LLL"),
 }))
    const firstItem = type[0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"report_subscription"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }else if(filter === "taxes payment"){
    const type = (filteredData as TaxType[]).map((item) => ({
      "Amount": item?.amount,
      "Rate": `${item?.rate}`,
      "Tax": item?.tax?.name,
      "Commision_rate": `${item?.commissions_payable?.rate}%`,
      "Commission Amount": item?.commissions_payable?.commission_amount,
      "Reference Amount": item?.commissions_payable?.reference_amount,
      "Status": item?.commissions_payable?.status,
      "created_at": moment(item?.created_at).format("LLL"),
    }))
    const firstItem = type[0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"report_tax"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }else if(filter === "Commissions"){
    const type = (filteredData as CommissionsType[]).map((item) => ({
   "Payee Name": item?.payee_name,
      "Created By": item?.created_by,
      "Date": moment(item?.date).format("LLL"), 
      "Payee Details": item?.payee_details,
      "Payee Number": item?.payee_number,
      
      "Payee Type": item?.payee_type,
      "Payment Stage": item?.payment_stage,
      "Rate": `${item?.rate}%`,
      "Reference": item?.reference,
      "Reference Amount": item?.reference_amount,
      "Status": item?.status,
      "Transaction Name": item?.transaction_name,
      "Transaction Category": item?.transaction_category,
    }))
    const firstItem = type[0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"report_commission"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }else if(filter === "policy"){
    const type = (filteredData as PolicyType[]).map((item) => ({
      "Certificate Number": item?.certificate_number,
      "Debit Number": item?.debit_number,
      "Policy Number": item?.policy_number,
      "Date": item?.date,
      "Amount": item?.request?.amount,
      "Payment Channel": item?.request?.payment_channel,
      "User Type": item?.request?.user_type,
      "Sub Agent": item?.request?.sub_agent?.email,
      "Business Class": item?.request?.business_class?.name,
      "Company Staff": item?.request?.company_staff?.name,
      "Cover Type": item?.request?.cover_type,
    }))
    const firstItem = type[0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"report_policy"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }else if(filter === "Customers"){
    const type = (filteredData as CustomerType[]).map((item) => ({
      "Customer Type": item?.customer_type,
      "Email": item?.email,
      "First Name": item?.first_name,
      "Ghana Card": item?.ghana_card,
      "Last Name": item?.last_name,
      "Phone Number": item?.phone_number,
      "Date": item?.date,
      "Created At": moment(item?.created_at).format("LLL"),
    }))
    const firstItem = type[0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"report_customers"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
  }
 

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  useEffect(() => {
    if (searchTerm) {
      setCurrentPage(1)
    }
  }, [searchTerm])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalItems = filteredData
  const paginatedData = useMemo(() => {
    return totalItems?.slice(startIndex, endIndex)
  }, [filteredData, currentPage])

  const userData = user
  const [colors, setColors] = useState<string[]>()

  const nextPage = () => {
    if (activePage * PAGE_SIZE < filteredData.length) {
      setActivePage(activePage + 1)
    }
  }

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    }
  }

  useEffect(() => {
    if (userData) {
      const data: UserType = user

      setColors(data.colors)
    }
  }, [])

  useEffect(() => {
    setActivePage(1) // Reset to page 1 when filter changes
  }, [filter])

  return (
    <>
      <CustomHeader title="Reports" />

      <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
        <div className="flex flex-col md:flex-col lg:flex-row gap-3  w-full">
          <div className="flex flex-row items-center w-full mr-3">
            <Typography className="w-28">Filter by</Typography>
            <select
              className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full md:w-1/4 lg:w-1/4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => handleFilterChange(e?.target?.value)}
            >
              <option selected disabled>
                Select filter
              </option>
              {options?.map((item) => (
                <option
                  className="capitalize"
                  value={item}
                  key={item}
                  label={item}
                >
                  {item}
                </option>
              ))}
            </select>
          </div>
          {/* <div>
            <div className="relative flex w-full lg:max-w-[24rem]">
              <Button
                ripple={false}
                variant="text"
                color="blue-gray"
                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
              >
                {" "}
                From
              </Button>
              <Input
                crossOrigin="anonymous"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Mobile Number"
                className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                size="md"
                containerProps={{
                  className: "min-w-0",
                }}
              />
            </div>
          </div>
          <div>
            <div className="relative flex w-full lg:max-w-[24rem]">
              <Button
                ripple={false}
                variant="text"
                color="blue-gray"
                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
              >
                {" "}
                To
              </Button>
              <Input
                crossOrigin="anonymous"
                type="date"
                placeholder="Mobile Number"
                className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                size="md"
                containerProps={{
                  className: "min-w-0",
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div> */}

          {/* <Button
            // onClick={() => refetch()}
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className="text-xs font-normal w-1/2"
            size="md"
          >
            Generate Report
          </Button> */}
        </div>
      </div>

      <div className="mt-5 bg-white p-3 rounded-lg">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="flex flex-col gap-y-2 bg-white ">
            <div className="flex shrink-0 flex-col  justify-end gap-2 sm:flex-row ml-7">
              <Tooltip content="Export as CSV">
                <Button
                  onClick={handleDownload}
                  variant="filled"
                  className="capitalize text-sm font-normal flex flex-wrap gap-2"
                  size="sm"
                >
                  <FiDownloadCloud size={20} />
                  <span>Download csv</span>
                </Button>
              </Tooltip>
            </div>
            <div className="py-3 flex gap-x-5 items-center flex-col 2xl:flex-row gap-y-3 mb-3">
              <div className="w-full ">
                <Input
                  crossOrigin="anonymous"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  label="Search"
                  icon={<BiSearch className="h-5 w-5" />}
                />
              </div>

              <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">From</label>
                  <Input
                    type="datetime-local"
                    value={startDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleStartDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">To</label>
                  <Input
                    type="datetime-local"
                    value={endDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleEndDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="px-0 py-0">
          <div
            className="overflow-x-auto overflow-y-auto"
            style={{ maxHeight: "55vh" }}
          >
            <table className="w-full  table-auto text-left">
              {filter === "subscriptions" && Subscriptions_Table_Head && (
                <HeaderRenderer tableHead={Subscriptions_Table_Head} />
              )}

              {filter === "taxes payment" && Tax_HEADERS && (
                <HeaderRenderer tableHead={Tax_HEADERS} />
              )}

              {filter === "Commissions" && Commissions_HEADERS && (
                <HeaderRenderer tableHead={Commissions_HEADERS} />
              )}
              {filter === "policy" && Policy_HEADERS && (
                <HeaderRenderer tableHead={Policy_HEADERS} />
              )}
              {filter === "Customers" && Customers_HEADERS && (
                <HeaderRenderer tableHead={Customers_HEADERS} />
              )}

              <tbody>
                {paginatedData?.map((item: any, index: number) => {
                  const isLast = index === paginatedData?.length - 1
                  const classes = isLast
                    ? "px-2 py-4  text-center"
                    : "px-2 py-4 border-b-2 border-blue-gray-50 text-center"

                  return (
                    <>
                      {filter === "subscriptions" ? (
                        <SubscriptionTable
                          classes={classes}
                          item={item}
                          index={index}
                        />
                      ) : filter === "policy" ? (
                        <PolicyTable
                          classes={classes}
                          item={item}
                          index={index}
                        />
                      ) : filter === "taxes payment" ? (
                        <TaxTable classes={classes} item={item} index={index} />
                      ) : filter === "Customers" ? (
                        <CustomerTable
                          classes={classes}
                          item={item}
                          index={index}
                        />
                      ) : filter === "Commissions" ? (
                        <CommissionTable
                          classes={classes}
                          item={item}
                          index={index}
                        />
                      ) : null}
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </CardBody>

        {filter === "subscriptions" &&
        subscriptionsData?.subscription_payment?.length! < 1 ? (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="black" variant="h6">
              No data found for the date range
            </Typography>
          </div>
        ) : filter === "taxes payment" &&
          tax_payable?.tax_payable?.length! < 1 ? (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="black" variant="h6">
              No data found for the date range
            </Typography>
          </div>
        ) : filter === "policy" && policyData?.policy?.length! < 1 ? (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="black" variant="h6">
              No data found for the date range
            </Typography>
          </div>
        ) : filter === "Customers" && customer?.customers?.length! < 1 ? (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="black" variant="h6">
              No data found for the date range
            </Typography>
          </div>
        ) : filter === "Commissions" &&
          commissionData?.view_payment_requistion_details?.length! < 1 ? (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="black" variant="h6">
              No data found for the date range
            </Typography>
          </div>
        ) : null}
        {(loadingCustomer ||
          loadingCommission ||
          loadingPolicy ||
          loadingSubscriptionsData ||
          loadingTaxPayable) && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-10 h-10" />
          </div>
        )}

        {paginatedData && paginatedData?.length !== 0 && (
          <GeneralPagination
            color="black"
            totalItems={totalItems?.length!}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}

        {/* {filteredData.length > 0 && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                color="gray"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="black" className="font-normal">
                Page <strong className="text-black">{activePage}</strong> of{" "}
                <strong className="text-black">{totalPages}</strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                color="gray"
                onClick={nextPage}
                disabled={activePage >= totalPages}
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )}  */}
      </div>
    </>
  )
}

const HeaderRenderer = ({ tableHead }: { tableHead: string[] }) => (
  <thead className="bg-white sticky z-50 top-0">
    <tr>
      {tableHead.map((head) => (
        <th
          key={head}
          className="capitalize text-center border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
        >
          <Typography
            variant="small"
            color="blue-gray"
            className="font-bold leading-none opacity-70"
          >
            {head}
          </Typography>
        </th>
      ))}
    </tr>
  </thead>
)

const PolicyTable = ({
  index,
  classes,
  item,
}: {
  index: number
  classes: string
  item: PolicyType
}) => (
  <>
    {" "}
    <tr
      key={index.toString()}
      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
    >
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className=" font-normal text-xs capitalize"
        >
          {`${item?.policy_number}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {`${item?.certificate_number}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(parseFloat(item?.request?.amount as any) ?? 0).toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {`${item?.request?.payment_channel}`}
        </Typography>
      </td>
      <td className={classes}>
        <div className="flex flex-col gap-2 text-left">
          <Typography
            variant="small"
            color="blue-gray"
            className="text-xs capitalize "
          >
            Name:{" "}
            <span className="font-normal">
              {item?.request?.sub_agent
                ? `${item?.request?.sub_agent?.first_name || ""} ${
                    item?.request?.sub_agent?.last_name || ""
                  }`.trim()
                : item?.request?.company_staff?.name || ""}
            </span>
          </Typography>
          <Typography variant="small" color="blue-gray" className=" text-xs">
            Email:{" "}
            <span className="font-normal">
              {item?.request?.sub_agent
                ? item?.request?.sub_agent?.email || ""
                : item?.request?.company_staff?.email || ""}
            </span>
          </Typography>

          <Typography variant="small" color="blue-gray" className=" text-xs">
            Usertype:{" "}
            <span className="font-normal">{item?.request?.user_type}</span>
          </Typography>
        </div>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="text-xs capitalize "
        >
          {item?.request?.business_class?.name}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="text-xs capitalize "
        >
          {item?.request?.cover_type}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {moment(item?.date).format("LLL")}
        </Typography>
      </td>
    </tr>
  </>
)

const CommissionTable = ({
  index,
  classes,
  item,
}: {
  index: number
  classes: string
  item: CommissionsType
}) => {
  let accountDetails: { [key: string]: any } | null = null
  try {
    accountDetails = item?.account_details
      ? JSON.parse(item.account_details)
      : null
  } catch (error) {
    console.error("Invalid JSON in account_details:", error)
  }

  return (
    <tr
      key={index.toString()}
      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
    >
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.payee_name}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {item?.payee_type}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.payee_number}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.payee_details}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.payment_stage}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.rate}%
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(parseFloat(item?.reference_amount as any) ?? 0).toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.transaction_name}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.transaction_category}
        </Typography>
      </td>
      <td className={classes}>
        <div className="flex flex-col gap-2 text-left">
          <Typography
            variant="small"
            color="blue-gray"
            className="text-xs capitalize "
          >
            Bank:{" "}
            <span className="font-normal">
              {accountDetails?.bank_name.toLowerCase() || "N/A"}
            </span>
          </Typography>
          <Typography
            variant="small"
            color="blue-gray"
            className=" text-xs capitalize "
          >
            Branch:{" "}
            <span className="font-normal">
              {accountDetails?.branch || "N/A"}
            </span>
          </Typography>
        </div>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.status}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {moment(item?.date).format("LLL")}
        </Typography>
      </td>
    </tr>
  )
}

const TaxTable = ({
  index,
  classes,
  item,
}: {
  index: number
  classes: string
  item: TaxType
}) => (
  <>
    {" "}
    <tr
      key={index.toString()}
      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
    >
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className=" font-normal text-xs capitalize"
        >
          {`${item?.tax?.name}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          GH₵{" "}
          {(parseFloat(item?.amount as any) ?? 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.commissions_payable?.commision?.name}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(
            parseFloat(item?.commissions_payable?.commission_amount as any) ?? 0
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.commissions_payable?.rate}%
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(
            parseFloat(item?.commissions_payable?.reference_amount as any) ?? 0
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.commissions_payable?.status}
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {moment(item?.date).format("LLL")}
        </Typography>
      </td>
    </tr>
  </>
)

const SubscriptionTable = ({
  index,
  classes,
  item,
}: {
  index: number
  classes: string
  item: SubscriptionType
}) => (
  <>
    {" "}
    <tr
      key={index.toString()}
      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
    >
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className=" font-normal text-xs capitalize"
        >
          {`${item?.reference_number}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {item?.policy?.policy_number}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.policy?.request?.cover_type}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(parseFloat(item?.amount as any) ?? 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(
            parseFloat(item?.policy?.request?.amount as any) ?? 0
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </td>
      {/* <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(parseFloat(item?.subscription_amount as any) ?? 0).toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        </Typography>
      </td> */}

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.policy?.request?.businessClassByBusinessClass?.name}
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {moment(item?.datecreated).format("LLL")}
        </Typography>
      </td>
    </tr>
  </>
)

const CustomerTable = ({
  index,
  classes,
  item,
}: {
  index: number
  classes: string
  item: CustomerType
}) => (
  <>
    <tr
      key={index.toString()}
      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
    >
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className=" font-normal text-xs capitalize"
        >
          {`${item?.first_name} ${item?.last_name}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {item?.email}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.phone_number}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.customer_type}
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {moment(item?.created_at).format("LLL")}
        </Typography>
      </td>
    </tr>
  </>
)

export default ReportView
