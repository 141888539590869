import { useQuery, gql } from "@apollo/client"

export const ADDTOCOMPREHENSIVE = gql`
  mutation addToComprehensive(
    $data: [comprehensive_third_party_risk_class_insert_input!]!
  ) {
    insert_comprehensive_third_party_risk_class(objects: $data) {
      affected_rows
    }
  }
`

export const UPDATE_COMMISION = gql`
  mutation MyMutation(
    $id: uuid
    $class_id: uuid
    $covertype: String
    $desc: String
    $rate: String
    $usertype: String
    $subclass_id: uuid
  ) {
    update_commisions_rates(
      where: { id: { _eq: $id } }
      _set: {
        class_id: $class_id
        description: $desc
        rate: $rate
        subclass_id: $subclass_id
        cover_type: $covertype
        intermediary_type: $usertype
      }
    ) {
      returning {
        id
      }
    }
  }
`

//driver-mutaions

export const CLOSE_DRIVER_ACCOUNT = gql`
  mutation MyMutation($driver_id: uuid, $updated_at: timestamp) {
    update_drivers(
      where: { id: { _eq: $driver_id } }
      _set: {
        is_deleted: true
        account_status: "inactive"
        updated_at: $updated_at
      }
    ) {
      returning {
        id
      }
    }
  }
`
//---------
export const ADDTOTHIRDPARTY = gql`
  mutation addTothirdParty(
    $data: [third_party_only_risk_class_insert_input!]!
  ) {
    insert_third_party_only_risk_class(objects: $data) {
      affected_rows
    }
  }
`

export const ADDREGION = gql`
  mutation createRegion($name: String) {
    insert_regions(objects: { name: $name }) {
      returning {
        id
      }
    }
  }
`

export const DELETEREGION = gql`
  mutation deleteRegion($region_id: uuid!) {
    delete_regions_by_pk(id: $region_id) {
      id
    }
  }
`

export const CREATE_DISTRICT = gql`
  mutation createDistrict(
    $region_id: uuid!
    $name: String
    $category: String
    $capital: String
  ) {
    insert_districts(
      objects: {
        region_id: $region_id
        name: $name
        category: $category
        capital: $capital
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const CREATE_CLIENT_TYPE = gql`
  mutation addClientType(
    $description: String
    $name: String
    $created_by: uuid
  ) {
    insert_client_types(
      objects: {
        description: $description
        name: $name
        created_by: $created_by
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const CREATE_NEW_DIVISION = gql`
  mutation createDivision($desc: String, $id: uuid, $name: String) {
    insert_divisions(
      objects: { description: $desc, insurance_company_id: $id, name: $name }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETE_DIVISION = gql`
  mutation deleteDivision($id: uuid!) {
    delete_divisions_by_pk(id: $id) {
      id
    }
  }
`

export const CREATE_TOWN = gql`
  mutation insertSingleTown($district_id: uuid!, $name: String) {
    insert_towns(objects: { district_id: $district_id, name: $name }) {
      returning {
        id
        name
      }
    }
  }
`

export const INSERTBUSINESSCLASS = gql`
  mutation insertBusinessClass(
    $image_url: String
    $name: String
    $description: String
  ) {
    insert_business_classes(
      objects: { image_url: $image_url, name: $name, description: $description }
    ) {
      returning {
        id
      }
    }
  }
`

export const UPDATEBUSINESSCLASS = gql`
  mutation updateBusinessClass(
    $id: uuid!
    $description: String
    $image: String
    $name: String
  ) {
    update_business_classes_by_pk(
      pk_columns: { id: $id }
      _set: { description: $description, image_url: $image, name: $name }
    ) {
      description
      created_at
      id
      image_url
      name
    }
  }
`

export const DELETEBUSINESSCLASS = gql`
  mutation deleteBusinessClass($id: uuid!) {
    delete_business_classes_by_pk(id: $id) {
      id
    }
  }
`

export const CREATESUBCLASS = gql`
  mutation createSubclass(
    $class_id: uuid!
    $description: String
    $name: String
  ) {
    insert_subclass(
      objects: { class_id: $class_id, description: $description, name: $name }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETESUBCLASS = gql`
  mutation deleteSubclassById($id: uuid!) {
    delete_subclass_by_pk(id: $id) {
      id
    }
  }
`

export const CREATEPERIL = gql`
  mutation createPeril($class_id: uuid!, $description: String, $name: String) {
    insert_perils(
      objects: { class_id: $class_id, description: $description, name: $name }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETEPERIL = gql`
  mutation deletePeril($id: uuid!) {
    delete_perils_by_pk(id: $id) {
      id
    }
  }
`

export const CREATEEXCLUSION = gql`
  mutation createExclusion(
    $class_id: uuid!
    $description: String
    $name: String
  ) {
    insert_exclusions(
      objects: { class_id: $class_id, description: $description, name: $name }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETEEXCLUSION = gql`
  mutation deleteExclusion($id: uuid!) {
    delete_exclusions_by_pk(id: $id) {
      id
    }
  }
`

export const DELETEPREMIUMRATE = gql`
  mutation deletePremiumRate($id: uuid!) {
    delete_premium_rate_by_pk(id: $id) {
      id
    }
  }
`

export const ADDPERILOREXCLUSIONTOCOVERTYPE = gql`
  mutation addPerilorExclusionToCoverType($cover_type_id: uuid, $peril_id: uuid) {
  insert_coverage(objects: {cover_type_id: $cover_type_id, peril_id: $peril_id}) {
    returning {
      id
    }
  }
}

`

export const ADDPREMIUMRATE = gql`
  mutation addPremiumRate(
    $cover_type_id: uuid
    $class_id: uuid
    $subclass_id: uuid
    $description: String
    $rate: String
  ) {
    insert_premium_rate(
      objects: {
        class_id: $class_id
        covertype_id: $cover_type_id
        description: $description
        rate: $rate
        status: "active"
        sub_class_id: $subclass_id
      }
    ) {
      returning {
        id
      }
    }
  }
`
export const CREATEPREMIUMRATES = gql`
  mutation insertNewData($data: [covertypes_perils_rate_insert_input!]!) {
    insert_covertypes_perils_rate(objects: $data) {
      returning {
        id
      }
    }
  }
`

export const CREATECOVER_TYPE_PERIL_RATES = gql`
  mutation insertNewData($data: [covertypes_perils_rate_insert_input!]!) {
    insert_covertypes_perils_rate(objects: $data) {
      returning {
        id
      }
    }
  }
`

export const UPDATEPREMIUMRATE = gql`
  mutation updatePremiumRate(
    $id: uuid
    $rate: String
    $status: String
    $sub_class_id: uuid
    $class_id: uuid
    $covertype: uuid
    $desc: String
  ) {
    update_premium_rate(
      where: { id: { _eq: $id } }
      _set: {
        rate: $rate
        status: $status
        sub_class_id: $sub_class_id
        class_id: $class_id
        covertype_id: $covertype
        description: $desc
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETESYSTEMDSICOUNT = gql`
  mutation deleteSystemDiscount($id: uuid!) {
    delete_system_discount_by_pk(id: $id) {
      id
    }
  }
`

export const ADDDISCOUNT = gql`
  mutation addDiscount($name: String, $description: String) {
    insert_system_discount(
      objects: { name: $name, description: $description, status: "active" }
    ) {
      returning {
        id
      }
    }
  }
`
export const ADD_COMPANY_DISCOUNT = gql`
  mutation MyMutation($dis: uuid, $com: uuid, $rate: numeric) {
    update_company_discounts(
      where: { discount_id: { _eq: $dis }, company_id: { _eq: $com } }
      _set: { rate: $rate }
    ) {
      returning {
        id
      }
    }
  }
`
export const ADD_COMPANY_ADDITIONAL_RATE = gql`
  mutation MyMutation($id: uuid, $com: uuid, $limit: numeric) {
    update_company_additional_benefits(
      where: { benefit: { _eq: $id }, company_id: { _eq: $com } }
      _set: { limit: $limit }
    ) {
      returning {
        id
      }
    }
  }
`

export const REMOVECOMMISSION = gql`
  mutation removeCommission($user_id: uuid, $id: uuid) {
    delete_user_commission_setup(
      where: { user_id: { _eq: $user_id }, id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`

export const ADDDISCOUNTRATE = gql`
  mutation addDiscountRate(
    $class_id: uuid
    $covertype_id: uuid
    $desc: String
    $discount_id: uuid
    $subclass_id: uuid
    $rate: String
  ) {
    insert_discount_rates(
      objects: {
        class_id: $class_id
        covertype_id: $covertype_id
        description: $desc
        discount_id: $discount_id
        sub_class_id: $subclass_id
        rate: $rate
        status: "active"
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETEDISCOUNTRATE = gql`
  mutation deleteDiscountRate($id: uuid!) {
    delete_discount_rates_by_pk(id: $id) {
      id
    }
  }
`

export const ADDCOVERTYPE = gql`
  mutation addCoverTypes(
    $class_id: uuid!
    $description: String
    $name: String
  ) {
    insert_covertypes(
      objects: {
        class_id: $class_id
        description: $description
        name: $name
        status: "active"
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const ADDCOMMISSIONTOUSER = gql`
  mutation addCommission(
    $commission_id: uuid
    $settlement_mode: String
    $user_id: uuid
  ) {
    insert_user_commission_setup(
      objects: {
        commission_id: $commission_id
        settlement_mode: $settlement_mode
        user_id: $user_id
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETECOVERTYPE = gql`
  mutation deleteCoverTypes($id: uuid!) {
    delete_covertypes_by_pk(id: $id) {
      id
    }
  }
`

export const DELETELOADING = gql`
  mutation deleteLoading($id: uuid!) {
    delete_loadings_by_pk(id: $id) {
      id
    }
  }
`

export const CREATELOADING = gql`
  mutation createLoading($description: String, $name: String) {
    insert_loadings(
      objects: { description: $description, name: $name, status: "active" }
    ) {
      returning {
        id
      }
    }
  }
`

export const CREATELOADINGTYPE = gql`
  mutation createLoadingRate(
    $class_id: uuid
    $covertype_id: uuid
    $desc: String
    $loading_id: uuid
    $subclass_id: uuid
    $rate: String
  ) {
    insert_loading_rates(
      objects: {
        class_id: $class_id
        covertype_id: $covertype_id
        description: $desc
        loading_id: $loading_id
        subclass_id: $subclass_id
        rate: $rate
        status: "active"
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const ADDCOMMISSION = gql`
  mutation addCommission($desc: String, $name: String) {
    insert_commisions(
      objects: { description: $desc, name: $name, status: "active" }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETECOMMISSIONS = gql`
  mutation deleteCommissions($id: uuid!) {
    delete_commisions_by_pk(id: $id) {
      id
    }
  }
`

export const ADDCOMMISSIONRATE = gql`
  mutation addCommissionRate(
    $desc: String
    $rate: String
    $class_id: uuid
    $commission_id: uuid
    $intermediary_type: String
    $subclass_id: uuid
  ) {
    insert_commisions_rates(
      objects: {
        class_id: $class_id
        commision_id: $commission_id
        description: $desc
        intermediary_type: $intermediary_type
        status: "active"
        subclass_id: $subclass_id
        rate: $rate
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETECOMMISSIONRATE = gql`
  mutation deleteCommissionRate($id: uuid!) {
    delete_commisions_rates_by_pk(id: $id) {
      id
    }
  }
`

export const CREATELEVY = gql`
  mutation createLevy($desc: String, $name: String) {
    insert_levies(
      objects: { description: $desc, name: $name, status: "active" }
    ) {
      returning {
        id
      }
    }
  }
`

export const ADDLEVYRATE = gql`
  mutation addLevyRate(
    $desc: String
    $rate: String
    $class_id: uuid
    $levy_id: uuid
    $amount: String
    $subclass_id: uuid
    $covertype: uuid
  ) {
    insert_levies_rate(
      objects: {
        class_id: $class_id
        description: $desc
        amount: $amount
        status: "active"
        subclass_id: $subclass_id
        rate: $rate
        levy_id: $levy_id
        covertype_id: $covertype
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETELEVY = gql`
  mutation deleteLevy($id: uuid!) {
    delete_levies_by_pk(id: $id) {
      id
    }
  }
`
export const DELETELEVYRATE = gql`
  mutation deleteLevyRate($id: uuid!) {
    delete_levies_rate_by_pk(id: $id) {
      id
    }
  }
`

export const CREATETAX = gql`
  mutation createTax($desc: String, $name: String) {
    insert_taxes(
      objects: { description: $desc, name: $name, status: "active" }
    ) {
      returning {
        id
      }
    }
  }
`

export const ADDTAXRATE = gql`
  mutation addTaxRate(
    $desc: String
    $rate: String
    $class_id: uuid
    $amount: String
    $tax_id: uuid
    $subclass_id: uuid
    $covertype: uuid
    $insurance_company: uuid
  ) {
    insert_tax_rate(
      objects: {
        class_id: $class_id
        description: $desc
        amount: $amount
        status: "active"
        subclass_id: $subclass_id
        rate: $rate
        tax_id: $tax_id
        covertype_id: $covertype
        insurance_company: $insurance_company
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETETAX = gql`
  mutation deleteTax($id: uuid!) {
    delete_taxes_by_pk(id: $id) {
      id
    }
  }
`
export const DELETETAXRATE = gql`
  mutation deleteTaxRate($id: uuid!) {
    delete_tax_rate_by_pk(id: $id) {
      id
    }
  }
`

export const UPDATERISKCLASS = gql`
  mutation updateClass(
    $id: uuid!
    $basic_premium: String
    $ecowas_peril: String
    $total_amount: String
    $fee_amount: String
  ) {
    update_third_party_only_risk_class(
      where: { risk_id: { _eq: $id } }
      _set: {
        basic_premium: $basic_premium
        ecowas_perils: $ecowas_peril
        total_amount: $total_amount
        fee_amount: $fee_amount
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const UPDATECOMPANYCOLORS = gql`
  mutation MyMutation($id: uuid, $colors: jsonb) {
    update_insurer_companies(
      where: { id: { _eq: $id } }
      _set: { colors: $colors }
    ) {
      returning {
        id
      }
    }
  }
`
