import React, { useEffect, useMemo, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  DialogFooter,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react"
import { BiPlus, BiTrash } from "react-icons/bi"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast, ToastContainer } from "react-toastify"
import { useMutation, useQuery } from "@apollo/client"
import { SubClassType } from "../business-class/manage"
import {
  GETBUSINESSCLASSES,
  GETCOVERTYPES,
  GETSUBCLASSES,
  GETTAXRATES,
} from "../../../GraphQl/queries"
import { CoverTypes } from "../../../types/query"
import { BusinessClassType } from "../../../types/class"
import { ADDTAXRATE, DELETETAXRATE } from "../../../GraphQl/mutations"
import { UserType } from "../../../types/user"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import { CustomModal } from "../../../components"
import { DriverModal } from "../../../components/modal/DriverModal"
import Otp from "../../../components/modal/enterOtpModal"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import GeneralPagination from "../../../components/paginations/generalpagination"

type PinType = {
  desc: string | null
  rate: string | null
  class_id: string | null
  amount: string | null
  tax_id: string | undefined
  subclass_id: string | null
  covertype: string | null
  insurance_company: string | null
}

type FormType = {
  id?: string
  class: string
  subclass: string
  amount: string
  cover: string
  rate: string
  cover_type: string
  description: string
  user_type?: string
}

type RateType = {
  amount: string
  business_class: {
    id: string
    name: string
    description: string
  }
  cover_type_name: string
  covertype: {
    id: string
    description: string
    name: string
  }
  description: string
  id: string
  tax_id: string
  rate: string
  status: string
  subclass: {
    id: string
    description: string
    name: string
  }
}

type Rate_Type = {
  id: string
  class: string
  subclass: string
  user_type: string
  rate: string
  description: string
  cover_type: string
}

const ManageTaxes = () => {
  const getStorage = localStorage.getItem("tax")
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [selectedRow, setSelectedRow] = useState<any>()
  const [showUpdateModal, setUpdateModal] = useState(false)
  const [openOtp, setOpenOtp] = useState(false)
  const [formData, setFormData] = useState<PinType | null>(null)
  const [requestType, setRequestType] = useState("")
  console.log(selectedRow)

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)
  const [selectedTax, setTax] = useState<{
    id: string
    name: string
    description: string
  }>()
  const PAGE_SIZE = 10

  const {
    register,
    handleSubmit,

    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormType>()

  const defaultValues = useMemo(() => {
    return {
      ...selectedRow,
    }
  }, [selectedRow])

  const {
    register: updateRegister,
    handleSubmit: handleUpdateSubmit,
    reset: resetUpdate,
    formState: { isSubmitting: updating, errors: updateError },
    setValue,
  } = useForm<FormType>({
    defaultValues: defaultValues,
  })

  const handleUpdate: SubmitHandler<FormType> = async (data) => {
    console.log(data)
    console.log(selectedRow?.id)
    // try {
    //   await updateRecord({
    //     variables: {
    //       id: selectedRow?.id,
    //       name: data?.name,
    //       description: data?.description,
    //     },
    //   })
    //   toast.success("Record updated successfully")
    //   resetUpdate()
    //   refetch()
    // } catch (error) {
    //   toast.error("error updatting record")
    // }
  }

  //update

  useEffect(() => {
    // Reset the form with new values when selectedRow changes
    resetUpdate(selectedRow as Rate_Type)
  }, [selectedRow, resetUpdate])
  //--------------

  const watchClass = watch("class")

  const { data, loading, refetch } = useQuery<{
    tax_rate: RateType[]
  }>(GETTAXRATES, {
    variables: {
      id: selectedTax?.id,
      company_id: companyId,
    },
  })

  const { data: subclasses, loading: loadingSubClass } = useQuery<{
    subclass: SubClassType[]
  }>(GETSUBCLASSES, {
    variables: {
      class_id: watchClass,
    },
  })

  const {
    data: coverTypeData,
    loading: loadingCoverType,
    refetch: refetchCoverTypes,
  } = useQuery<{
    covertypes: CoverTypes[]
  }>(GETCOVERTYPES)

  const {
    data: classes,
    refetch: refreshClass,
    loading: loadingClasses,
  } = useQuery<{
    business_classes: BusinessClassType[]
  }>(GETBUSINESSCLASSES)

  const [CreateTaxRate, { loading: RequestLoading }] = useMutation(ADDTAXRATE, {
    onCompleted: (data) => {
      toast.success("Tax rate Successfully added")
      reset()
      refetch()
      refreshClass()
      refetchCoverTypes()
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error creating tax rate"),
  })

  const [DeleteTaxRate] = useMutation(DELETETAXRATE, {
    onCompleted: (data) => {
      toast.success("Tax rate deleted successfully")
      refetch()
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error deleting tax rate"),
  })

  const filterData = (filter: RateType) => {
    const isTextMatch =
      filter?.business_class?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.covertype?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.covertype?.description
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.subclass?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.subclass?.description
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.status?.toLowerCase()?.includes(searchText?.toLowerCase())

    filter?.rate?.toLowerCase()?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText) {
      setCurrentPage(1)
    }
  }, [searchText])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  // Filtered and paginated data for the current page
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const totalItems = data?.tax_rate?.filter(filterData)
  const paginatedData = totalItems?.slice(startIndex, endIndex)

  // const nextPage = () => {
  //   if (
  //     (activePage - 1) * PAGE_SIZE <
  //     (data?.tax_rate?.filter(filterData).length || 0)
  //   ) {
  //     setActivePage(activePage + 1)
  //   }
  // }

  // const prevPage = () => {
  //   if (activePage > 1) {
  //     setActivePage(activePage - 1)
  //   }
  // }

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    const getData = {
      desc: data?.description,
      rate: data?.rate,
      class_id: data?.class,
      amount: data?.amount,
      tax_id: selectedTax?.id,
      subclass_id: data.subclass ? data.subclass : null,
      covertype: data.cover ? data.cover : null,
      insurance_company: companyId,
    }
    setFormData(getData)
    console.log("Form submitted successfully", data)
    setOpenOtp(true)
  }

  const handlePinSubmit = async () => {
    try {
      CreateTaxRate({
        variables: {
          ...formData,
        },
      })
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating tax rate")
    }
  }

  const deleteTaxRate = (id: string) => {
    DeleteTaxRate({
      variables: {
        id,
      },
    })
    try {
    } catch (error: any) {
      toast.error(error?.message ?? "Error deleting tax rate")
    }
  }

  useEffect(() => {
    if (getStorage) {
      setTax(JSON.parse(getStorage))
      refetch()
    }
  }, [])

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  return (
    <>
      <CustomHeader title="Tax Rating" />
      <DriverModal
        title="Enter Your PIN"
        open={openOtp}
        size={"xs"}
        focus="message"
        onClose={() => setOpenOtp(false)}
        children={
          <Otp
            onConfirm={() =>
              requestType === "create"
                ? handlePinSubmit()
                : setUpdateModal(true)
            }
            onClose={() => setOpenOtp(false)}
          />
        }
        // () => handleCreateSinglePaymentOrder(chosenId)
      />
      <CustomModal
        onClose={() => {
          setUpdateModal(false)
          // setSelectedRow(null)
        }}
        open={showUpdateModal}
        size={"md"}
        title="Update"
        className="h-full overflow-scroll scrolls"
        children={
          <>
            <ToastContainer />
            <div className=" rounded-full border">
              <div
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
                className=" p-3 rounded-md"
              >
                <Typography className="text-xl text-white ">
                  Update Tax Rate
                </Typography>
              </div>
              <form
                onSubmit={handleUpdateSubmit(handleUpdate)}
                className="p-3 capitalize bg-white rounded-b-xl"
              >
                <div className="mt-5 flex flex-col items-end justify-end">
                  <div className="w-full max-w-2xl ">
                    <div className="mb-6 flex flex-col gap-2">
                      <div className="w-full my-2 flex items-center">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Class of Business
                        </Typography>

                        <select
                          id="Class"
                          className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          defaultValue={defaultValues?.class}
                          // value={selectedClass as string}
                          {...updateRegister("class", { required: true })}
                          // onChange={(e) => {
                          //   setSlectedClass(
                          //     e.target.options[
                          //       e.target.selectedIndex
                          //     ].getAttribute("data-code")
                          //   )
                          // }}
                        >
                          <option value="Select class">Select class</option>
                          {classes?.business_classes?.map((data) => (
                            <option
                              data-code={data?.name}
                              label={data?.name}
                              value={data?.id}
                            >
                              {data?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="w-full my-2 flex items-center">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Subclass
                        </Typography>

                        <select
                          id="subclass"
                          defaultValue={defaultValues?.subclass}
                          className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          {...updateRegister("subclass", { required: true })}
                        >
                          <option>Select subclass</option>
                          {subclasses?.subclass?.map((data) => (
                            <option label={data?.name} value={data?.id}>
                              {data?.name}
                            </option>
                          ))}
                          <option
                            value={"00000000-0000-0000-0000-000000000000"}
                            label="All"
                          >
                            All
                          </option>
                        </select>
                      </div>

                      <div className="w-full my-2 flex items-center">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Type of Cover
                        </Typography>
                        <select
                          id="cover_type"
                          defaultValue={defaultValues?.cover_type!}
                          className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          {...updateRegister("cover_type", { required: true })}
                        >
                          <option value="">Select covertype</option>
                          {loadingCoverType ? (
                            <option disabled>Loading...</option>
                          ) : (
                            coverTypeData?.covertypes?.map((data) => (
                              <option label={data?.name} value={data?.id}>
                                {data?.name}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                      <div className="w-full my-2 flex items-center">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          rate
                        </Typography>
                        <Input
                          defaultValue={defaultValues?.rate}
                          size="lg"
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          {...updateRegister("rate", { required: false })}
                        />
                      </div>
                      <div className="w-full my-2 flex items-center">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Amount
                        </Typography>
                        <Input
                          defaultValue={defaultValues?.rate}
                          size="lg"
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          {...updateRegister("amount", { required: false })}
                        />
                      </div>

                      <div className="w-full my-2 flex items-center">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Description
                        </Typography>
                        <Textarea
                          size="lg"
                          defaultValue={defaultValues?.description}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          {...updateRegister("description", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <DialogFooter>
                  <Button
                    variant="text"
                    color="red"
                    onClick={() => setUpdateModal(false)}
                    className="mr-1"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    type="submit"
                    className="flex items-center justify-center"
                    variant="gradient"
                    color="green"
                  >
                    {updating ? (
                      <Spinner className="h-4 w-4 text-white" />
                    ) : (
                      <span>Update</span>
                    )}
                  </Button>
                </DialogFooter>
              </form>
            </div>
          </>
        }
      />

      <div className="my-5">
        <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {["Type of tax", "Description"].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="transition-all bg-gray-300">
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {selectedTax?.name}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {selectedTax?.description}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </CardBody>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className=" p-3"
          >
            <Typography className="text-xl text-white">
              Create {selectedTax?.name} Rate
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="capitalize p-3 bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Class of Business
                    </Typography>

                    <select
                      id="Class"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("class", { required: true })}
                    >
                      <option>
                        {loadingClasses ? "Loading..." : "Select class"}
                      </option>
                      {classes?.business_classes?.map((data) => (
                        <option label={data?.name} value={data?.id}>
                          {data?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Subclass
                    </Typography>

                    <select
                      id="subclass"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("subclass", { required: true })}
                    >
                      <option value="">Select subclass</option>
                      {loadingSubClass ? (
                        <option disabled>Loading...</option>
                      ) : (
                        subclasses?.subclass?.map((data) => (
                          <option label={data?.name} value={data?.id}>
                            {data?.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Type of Cover
                    </Typography>
                    <select
                      id="covertypes"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("cover", { required: true })}
                    >
                      <option value="">Select covertype</option>
                      {loadingCoverType ? (
                        <option disabled>Loading...</option>
                      ) : (
                        coverTypeData?.covertypes?.map((data) => (
                          <option label={data?.name} value={data?.id}>
                            {data?.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      rate
                    </Typography>
                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("rate", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Amount
                    </Typography>
                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("amount", { required: true })}
                    />
                  </div>

                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Description
                    </Typography>
                    <Textarea
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("description", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                onClick={() => setRequestType("create")}
                type="submit"
                size="sm"
                className="capitalize flex flex-row items-centerfont-normal"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
              >
                <BiPlus size={20} />
                {isSubmitting || RequestLoading
                  ? "Creating..."
                  : "Create tax Rate"}
              </Button>
            </div>
          </form>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="text-xl"
            >
              List of {selectedTax?.name} Rates
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "no",
                      "class",
                      "Subclass",
                      "Description",
                      "Cover Type",
                      "Rate",
                      "Amount",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-4 py-2  text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left"

                    return (
                      <tr
                        key={item?.id + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index + 1}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.business_class?.name}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            All
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.description}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.cover_type_name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.rate}%
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            0
                          </Typography>
                        </td>

                        <td className={classes}>
                          <div className="flex flex-row items-center gap-x-2">
                            <Button
                              onClick={() => {
                                setSelectedRow({
                                  class: item?.business_class,
                                  subclass: item?.subclass || "All",
                                  amount: item?.amount || 0,
                                  cover1: item?.covertype,
                                  cover2: item?.cover_type_name,
                                  rate: item?.rate,
                                  cover_type: item?.covertype,
                                  description: item?.description,
                                  user_type: "",
                                })
                                setRequestType("update")
                                setOpenOtp(true)
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-brand-green"
                            >
                              Edit
                            </Button>
                            <IconButton
                              onClick={() => deleteTaxRate(item?.id)}
                              color="red"
                              size="sm"
                            >
                              <BiTrash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && paginatedData?.length !== 0 && (
              <GeneralPagination
                color="black"
                totalItems={totalItems?.length!}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
            {/* {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (data?.tax_rate?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (data?.tax_rate?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  )
}

export default ManageTaxes
