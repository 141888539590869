import {
  Button,
  CardBody,
  CardHeader,
  DialogFooter,
  IconButton,
  Input,
  Spinner,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import React, { useEffect, useState, useMemo } from "react"
import {
  CustomDeleteModal,
  CustomModal,
  DetailDrawer,
  HeaderIcon,
  RenderAdminDetail,
} from "../../../components"
import useSWR from "swr"
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util"
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai"
import { AdminType, UpdateType } from "../../../types/admin"
import { ToastContainer, toast } from "react-toastify"
import { UserType } from "../../../types/user"
import { SubmitHandler, useForm } from "react-hook-form"
import { AddAdminModal } from "../../../components/forms/add-admin"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import { useHistory } from "react-router-dom"
import { BiBlock, BiSearch } from "react-icons/bi"
import { DASHBOARD_USERTYPE } from "../../../types/role"
import moment from "moment"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"

type ResponseType = {
  "Transaction Ref": string
  "Transaction Id": string
  "Transaction Date": string
  "Policy Number": string
  Policyholder: string
  Payee: string
  "Class/Subclass": string
  "Basic Premium": number
  "Commission Rate": string
  "Gross Commission": number
  "Tax Amount": number
  "Net Commission": number
  RiskID: string
}
const PaidCommisionss = () => {
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [showDeleteModal, SetShowDeleteModal] = useState(false)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [selectedRow, setSelectedRow] = useState<ResponseType>()
  const [commissions, setCommissions] = useState<ResponseType[]>([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }

  const PAGE_SIZE = 10

  const handleRowClick = (item: ResponseType) => {
    setSelectedRow(item)
    setOpenDrawer(true)
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const filterData = (admin: ResponseType) => {
    const date = moment(admin["Transaction Date"]).format("LLL")
    const isTextMatch =
      admin?.["Transaction Ref"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Transaction Id"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Policy Number"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Gross Commission"]
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Tax Amount"]
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Basic Premium"]
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Net Commission"]
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.RiskID?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      admin?.Payee?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      admin?.["Policyholder"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Class/Subclass"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      date?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      admin?.["Commission Rate"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  const fetchPaidComissions = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/get-paid-commissions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            usertype: DASHBOARD_USERTYPE.company,
            company_id: companyId,
            commission_type: "BDC",
          }),
        }
      )
      const req = await res.json()
      if (res.ok) {
        if (req?.error === 404) {
          setCommissions([])
        } else {
          setCommissions(req?.data)
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)

      toast.error("Failed to fetch commissions. Refresh to try again")
    }
  }
  // const rr  = commissions.map((item)=>{
  //   JSON.parse(item)
  // })

  // console.log(commissions)

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText || startDate || endDate) {
      setCurrentPage(1)
    }
  }, [searchText, startDate, endDate])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const totalItems = commissions?.filter(filterData)?.filter((item: any) => {
    const normalizeDate = (date: Date | string): Date => {
      const normalizedDate = new Date(date)
      normalizedDate.setHours(0, 0, 0, 0) // Set time to midnight to include the entire day
      return normalizedDate
    }
    const from =
      (startDate as any) instanceof Date
        ? normalizeDate(startDate)
        : normalizeDate(startDate)
    const to =
      (endDate as any) instanceof Date
        ? normalizeDate(endDate)
        : normalizeDate(endDate)
    const dateCreated = normalizeDate(item["Transaction Date"])
    // Convert date_created to Date object
    // Check if dateCreated falls within the range (inclusive)
    if (startDate && endDate) {
      return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
    } else if (startDate) {
      return dateCreated >= from // Only from date is set
    } else if (endDate) {
      return dateCreated <= to // Only to date is set
    }
    return true // No filter applied
  })

  const paginatedData = totalItems?.slice(startIndex, endIndex)

  // console.log(paginatedData.length)

  // console.log(commissions)

  const filteredItems = paginatedData.filter((item) => {
    const itemDate = new Date(item["Transaction Date"])
    return itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
  })

  // const paginatedData = commissions
  //   ?.filter(filterData)
  //   ?.slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE)

  // const nextPage = () => {
  //   if (
  //     (activePage - 1) * PAGE_SIZE <
  //     (commissions?.filter(filterData).length || 0)
  //   ) {
  //     setActivePage(activePage + 1)
  //   }
  // }

  // const prevPage = () => {
  //   if (activePage > 1) {
  //     setActivePage(activePage - 1)
  //   }
  // }

  useEffect(() => {
    fetchPaidComissions()
  }, [])

  const handlePrint = () => {
    if (paginatedData.length === 0) {
      window.alert("No data to print")
      return
    }
    // Create a new window for printing
    const printWindow = window.open("", "_blank")

    // Construct the content to be printed
    const printContent = `
      <html>
        <head>
          <title>Collection of Paid Commissions</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
            }
            h1 {
              text-align: center;
              font-size: 24px;
              margin-bottom: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 10px;
            }
            th {
              background-color: #f2f2f2;
              font-weight: bold;
            }
          </style>
        </head>
        <body>
          <h1>Paid Commissions</h1>
          <table>
            <thead>
              <tr>
                <th>Transaction Ref</th>
                <th>Policy Number</th>
                <th>Payee</th>
                <th>Class/Subclass</th>
                <th>Basic Premium</th>
                <th>Commission Rate</th>
                <th>Gross Commission</th>
                <th>Tax</th>
                <th>Net Commission</th>
                 <th>Transaction Date</th>
              </tr>
            </thead>
            <tbody>
             ${paginatedData
               .map(
                 (rowData) => `<tr>
                <td>${rowData["Transaction Ref"]}</td>
                <td>${rowData["Policy Number"]}</td>
                <td>${rowData["Policyholder"]}</td>
                <td>${rowData["Class/Subclass"]}</td>
                <td>GHS ${(
                  (rowData?.["Basic Premium"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>${rowData["Commission Rate"]}%</td>
                <td>GHS ${(
                  (rowData?.["Gross Commission"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>GHS ${(
                  (rowData?.["Tax Amount"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>GHS ${(
                  (rowData?.["Net Commission"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
             <td>${moment(rowData["Transaction Date"]).format("LLL")}</td>
              </tr>
              `
               )
               .join(" ")}
            </tbody>
          </table>
        </body>
      </html>
    `

    // Write the content to the new window
    printWindow!.document.write(printContent)

    // Close the document after printing
    printWindow!.document.close()

    // Print the window
    printWindow!.print()
  }

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])
  return (
    <>
      <CustomHeader title="Paid commssions" />
      <div className="mt-10">
        <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
          <div className="bg-black pt-5 rounded-t-2xl">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold  leading-none opacity-70 capitalize"
            >
              Total paid commissions
              {commissions && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {commissions?.length}
                </span>
              )}
            </Typography>
          </div>
          <div className="flex flex-col md:flex-row lg:flex-row gap-3 bg-white w-full">
            {/* <div className="w-full">
              <Input
                crossOrigin="anonymous"
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div> */}
            <CardHeader
              floated={false}
              shadow={false}
              className="rounded-none w-full"
            >
              <div className="w-full  flex flex-col gap-y-4 ">
                <div className="flex shrink-0 flex-col gap-2 justify-end sm:flex-row">
                  <Button
                    onClick={handlePrint}
                    size="sm"
                    color="green"
                    className=" px-10 text-[1.0rem]"
                  >
                    Print
                  </Button>
                </div>

                <div className="">
                  <div className="p-3 flex gap-x-4 items-center flex-col 2xl:flex-row gap-y-3 mb-3">
                    <div className="w-full ">
                      <Input
                        crossOrigin="anonymous"
                        value={searchText}
                        onChange={handleSearch}
                        label="Search"
                        icon={<BiSearch className="h-5 w-5" />}
                      />
                    </div>

                    <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                      <div className="flex items-center space-x-2">
                        <label className="font-semibold text-gray-700">
                          From
                        </label>
                        <Input
                          type="datetime-local"
                          value={startDate.slice(0, -1)} // Remove 'Z' for input value
                          onChange={handleStartDateChange}
                          size="md"
                          className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="flex items-center space-x-2">
                        <label className="font-semibold text-gray-700">
                          To
                        </label>
                        <Input
                          type="datetime-local"
                          value={endDate.slice(0, -1)} // Remove 'Z' for input value
                          onChange={handleEndDateChange}
                          size="md"
                          className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardHeader>
          </div>
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              {/* //min-w-max  */}
              <table className="w-full bg-white min-w-max table-auto">
                <thead className="bg-white sticky  top-0">
                  <tr>
                    {[
                      "Transaction Ref",
                      "Provider transaction id",
                      "Policy No.",
                      "Class/Subclass",
                      "Risk ID",
                      "Premium",
                      "Rate",
                      "Gross Commission",
                      "Tax",
                      "Net Commission",
                      "Payee",
                      "Transaction Date",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "p-2  text-center"
                      : "p-2 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={item?.["Policy Number"] + index.toString()}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer h-[4.7rem] "
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item["Transaction Ref"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item["Transaction Id"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.["Policy Number"]}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs"
                          >
                            {item?.["Class/Subclass"]}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs"
                          >
                            {item?.RiskID}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS {item?.["Basic Premium"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.["Commission Rate"]}%
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {(
                              (item?.["Gross Commission"] as any) ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {(
                              (item?.["Tax Amount"] as any) ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {(
                              (item?.["Net Commission"] as any) ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs"
                          >
                            {item?.Payee}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.["Transaction Date"]).format("LLL")}
                          </Typography>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          {loading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {paginatedData?.length! === 0 && !loading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}
        </CardBody>

        {/* Paginator */}
        {paginatedData && paginatedData?.length !== 0 && !loading && (
          <GeneralPagination
            totalItems={totalItems?.length!}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </>
  )
}

export default PaidCommisionss
